import React, {ReactNode} from "react";
import {createData, getSorting, ITableRowEntry, numberWithCommas, stableSort, styles} from "./tableUtils";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import EnhancedTableHead from "./EnhancedTableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import {IOfferFilled, Listing} from "../../services/redux/trading/tradingStore";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/initialStore";
import {removeBuyOffer, removeSellOffer} from "../../services/redux/trading/tradingActions";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

export enum tableOrder {
	ASCENDING = "asc",
	DESCENDING = "desc",
}

export enum cancelTradingTableType {
	BUY = "BUY",
	SELL = "SELL",
	BOTH = "BOTH",
}

interface IProps {
	classes?: any;
	variety: cancelTradingTableType,
	data: IOfferFilled[],
	dispatch?: any;
}

interface ISecuritiesTableState {
	order: tableOrder,
	orderBy: string; // TODO
	selected: any[];
	page: number;
	rowsPerPage: number;
	showModal: boolean;
	selectedIndex?: number;
	offerToDelete?: IOfferFilled;
	showConfirmCancelModal: boolean;
}

const currentOffersColumns: any[] = [
	{
		numeric: true,
		id: "type",
		label: "Trade Type",
	},
	{numeric: true, id: "securityID", label: "Property Type"},
	{
		id: "expirationDate",
		numeric: true,
		label: "Expiration Date",
	},
	{numeric: true, id: "amount", label: "Amount Left"},
	{numeric: true, id: "price", label: "Price"},
	{numeric: true, id: "priceAsOf", label: "Price, June 30 2019"},
	{numeric: true, id: "aboveMarketValue", label: "Above/Below Market Value"}
];

class CurrentOffers extends React.Component<IProps, ISecuritiesTableState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			page: 0,
			rowsPerPage: 10,
			showModal: false,
			showConfirmCancelModal: false,
		};

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
		this.prepareConfirmCancelModal = this.prepareConfirmCancelModal.bind(this);
		this.cancelConfirmCancelModal = this.cancelConfirmCancelModal.bind(this);
		this.cancel = this.cancel.bind(this);
		this.closeSecurityModal = this.closeSecurityModal.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
	private createRows(rows: IOfferFilled[]): any {
		return rows.map((row: IOfferFilled, i: number) => {

			let injectPropsForSafari3: any = {
				onTouchTap: this.prepareConfirmCancelModal(row),
			};

			// @ts-ignore
			const type: string = row.type;

			return (
				<TableRow
					hover
					tabIndex={-1}
					key={row.id + type}
					style={{
						height: 69,
						whiteSpace: "pre",
						lineHeight: 1.7,
					}}
				>
					<TableCell>{type}</TableCell>
					<TableCell>
						{
							row.security.bedrooms + " Bedroom(s)," + " " +
							row.security.bathrooms + " Bathroom(s)," + "\n" +
							row.security.lotType + ", " +
							row.security.market
						}
					</TableCell>
					<TableCell>{moment(row.security.expirationDate).format("MMM Do YYYY")}</TableCell>
					<TableCell align="right">{numberWithCommas(row.amount)}</TableCell>
					<TableCell align="right">{"$" + row.price.toFixed(2)}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.security.priceAsOf)}</TableCell>
					<TableCell align="right">{row.aboveMarketValue === true ? "Above" : "Below"}</TableCell>

					<TableCell align="center">
						<Button
							variant="contained"
							color="primary"
							size="medium"
							onClick={this.prepareConfirmCancelModal(row)}
							{...injectPropsForSafari3}
						>
							Cancel
						</Button>
					</TableCell>
				</TableRow>
			);
		})
	}

	private prepareConfirmCancelModal(offer: IOfferFilled): () => void {
		const that: CurrentOffers = this;
		return () => {
			that.setState({
				offerToDelete: offer,
				showConfirmCancelModal: true,
			})
		}
	}

	private cancelConfirmCancelModal(): void {
		const that: CurrentOffers = this;

		this.setState({
			showConfirmCancelModal: false,
		}, () => {
			setTimeout(() => {
				that.setState({
					offerToDelete: undefined,
				});
			}, 250);
		})
	}

	private cancel(): void {

		// @ts-ignore
		const offer: IOfferFilled = this.state.offerToDelete;

		if (this.props.variety === cancelTradingTableType.BUY) {
			this.props.dispatch(removeBuyOffer(offer.id));

		} else if (this.props.variety === cancelTradingTableType.SELL) {
			this.props.dispatch(removeSellOffer(offer.id));

		} else if (this.props.variety === cancelTradingTableType.BOTH) {
			// @ts-ignore
			if (offer.listingType === Listing.BID) {
				this.props.dispatch(removeBuyOffer(offer.id));

			// @ts-ignore
			} else if (offer.listingType === Listing.OFFER) {
				this.props.dispatch(removeSellOffer(offer.id));
			}
		}

		this.cancelConfirmCancelModal(); // this function just closes and clears the selected security so we can call it here as well to wrap up cancelling a post
	}

	private closeSecurityModal(): void {
		this.setState({
			selectedIndex: undefined,
			showModal: false,
		})
	}

	public render(): JSX.Element {

		const {data, classes} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		const rowsToShow: any = stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		const injectPropsForSafari: any = {
			onTouchTap: this.cancel,
		};

		const injectPropsForSafari2: any = {
			onTouchTap: this.cancelConfirmCancelModal,
		};

		// @ts-ignore
		const deleting: IOfferFilled = this.state.offerToDelete;

		return (
			<React.Fragment>
				<Dialog
					open={this.state.showConfirmCancelModal}
					fullWidth={true}
					maxWidth={"sm"}
				>
					<DialogTitle id="customized-dialog-title">
						{this.props.variety === cancelTradingTableType.SELL ?
							<React.Fragment>
								Are you sure you want to cancel this sell order?
							</React.Fragment> :
							<React.Fragment>
								Are you sure you want to cancel this buy order?
							</React.Fragment>
						}
					</DialogTitle>

					<Divider light style={{height: 2, marginTop: 10, marginBottom: 10}}/>

					<DialogContent>
						{this.state.offerToDelete && (
							<Grid container spacing={24}>
								<Grid item xs={6}>
									<Typography className="black-text">
										Security
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subheading">
										{
											deleting.security.bedrooms + " Bedroom(s)," + " " +
											deleting.security.bathrooms + " Bathroom(s)," + "\n" +
											deleting.security.lotType + ", " +
											deleting.security.market
										}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography className="black-text">
										Amount Left
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subheading">
										{deleting.amount}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography className="black-text">
										Price
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subheading">
										{"$" + deleting.price.toFixed(2)}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography className="black-text">
										Price, June 30 2019
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subheading">
										{"$" + numberWithCommas(deleting.security.priceAsOf)}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography className="black-text">
										Above/Below Market Value
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subheading">
										{deleting.aboveMarketValue === true ? "Above" : "Below"}
									</Typography>
								</Grid>
							</Grid>
						)}
					</DialogContent>

					<DialogActions style={{marginTop: 20}}>
						<Button
							color="primary"
							size="large"
							onClick={this.cancelConfirmCancelModal}
							{...injectPropsForSafari2}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={this.cancel}
							{...injectPropsForSafari}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>

				<Paper className={classes.root}>
					<div className={classes.tableWrapper}>
						<Table className={classes.table} aria-labelledby="tableTitle">
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onRequestSort={this.handleRequestSort}
								rowCount={data.length}
								columns={currentOffersColumns}
							/>

							<TableBody>
								{rows}
								{emptyRows > 0 && (
									<TableRow style={{height: 55 * emptyRows}}>
										<TableCell colSpan={6}/>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>

					<TablePagination
						rowsPerPageOptions={[10, 15, 20]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={(this.changePage as any)}
						onChangeRowsPerPage={(this.changeRowsPerPage as any)}
					/>
				</Paper>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps): IProps => {

	let data: IOfferFilled[];
	const userID: number = store.tradingStore.currentUser;


	if (props.variety === cancelTradingTableType.BUY) {
		// Buy Offers
		data = Object.keys(store.tradingStore.buyOffers)
			.filter((key: string) => store.tradingStore.buyOffers[key].userID === userID)
			.map((key: string): IOfferFilled => ({
				...store.tradingStore.buyOffers[key],
				type: store.tradingStore.buyOffers[key].listingType,
				user: store.tradingStore.users[store.tradingStore.buyOffers[key].userID],
				security: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID],
				id: parseInt(key),
				expirationDate: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID].expirationDate,
			}));
	} else if (props.variety === cancelTradingTableType.SELL) {
		// Sell Offers
		data = Object.keys(store.tradingStore.sellOffers)
			.filter((key: string) => store.tradingStore.sellOffers[key].userID === userID)
			.map((key: string): IOfferFilled => ({
				...store.tradingStore.sellOffers[key],
				type: store.tradingStore.sellOffers[key].listingType,
				user: store.tradingStore.users[store.tradingStore.sellOffers[key].userID],
				security: store.tradingStore.securities[store.tradingStore.sellOffers[key].securityID],
				id: parseInt(key),
				expirationDate: store.tradingStore.securities[store.tradingStore.sellOffers[key].securityID].expirationDate,
			}));
	} else {
		// Both
		const buy = Object.keys(store.tradingStore.buyOffers)
			.filter((key: string) => store.tradingStore.buyOffers[key].userID === userID)
			.map((key: string): IOfferFilled => ({
				...store.tradingStore.buyOffers[key],
				type: store.tradingStore.buyOffers[key].listingType,
				user: store.tradingStore.users[store.tradingStore.buyOffers[key].userID],
				security: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID],
				id: parseInt(key),
				expirationDate: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID].expirationDate,
			}));

		const sell = Object.keys(store.tradingStore.sellOffers)
			.filter((key: string) => store.tradingStore.sellOffers[key].userID === userID)
			.map((key: string): IOfferFilled => ({
				...store.tradingStore.sellOffers[key],
				type: store.tradingStore.sellOffers[key].listingType,
				user: store.tradingStore.users[store.tradingStore.sellOffers[key].userID],
				security: store.tradingStore.securities[store.tradingStore.sellOffers[key].securityID],
				id: parseInt(key),
				expirationDate: store.tradingStore.securities[store.tradingStore.sellOffers[key].securityID].expirationDate,
			}));

		data = cloneDeep(buy);
		data = data.concat(sell);
	}

	return ({
		...props,
		// @ts-ignore
		data,
	})
})(CurrentOffers));
