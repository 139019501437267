import React, {ReactNode} from 'react';
import {Divider, withStyles} from "@material-ui/core";
import {IStore} from "../services/redux/initialStore";
import {connect} from "react-redux";
import {getFinancialSummary, IFinancialSummary, IFinancialSummaryEntry} from "../services/financialSummary";
import FSDataDisplay from "./FSDataDisplay";
import values from "lodash/values";

export interface IFinancialSummaryComponentProps {
	summary?: IFinancialSummary;
}

export interface IFinancialSummaryComponentState {

}

class FinancialSummaryComponent extends React.Component<IFinancialSummaryComponentProps, IFinancialSummaryComponentState> {

	constructor(props: IFinancialSummaryComponentProps) {
		super(props);
	}

	private makeDataDisplays(summary: IFinancialSummary): ReactNode {
		const display: ReactNode[] = [];

		let entry: any;
		for (entry in summary) {
			if (summary.hasOwnProperty(entry)) {

				let ignoreTotalColor: boolean = false;

				if (
					entry === "invested" ||
					entry === "availableBalance"
				) {
					ignoreTotalColor = true;
				}

				display.push(
					<FSDataDisplay
						key={`fs-data-display-${entry}`}
						{...summary[entry]}
						camelCaseLabel={entry}
						ignoreTotalColor={ignoreTotalColor}
					/>
				);

				if (
					entry === "withdrawals" ||
					entry === "offersPurchased" ||
					entry === "availableBalance"
				) {
					display.push(
						<Divider style={{marginTop: 5}} key={`fs-data-display-${entry}-divider` + Math.random()}/>
					);
				}
			}
		}

		return display;
	}

	public render(): JSX.Element {

		// @ts-ignore
		const display: ReactNode = this.makeDataDisplays(this.props.summary);

		return (
			<div className="fsc-main-component">
				<div className="fsc-inner-display-center-helper">
					{display}
				</div>
			</div>
		);
	}
}

const styles = theme => ({
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IFinancialSummaryComponentProps) => {

	return {
		summary: getFinancialSummary(),
	}

})(FinancialSummaryComponent));
