import {Card, CardContent, Tooltip, Typography, withStyles, Zoom} from "@material-ui/core";
import * as React from "react";

export interface InternalMarketCard2Props {
	classes?: any;
	title: string;
	value: any;
	toolTitle?: string;
}

const InternalMarketCard2: React.FunctionComponent<InternalMarketCard2Props> = (props: InternalMarketCard2Props): JSX.Element => {

	const {classes, title, value, toolTitle} = props;

	return (
		<Tooltip
			disableFocusListener
			TransitionComponent={Zoom}
			title={toolTitle ? toolTitle : title}
		>
			<Card className={classes.card}>
				<CardContent>
					<Typography variant="h6">
						{title}
					</Typography>

					<Typography variant="h5" color="textSecondary">
						{value}
					</Typography>
				</CardContent>
			</Card>
		</Tooltip>
	);
};

const styles = {
	card: {
		height: "100%",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
};

export default withStyles(styles)(InternalMarketCard2);
