import React, {ReactNode} from 'react';
import {Grid, Paper, withStyles} from "@material-ui/core";
import UsefulLink from "../components/UsefulLink";

export interface IUsefulLinksProps {
	classes: any;
}

const usefulLinkData: any[] = [
	{
		src: process.env.PUBLIC_URL + "/useful_links_logos/mls-logo.svg",
		title: "MLS Vancouver",
		body: "The latest MLS® listings of houses, townhomes, and condos for sale in Vancouver, BC. Keep an eye on the market and make intelligent trades by looking at the current for sale listings in the Vancouver area.",
		link: "https://www.rew.ca/properties/areas/vancouver-bc",
	},
	{
		src: process.env.PUBLIC_URL + "/useful_links_logos/vancouver_real_estate_board.jpg",
		title: "Vancouver Real Estate Board",
		body: "The Real Estate Board of Greater Vancouver (REBGV) is a member-based professional association of more than 14,000 REALTORS® who live and work in communities from Whistler to Maple Ridge to Tsawwassen and everywhere in between.",
		link: "https://www.rebgv.org/",
	},
	{
		src: process.env.PUBLIC_URL + "/useful_links_logos/canada_mortgage_and_housing_corporation.png",
		title: "Canadian Housing and Mortgage Corporation",
		body: "The portal offers housing market information on a large and small scale, from data for the entire country down to individual census tracts. Easily view reports, comparisons and more in order to make intelligent trades in the system.",
		link: "https://www.cmhc-schl.gc.ca/",
	},
	{
		src: process.env.PUBLIC_URL + "/useful_links_logos/teranet.png",
		title: "Teranet – National Bank Housing Index",
		body: "The Teranet-National Bank House Price Index™ is an independent representation of the rate of change of Canadian single-family home prices. The measurements are based on the property records of public land registries, where sale price is available. The monthly composite index cover eleven Canadian metropolitan areas.",
		link: "https://housepriceindex.ca/#maps=c11",
	},
];

const UsefulLinks: React.FunctionComponent<IUsefulLinksProps> = (props: IUsefulLinksProps): JSX.Element => {

	const {classes} = props;

	function createUsefulLinks(items: any[] = []): ReactNode {
		return items.map((item: any, i: number) => {
			return (
				<Grid item xs={12}>
					<UsefulLink
						title={item.title}
						body={item.body}
						imageSource={item.src}
						link={item.link}
					/>
				</Grid>
			);
		});
	}

	return (
		<div>
			<Grid
				container
				spacing={40}
				style={{marginBottom: 30}}
			>
				{createUsefulLinks(usefulLinkData)}
			</Grid>
		</div>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

// @ts-ignore
export default withStyles(styles)(UsefulLinks);
