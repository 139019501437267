import * as React from "react";
import "./Landing.css";
import ShowcaseButton from "../components/ShowcaseButton";

const Landing: React.FC = (props) => {

	function welcome() {
		// @ts-ignore
		props.history.push("/demo-intro")
	}

	return (
		<div className="background">
			<div className="overlay">
				<div className="container">
					<h1 className="title">Real Estate Securities Exchange</h1>
					<ShowcaseButton onClick={welcome} light={false}><h2 className="welcome-button">Welcome</h2></ShowcaseButton>
				</div>
			</div>
		</div>
	);

};

export default Landing;