import {ISecurity} from "./redux/trading/tradingStore";
import {Moment} from "moment";
import {store} from "./redux";
import moment from "moment";

export interface IInternalMarketLeaders {
	mostTraded: {
		security: ISecurity,
		quantity: number,
	}
	highestAveragePrice: {
		security: ISecurity,
		price: number,
	}
	lowestAveragePrice: {
		security: ISecurity,
		price: number,
	}
	mostMoneyMoved: {
		security: ISecurity,
		money: number,
	}
}

export enum TimeFrame {
	DAY,
	WEEK,
	MONTH,
}

interface IInternalMarketSecurityResultShort {
	security: ISecurity,
	timeFrame: TimeFrame,
	beginningDate: Moment,
	averagePrice: number,
	quantityTraded: number,
	lowestPriceTraded: number,
	highestPriceTraded: number,
	numberOfTrades: number,
	totalMoney: number,
	priceChange: number,
	history: Array<{
		date: Moment,
		quantity: number,
		price: number,
	}>
}

// the following require getting the IInternalMarketSecurityResultShort of all securities, so I separated to reuse the functions below
// priceRanking: number,
// volumeRanking: number,
export interface IInternalMarketSecurityResult extends IInternalMarketSecurityResultShort {
	priceRanking: number,
	volumeRanking: number,
}

interface IRecord {
	totalMoney: number,
	averagePrice: number,
	quantity: number
}

export function getInternalMarketLeaders(): IInternalMarketLeaders {
	const {history, securities} = store.getState().tradingStore;
	const records: { [key: number]: IRecord } = {};
	const marketLeaders: IInternalMarketLeaders = {
		mostTraded: {
			security: securities[0],
			quantity: 0,
		},
		highestAveragePrice: {
			security: securities[0],
			price: 0,
		},
		lowestAveragePrice: {
			security: securities[0],
			price: Number.MAX_SAFE_INTEGER,
		},
		mostMoneyMoved: {
			security: securities[0],
			money: 0,
		}
	};

	for (const securitiesKey in securities) {
		records[securitiesKey] = {
			averagePrice: 0,
			quantity: 0,
			totalMoney: 0,
		};
	}

	history.forEach(({securityID, price, quantity}) => {
		records[securityID].quantity = Math.floor(records[securityID].quantity + quantity);
		records[securityID].totalMoney = Math.floor((records[securityID].totalMoney + (price * quantity)) * 100 ) / 100;
		records[securityID].averagePrice = Math.round(records[securityID].totalMoney / records[securityID].quantity * 100) / 100;

		// most traded
		if (records[securityID].quantity > marketLeaders.mostTraded.quantity) {
			marketLeaders.mostTraded = {
				security: securities[securityID],
				quantity: records[securityID].quantity,
			}
		}

		// highest average price
		if (records[securityID].averagePrice > marketLeaders.highestAveragePrice.price) {
			marketLeaders.highestAveragePrice = {
				security: securities[securityID],
				price: records[securityID].averagePrice,
			}
		}

		// lowest average price
		if (records[securityID].averagePrice < marketLeaders.lowestAveragePrice.price) {
			marketLeaders.lowestAveragePrice = {
				security: securities[securityID],
				price: records[securityID].averagePrice,
			}
		}

		// most money moved
		if (records[securityID].totalMoney > marketLeaders.mostMoneyMoved.money) {
			marketLeaders.mostMoneyMoved = {
				security: securities[securityID],
				money: records[securityID].totalMoney,
			}
		}
	});

	return marketLeaders;
}

// the following the functions work together, since in order to calculate the IInternalMarketSecurityResult,
// information needs to be computed for each security
export function getSecurityResult(id: number, time: TimeFrame): IInternalMarketSecurityResult {
	const {securities} = store.getState().tradingStore;
	const shortResults: Array<[number, IInternalMarketSecurityResultShort]> = [];
	let result: IInternalMarketSecurityResult = {
		...getShortSecurityResult(id, time), // i know this is bad to call an extra time, but there were annoying tslinting errors
		priceRanking: 0,
		volumeRanking: 0,
	};

	// get short results for all securities
	for (const securitiesKey in securities) {
		const _id: number = securities[securitiesKey].id;
		const shortResult: IInternalMarketSecurityResultShort = getShortSecurityResult(_id, time);
		shortResults.push([_id, shortResult]);
	}

	// volume ranking
	const volumeRankings = shortResults.sort((a, b) => (a[1].quantityTraded - b[1].quantityTraded));
	volumeRankings.forEach((v, i) => {
		if (id === v[0]) {
			result.volumeRanking = i;
		}
	});

	// price ranking
	const priceRankings = shortResults.sort((a, b) => (a[1].averagePrice - b[1].averagePrice));
	priceRankings.forEach((v, i) => {
		if (id === v[0]) {
			result.priceRanking  = i;
		}
	});

	return result;
}

function getShortSecurityResult(id: number, time: TimeFrame): IInternalMarketSecurityResultShort {
	const {history, securities} = store.getState().tradingStore;
	const result: IInternalMarketSecurityResult = {
		security: securities[id],
		beginningDate: moment().startOf("hour"),
		timeFrame: time,
		averagePrice: 0,
		priceChange: 0,
		quantityTraded: 0,
		priceRanking: 0,
		volumeRanking: 0,
		lowestPriceTraded: Number.MAX_SAFE_INTEGER,
		highestPriceTraded: 0,
		numberOfTrades: 0,
		totalMoney: 0,
		history: [],
	};
	let changeTimeCheck: Moment;


	// determine starting time
	switch (time) {
		case TimeFrame.DAY:
			result.beginningDate = result.beginningDate.subtract(1, "day");
			changeTimeCheck = result.beginningDate.clone().subtract(1, "day");
			break;
		case TimeFrame.WEEK:
			result.beginningDate = result.beginningDate.subtract(1, "week");
			changeTimeCheck = result.beginningDate.clone().subtract(1, "week");
			break;
		case TimeFrame.MONTH:
			result.beginningDate = result.beginningDate.subtract(1, "month");
			changeTimeCheck = result.beginningDate.clone().subtract(1, "month");
			break;
	}

	let lastTotalMoney: number = 0;
	let lastVolumeOfTrade: number = 0;
	let lastAveragePrice: number = 0;


	history.forEach(({date, quantity, price, securityID}) => {
		const momentDate: Moment = moment(date);

		// calculate the previous time frames average price
		if (momentDate.isAfter(changeTimeCheck) && momentDate.isBefore(result.beginningDate) && securityID === id) {
			const total = Math.floor(price * quantity * 100) / 100;
			lastTotalMoney = ((lastTotalMoney + total) * 100) / 100;
			lastVolumeOfTrade = Math.floor(lastVolumeOfTrade + quantity);
			lastAveragePrice = Math.floor(lastTotalMoney / lastVolumeOfTrade * 100) / 100;
		}

		if (momentDate.isAfter(result.beginningDate) && securityID === id) {

			// total
			const total = Math.floor(price * quantity * 100) / 100;

			// total money
			result.totalMoney = ((result.totalMoney + total) * 100) / 100;

			// volume of trade
			result.quantityTraded = Math.floor(result.quantityTraded + quantity);

			// average price
			result.averagePrice = Math.floor(result.totalMoney / result.quantityTraded * 100) / 100;

			// number of trades
			result.numberOfTrades++;

			// lowest price traded
			if (price < result.lowestPriceTraded) {
				result.lowestPriceTraded = price;
			}

			// highest price traded
			if (price > result.highestPriceTraded) {
				result.highestPriceTraded = price;
			}

			// add to history
			result.history.push({
				date: momentDate.clone(),
				quantity,
				price,
			})
		}
	});

	// price change
	result.priceChange = Math.floor((lastAveragePrice - result.averagePrice) * 100) / 100;

	// order history sorting
	result.history = result.history.sort((a, b) => (a.date.isAfter(b.date) ? -1 : 1));

	let i: number;
	for (i = 0; i < result.history.length; i++) {
		result.history[i].date = result.history[i].date.valueOf() as unknown as any;
	}


	return result;
}
