import * as React from "react";
import {ReactNode} from "react";
import {
	FormControl,
	Grid,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Tab,
	Tabs,
	Typography,
	withStyles
} from "@material-ui/core";
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import {ISecurity} from "../services/redux/trading/tradingStore";
import {
	getInternalMarketLeaders,
	getSecurityResult,
	IInternalMarketLeaders,
	IInternalMarketSecurityResult,
	TimeFrame
} from "../services/internalMarket";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ComposedChart,
	Legend,
	Line,
	LineChart,
	Tooltip as ChartTip,
	XAxis,
	YAxis
} from "recharts";
import InternalMarketCard from "../components/InternalMarketCard";
import {numberWithCommas} from "../services/utils";
import InternalMarketCard2 from "../components/InternalMarketCard2";
import moment, {Moment} from "moment";

export interface IInternalMarketProps {
	classes?: any;
	allSecurities: { [key: number]: ISecurity },
	marketLeaders: IInternalMarketLeaders;
	initialSecurityInfo: IInternalMarketSecurityResult;
}

export interface IInternalMarketState {
	selectedSecurity: any;
	timeRange: TimeFrame;
	timeRangeTab: number;
	selectedSecurityInfo: IInternalMarketSecurityResult;
}

const defaultTimeRange: TimeFrame = TimeFrame.WEEK;

class InternalMarket extends React.Component<IInternalMarketProps, IInternalMarketState> {

	constructor(props: IInternalMarketProps) {
		super(props);
		this.state = {
			// selectedSecurity: undefined,
			selectedSecurity: props.marketLeaders.mostTraded.security.id,
			timeRange: defaultTimeRange,
			timeRangeTab: 1,
			selectedSecurityInfo: props.initialSecurityInfo,
		};
		this.securityChangeHandler = this.securityChangeHandler.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.formatMomentForChart = this.formatMomentForChart.bind(this);
		this.formatMomentForChartTip = this.formatMomentForChartTip.bind(this);
		this.chartTooltipFormatter = this.chartTooltipFormatter.bind(this);
		this.customTickLabel = this.customTickLabel.bind(this);
	}

	/**
	 * Intercept for the drop down changing values because of html form events
	 *
	 * @param event
	 */
	private securityChangeHandler(event: any): void {

		const {value} = event.target;

		this.setState({
			selectedSecurity: value,
			selectedSecurityInfo: getSecurityResult(value, this.state.timeRange),
		});
	}

	/**
	 * Creates all the items for the drop down
	 *
	 * @param securities
	 */
	private static createSecurityDropDowns(securities: ISecurity[]): ReactNode {
		return securities.map((security: ISecurity, i: number) => {
			return (
				<MenuItem value={security.id}>{security.name}</MenuItem>
			);
		})
	}

	/**
	 * Update state with new value for tab view
	 *
	 * @param event
	 * @param value
	 */
	private handleTabChange(event: any, value: any): void {
		let newTimeRange: TimeFrame = this.state.timeRange;
		switch (value) {
			case 0:
				newTimeRange = TimeFrame.DAY;
				break;
			case 1:
				newTimeRange = TimeFrame.WEEK;
				break;
			case 2:
				newTimeRange = TimeFrame.MONTH;
				break;
			default:
				newTimeRange = this.state.timeRange;
				break;
		}

		this.setState({
			timeRange: newTimeRange,
			timeRangeTab: value,
			selectedSecurityInfo: getSecurityResult(this.state.selectedSecurity, newTimeRange),
		});
	}

	private formatMomentForChart(tickItem: Moment): string {
		if (this.state.timeRange === TimeFrame.DAY) {
			return moment(tickItem).format("HH:mm");
		} else {
			return moment(tickItem).format("MMM Do YYYY");
		}
	}

	private formatMomentForChartTip(tickItem: Moment): string {
		return moment(tickItem).format("MMM Do YYYY HH:mm");
	}

	private chartTooltipFormatter(value: any, name: string, props: any): string {
		if (name === "price") {
			return "$" + value.toFixed(2);
		}

		return value;
	}

	private customTickLabel(label: any): any {
		const {
			x, y, stroke, payload,
		} = label;

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="end" fill="#666"
				      transform="rotate(-35)">{this.formatMomentForChart(payload.value)}</text>
			</g>
		);
	}

	public render(): ReactNode {

		const labelColumnWidth: any = 2;
		const dataColumnWidth: any = 10;

		const {classes, allSecurities, marketLeaders} = this.props;
		const info: IInternalMarketSecurityResult = this.state.selectedSecurityInfo;
		const selectionOptions: any = InternalMarket.createSecurityDropDowns(Object.values(allSecurities));

		return (
			<Paper className={classes.paper}>
				<div className={classes.contentWrapper}>

					<Grid container spacing={16} justify="center">
						<Grid item xs={12} sm={6} md={4}>
							<InternalMarketCard
								title={"Most Traded"}
								securityName={marketLeaders.mostTraded.security.name}
								value={numberWithCommas(marketLeaders.mostTraded.quantity)}
								toolTitle="The security that has the highest quantity traded since the beginning of the the simulation."
							/>
						</Grid>


						<Grid item xs={12} sm={6} md={4}>
							<InternalMarketCard
								title={"Highest Volume"}
								securityName={marketLeaders.mostMoneyMoved.security.name}
								value={"$" + numberWithCommas(marketLeaders.mostMoneyMoved.money)}
								toolTitle="The security that has the largest amount of money transferred since the beginning of the the simulation."
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<InternalMarketCard
								title={"Highest Average Price"}
								securityName={marketLeaders.highestAveragePrice.security.name}
								value={"$" + numberWithCommas(marketLeaders.highestAveragePrice.price)}
								toolTitle="The highest average price since the beginning of the the simulation."
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<InternalMarketCard
								title={"Lowest Average Price"}
								securityName={marketLeaders.lowestAveragePrice.security.name}
								value={"$" + numberWithCommas(marketLeaders.lowestAveragePrice.price)}
								toolTitle="The lowest average price since the beginning of the the simulation."
							/>
						</Grid>
					</Grid>

					<div className={classes.securitiesSelectorContainer} style={{marginTop: 30}}>
						<FormControl style={{width: "100%", maxWidth: 800}}>
							<Select
								value={this.state.selectedSecurity}
								onChange={this.securityChangeHandler}
								name={"selectedSecurity"}
								input={
									<OutlinedInput
										labelWidth={0}
										name="age"
										id="outlined-age-simple"
										placeholder={"test"}
										style={{
											marginTop: 7,
											color: this.state.selectedSecurity === "PLACEHOLDER" ? "#7c7c7c" : "black"
										}}
									/>
								}
							>
								<MenuItem value="PLACEHOLDER" disabled>
									Security
								</MenuItem>
								{selectionOptions}
							</Select>
						</FormControl>
					</div>

					<div style={{display: "flex", justifyContent: "center", marginTop: 30}} id="override-tab-bar-color">
						<Paper style={{width: "100%", maxWidth: 800, flexGrow: 1}}>
							<Tabs
								value={this.state.timeRangeTab}
								onChange={this.handleTabChange}
								indicatorColor="primary"
								textColor="primary"
								centered
								variant={"fullWidth"}
							>
								<Tab label="Day"/>
								<Tab label="Week"/>
								<Tab label="Month"/>
							</Tabs>
						</Paper>
					</div>

					{info.history.length > 1 ?
						<div style={{display: "flex", justifyContent: "center", marginTop: 40}}>
							<div className="graph-container" style={{overflowX: "scroll"}}>
								<ComposedChart
									width={700}
									height={400}
									data={info.history}
									margin={{
										top: 20, right: 30, left: 20, bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3"/>
									<XAxis
										dataKey="date"
										scale="time"
										type="number"
										domain={['dataMin', 'dataMax']}
										tickFormatter={this.formatMomentForChart}
										tick={this.customTickLabel}
										height={100}
									/>

									<YAxis
										yAxisId="left"
										domain={[0, 1]}
										tickFormatter={(tickItem) => "$" + tickItem.toFixed(2)}
									/>
									{/*<YAxis yAxisId="right" orientation="right"/>*/}

									{/*<Legend/>*/}
									{/*<Line yAxisId="right" type="monotone" dataKey="price" stroke="#82ca9d" fill="#82ca9d"/>*/}
									{/*<Bar*/}
									{/*yAxisId="right"*/}
									{/*dataKey="quantity"*/}
									{/*stroke="#82ca9d"*/}
									{/*fill="#82ca9d"*/}
									{/*barSize={80}*/}
									{/*/>*/}

									<Line yAxisId="left" type="monotone" dataKey="price" stroke="#8884d8"
									      fill="#8884d8"/>

									<ChartTip
										labelFormatter={this.formatMomentForChartTip}
										formatter={this.chartTooltipFormatter}
									/>
								</ComposedChart>
							</div>
						</div> :

						<div style={{marginTop: 30}}>
							<Typography variant="h4" style={{textAlign: "center"}}>
								Insufficient data points to display chart.
							</Typography>
						</div>
					}

					{info.history.length > 0 ?
						<Grid container spacing={16} justify="center" style={{marginTop: 40}}>
							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Average Price"}
									value={"$" + numberWithCommas(info.averagePrice)}
									toolTitle="The average traded price in the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Price Change"}
									value={"$" + numberWithCommas(info.priceChange)}
									toolTitle="The difference between the average price in the previous time frame and the current time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Quantity Traded"}
									value={numberWithCommas(info.quantityTraded)}
									toolTitle="The total quantity traded in the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Volume Traded"}
									value={"$" + numberWithCommas(info.totalMoney)}
									toolTitle="The total amount of money transferred in the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Price Ranking"}
									value={info.priceRanking}
									toolTitle="The ranking for the security in terms of average price within the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Volume Ranking"}
									value={info.volumeRanking}
									toolTitle="The ranking for the security in terms of quantity within the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Lowest Price Traded"}
									value={"$" + numberWithCommas(info.lowestPriceTraded)}
									toolTitle="The lowest price the security was traded for within the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Highest Price Traded"}
									value={"$" + numberWithCommas(info.highestPriceTraded)}
									toolTitle="The highest price the security was traded for within the time frame."
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3}>
								<InternalMarketCard2
									title={"Number of Trades"}
									value={numberWithCommas(info.numberOfTrades)}
									toolTitle="The number of transactions the security was traded for within the time frame."
								/>
							</Grid>
						</Grid> :

						<div style={{marginTop: 40}}>
							<Typography variant="h4" style={{textAlign: "center"}}>
								There must be at least 1 data point to calculate statistics.
							</Typography>
						</div>
					}

				</div>
			</Paper>
		);

	}
}

const styles = theme => ({
	paper: {
		maxWidth: 1100,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	}
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IInternalMarketProps) => {

	const marketLeaders: IInternalMarketLeaders = getInternalMarketLeaders();

	return {
		allSecurities: store.tradingStore.securities,
		marketLeaders: marketLeaders,
		initialSecurityInfo: getSecurityResult(marketLeaders.mostTraded.security.id, defaultTimeRange),
	}
})(InternalMarket));
