import React from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import tradingStore, {IUser} from "../services/redux/trading/tradingStore";
import Typography from "@material-ui/core/Typography";
import CurrentOffers, {cancelTradingTableType} from "../components/TableView/CurrentOffers";
import "./Summary.css";
import PortfolioTable from "../components/TableView/PortfolioTable";

interface IProps {
	classes: any,
	dispatch: any,
	securitiesData: ISecurityData[],
}

const Summary: React.FunctionComponent<IProps> = (props: IProps): JSX.Element => {
	const {classes, securitiesData} = props;

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold"}}>
					Holdings Breakdown
				</Typography>
				<div>
					<PortfolioTable
						data={securitiesData}
					/>
				</div>

				{/*<Typography style={{fontSize: 24, fontWeight: "bold", marginTop: 20}}>*/}
					{/*Current Offers*/}
				{/*</Typography>*/}
				{/*<CurrentOffers variety={cancelTradingTableType.SELL}/>*/}
				{/*<Typography style={{fontSize: 24, fontWeight: "bold", marginTop: 20}}>*/}
					{/*Current Bids*/}
				{/*</Typography>*/}
				{/*<CurrentOffers variety={cancelTradingTableType.BUY}/>*/}
			</div>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	row: {
		flexDirection: "row",
		alignItems: "center",
		display: "flex",
	},
	date: {
		width: 200,
	},
	message: {
		width: "100%"
	},
	history: {
		overflowY: "auto",
		height: 400,
		width: "100%",
		padding: 15,
	}
});

interface ISecurityData {
	name: string;
	invested: number;
	available: number;
	amt: number;
	securityID: number;
	totalShare: number;
	amountAboveOwned: number;
	amountBelowOwned: number;
	amountAboveListed: number;
	amountBelowListed: number;
	amountOwned: number;
	expirationDate: any;
	priceAtExpiration: number;
}

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps) => {

	// securitiesData
	const securitiyKeys = Object.keys(store.tradingStore.securities);
	const securitiesData: ISecurityData[] = [];
	const user: IUser = store.tradingStore.users[store.tradingStore.currentUser];

	for (const key of securitiyKeys) {

		const amt = user.securities[key].amountListed + user.securities[key].amountOwned;
		securitiesData.push({
			name:
				store.tradingStore.securities[key].bedrooms + " Bedroom(s)," + " " +
				store.tradingStore.securities[key].bathrooms + " Bathroom(s)" + "\n" +
				store.tradingStore.securities[key].lotType + ", " +
				store.tradingStore.securities[key].market,
			invested: user.securities[key].amountListed,
			available: user.securities[key].amountOwned,
			amt,
			securityID: parseInt(key),
			totalShare: amt / store.tradingStore.securities[key].totalIssued,

			// NEW
			amountAboveOwned: user.securities[key].amountAboveOwned,
			amountBelowOwned: user.securities[key].amountBelowOwned,
			amountAboveListed: user.securities[key].amountAboveListed,
			amountBelowListed: user.securities[key].amountBelowListed,

			amountOwned: user.securities[key].amountOwned,

			expirationDate: store.tradingStore.securities[key].expirationDate,
			priceAtExpiration: store.tradingStore.securities[key].priceAtExpiration,
		});
	}

	return {
		securitiesData,
		...props
	}
})(Summary));
