import * as React from "react";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/initialStore";
import {IOffer, IUser} from "../../services/redux/trading/tradingStore";
import {buyFromSellOffer, removeTradingError} from "../../services/redux/trading/tradingActions";
import {numberWithCommas} from "../TableView/tableUtils";

interface IBuySecuritiesModalProps {
	open: boolean;
	onClose: () => void;
	quantity: any;
	price: number;
	securityName: string;
	securityID: number;
	offerID: number;
	availableBalance?: number;
	user?: IUser;
	dispatch?: any;
	error?: string;
	fullOffer?: IOffer;
}

interface IBuySecuritiesModalState {
	quantity: any;
	showConfirmModal: boolean;
}

class BuySecuritiesModal extends React.Component<IBuySecuritiesModalProps, IBuySecuritiesModalState> {

	public static defaultProps: IBuySecuritiesModalProps = {
		open: false,
		onClose: () => {
		},
		quantity: "",
		price: 0,
		securityName: "",
		securityID: 0,
		offerID: 0,
		availableBalance: 0,
	};

	public constructor(props: IBuySecuritiesModalProps) {
		super(props);
		this.state = {
			quantity: props.quantity / 1000,
			showConfirmModal: false,
		};
		this.updateQuantity = this.updateQuantity.bind(this);
		this.handleOnPaste = this.handleOnPaste.bind(this);
		this.setQuantityToAll = this.setQuantityToAll.bind(this);
		this.calculatePrice = this.calculatePrice.bind(this);
		this.prepareConfirmationModal = this.prepareConfirmationModal.bind(this);
		this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
		this.attemptConfirm = this.attemptConfirm.bind(this);
		this.prepareCloseModal = this.prepareCloseModal.bind(this);
	}

	private updateQuantity(event: any): void {
		// const val: string = event.target.value;
		//
		// let ignoreChange: boolean = false;
		//
		// let newValue: any = parseInt(val, 10);
		// // prevent NaN
		// if (isNaN(newValue)) {
		// 	ignoreChange = true;
		// 	// allow if empty string (other wise you can't press backspace)
		// 	if (val === "") {
		// 		ignoreChange = false
		// 	}
		// } else {
		// 	// prevent negative numbers
		// 	if (newValue < 0) {
		// 		ignoreChange = true;
		//
		// 		if (this.state.quantity === "") {
		// 			this.setState({
		// 				quantity: 0,
		// 			});
		// 			return;
		// 		}
		// 	}
		//
		// 	// // prevent leading 0s (catches copy paste with leading 0s)
		// 	// if (val.length > 1) {
		// 	// 	newValue = newValue.toString().replace(/^0+/, '');
		// 	//
		// 	// 	this.setState({
		// 	// 		quantity: newValue,
		// 	// 	});
		// 	// 	return;
		// 	// }
		// }
		//
		// // double check for period
		// if (val.includes(".")) {
		// 	ignoreChange = true;
		// }
		//
		// // better way to prevent leading 0s
		// if (newValue === 0) {
		// 	ignoreChange = true;
		// }
		//
		// // see if entered value is above the maximum, if so set to maximum
		// if (newValue > this.props.quantity) {
		// 	this.setState({
		// 		quantity: this.props.quantity,
		// 	});
		// 	return;
		// }
		//
		// // handles for a copy paste that looks something like "100e"
		// if (event.target.value.indexOf("e") > 0) {
		// 	ignoreChange = true;
		// }

		const digitRegex: RegExp = RegExp("^[0-9]*$");
		let newVal: string = event.target.value;

		let update: boolean = false;

		if (digitRegex.test(newVal)) {
			update = true;
			if (parseInt(newVal, 10) > this.props.quantity) {
				newVal = this.props.quantity;
			}
		}

		this.setState({
			quantity: update ? newVal : this.state.quantity,
		})
	}

	/**
	 * Handles "." press which is allowed in number inputs;
	 * this can't be handled as the info isn't passed to onChange
	 *
	 * @param event
	 */
	private extraKeyDownHandler(event: any): void {
		// e
		if (event.keyCode === 190) {
			event.preventDefault();
		}

		// .
		if (event.keyCode === 69) {
			event.preventDefault();
		}

		// +
		if (event.keyCode === 187) {
			event.preventDefault();
		}

		// -
		if (event.keyCode === 189) {
			event.preventDefault();
		}
	}

	private handleOnPaste(event: any): void {
		let clipboardData: any;
		let pastedData: any;

		event.stopPropagation();
		event.preventDefault();

		clipboardData = event.clipboardData;
		pastedData = clipboardData.getData('Text');

		this.updateQuantity({target: {value: pastedData}});

	}

	private setQuantityToAll(): void {
		this.setState({
			quantity: this.props.quantity,
		})
	}

	private calculatePrice(): string {
		const price: any = (this.props.price * (parseFloat((this.state.quantity as string)) * 1000)).toFixed(2);

		if (!isNaN(price)) {
			return "$" + numberWithCommas(price);
		}

		return "Please enter a valid quantity";
	}

	private prepareConfirmationModal(): void {
		this.setState({showConfirmModal: true});
	}

	private closeConfirmationModal(): void {
		this.setState({showConfirmModal: false});
	}

	private async attemptConfirm(): Promise<void> {
		await this.props.dispatch(buyFromSellOffer(this.props.offerID, parseFloat(this.state.quantity as string) * 1000));

		this.setState({
			showConfirmModal: false,
		}, () => {
			if (!this.props.error) {
				this.props.onClose();
			}
		});
	}

	private async prepareCloseModal(): Promise<void> {
		await this.props.dispatch(removeTradingError());
		this.props.onClose();
	}

	public render(): JSX.Element {

		const dollarDifference: any = (parseFloat(this.state.quantity as string) * 1000 * this.props.price);
		const newBalance: number = ((this.props.availableBalance as number) - dollarDifference);

		// const amountOfCurrentSecurity: any = (this.props.user as IUser).securities[this.props.securityID].amountOwned;

		const injectPropsForSafari: any = {
			onTouchTap: this.attemptConfirm,
		};

		const injectPropsForSafari2: any = {
			onTouchTap: this.closeConfirmationModal,
		};

		const injectPropsForSafari3: any = {
			onTouchTap: this.prepareCloseModal,
		};

		const injectPropsForSafari4: any = {
			onTouchTap: this.prepareConfirmationModal,
		};

		// @ts-ignore
		const aboveOrBelowSubTitleIndicator: string = this.props.fullOffer.aboveMarketValue ? "Above Market Value" : "Below Market Value";
		// @ts-ignore
		const aboveOrBelowRegularIndicator: string = this.props.fullOffer.aboveMarketValue ? "above market value" : "below market value";

		// @ts-ignore
		const userOwnedBeforeCompleting: number = this.props.fullOffer.aboveMarketValue ? this.props.user.securities[this.props.securityID].amountAboveOwned : this.props.user.securities[this.props.securityID].amountBelowOwned;
		const amountOfCurrentSecurity: any = userOwnedBeforeCompleting;
		const newSecurityQuantity: number = amountOfCurrentSecurity + (parseFloat(this.state.quantity as string)) * 1000;


		return (
			<React.Fragment>
				<Dialog
					open={this.props.open}
					onClose={this.prepareCloseModal}
					fullWidth={true}
					maxWidth="sm"
				>
					<DialogTitle id="customized-dialog-title">
						Buy Securities
					</DialogTitle>

					<DialogContent>

						{this.props.error &&
                        <div>
                            <p style={{color: "red"}}>
								{this.props.error}
                            </p>
                        </div>
						}

						<div>
							<p
								style={{
									color: "gray",
									fontSize: 20,
									textAlign: "center"
								}}
							>
								{numberWithCommas(this.props.quantity) + " x " + this.props.securityName}
							</p>
						</div>

						<div>
							<p
								style={{
									color: "black",
									fontSize: 22,
									textAlign: "center"
								}}
							>
								{aboveOrBelowSubTitleIndicator}
							</p>
						</div>

						<div>
							<p
								style={{
									textAlign: "center",
									fontSize: 32,
									color: "black",
								}}
							>
								{this.calculatePrice()}
							</p>
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<div style={{marginRight: 15}}>
								<TextField
									label="Quantity"
									type="tel"
									inputProps={{
										max: this.props.quantity
									}}
									margin="normal"
									variant="outlined"
									value={this.state.quantity}
									onChange={this.updateQuantity}
								/>
							</div>
							<div
								style={{
									color: "gray",
									fontSize: 20,
								}}
							>
								{"(x 1000) @ "} ${this.props.price.toFixed(2)} / security
							</div>
						</div>

						<Divider light style={{height: 2, marginTop: 25, marginBottom: 25}}/>

						<Grid container spacing={24}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div style={style.transactionInfoRow}>
									<Typography>
										Balance before transaction
									</Typography>
									<Typography style={style.rightSideBalances}>
										${numberWithCommas((this.props.availableBalance as number).toFixed(2))}
									</Typography>
								</div>
								<div style={style.transactionInfoRow}>
									<Typography style={{color: "gray"}}>
										This transaction
									</Typography>
									<Typography style={{...style.rightSideBalances, color: "gray"}}>
										{!isNaN(dollarDifference) ? "- $" + numberWithCommas(dollarDifference.toFixed(2)) : "/"}
									</Typography>
								</div>
								<Grid container>
									<Grid item xs={9}/>
									<Grid item xs={3}>
										<Divider/>
									</Grid>
								</Grid>
								<div style={{...style.transactionInfoRow, marginTop: 10}}>
									<Typography style={{fontSize: 16}}>
										Final Balance
									</Typography>
									<Typography style={{...style.rightSideBalances, fontSize: 16}}>
										{!isNaN(newBalance) ? "$" + numberWithCommas(newBalance.toFixed(2)) : "/"}
									</Typography>
								</div>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div style={style.transactionInfoRow}>
									<Typography>
										Your quantity of {aboveOrBelowRegularIndicator + " " + this.props.securityName}
									</Typography>
									<Typography style={style.rightSideBalances}>
										{numberWithCommas(amountOfCurrentSecurity)}
									</Typography>
								</div>
								<div style={style.transactionInfoRow}>
									<Typography style={{color: "gray"}}>
										This transaction
									</Typography>
									<Typography style={{...style.rightSideBalances, color: "gray"}}>
										{!isNaN(parseFloat((this.state.quantity as string)) * 1000) ? "+ " + numberWithCommas(this.state.quantity * 1000) : "/"}
									</Typography>
								</div>
								<Grid container>
									<Grid item xs={9}/>
									<Grid item xs={3}>
										<Divider/>
									</Grid>
								</Grid>
								<div style={{...style.transactionInfoRow, marginTop: 10}}>
									<Typography style={{fontSize: 16}}>
										New Quantity of {aboveOrBelowRegularIndicator + " " + this.props.securityName}
									</Typography>
									<Typography style={{...style.rightSideBalances, fontSize: 16}}>
										{!isNaN(newSecurityQuantity) ? numberWithCommas(newSecurityQuantity) : "/"}
									</Typography>
								</div>
							</Grid>
						</Grid>

						<Divider light style={{height: 2, marginTop: 25, marginBottom: 25}}/>

					</DialogContent>

					<DialogActions>
						<Button
							color="primary"
							size="large"
							onClick={this.prepareCloseModal}
							{...injectPropsForSafari3}
						>
							Cancel
						</Button>
						<div style={{marginLeft: 15}}>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={this.prepareConfirmationModal}
								disabled={this.state.quantity.length < 1}
								{...injectPropsForSafari4}
							>
								Confirm Purchase
							</Button>
						</div>
					</DialogActions>
				</Dialog>


				<Dialog
					open={this.state.showConfirmModal}
					fullWidth={true}
					maxWidth={"sm"}
				>
					<DialogTitle id="customized-dialog-title">
						Are you sure you want to buy from this sell order?
					</DialogTitle>

					<Divider light style={{height: 2, marginTop: 10, marginBottom: 10}}/>

					<DialogContent>
						<Grid container spacing={24}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div style={style.transactionInfoRow}>
									<Typography>
										Balance before transaction
									</Typography>
									<Typography style={style.rightSideBalances}>
										${numberWithCommas((this.props.availableBalance as number).toFixed(2))}
									</Typography>
								</div>
								<div style={style.transactionInfoRow}>
									<Typography style={{color: "gray"}}>
										This transaction
									</Typography>
									<Typography style={{...style.rightSideBalances, color: "gray"}}>
										{!isNaN(dollarDifference) ? "- $" + numberWithCommas(dollarDifference.toFixed(2)) : "/"}
									</Typography>
								</div>
								<Grid container>
									<Grid item xs={9}/>
									<Grid item xs={3}>
										<Divider/>
									</Grid>
								</Grid>
								<div style={{...style.transactionInfoRow, marginTop: 10}}>
									<Typography style={{fontSize: 16}}>
										Final Balance
									</Typography>
									<Typography style={{...style.rightSideBalances, fontSize: 16}}>
										{!isNaN(newBalance) ? "$" + numberWithCommas(newBalance.toFixed(2)) : "/"}
									</Typography>
								</div>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div style={style.transactionInfoRow}>
									<Typography>
										Your quantity of {aboveOrBelowRegularIndicator + " " + this.props.securityName}
									</Typography>
									<Typography style={style.rightSideBalances}>
										{numberWithCommas(amountOfCurrentSecurity)}
									</Typography>
								</div>
								<div style={style.transactionInfoRow}>
									<Typography style={{color: "gray"}}>
										This transaction
									</Typography>
									<Typography style={{...style.rightSideBalances, color: "gray"}}>
										{!isNaN(parseFloat((this.state.quantity as string)) * 1000) ? "+ " + numberWithCommas(this.state.quantity * 1000) : "/"}
									</Typography>
								</div>
								<Grid container>
									<Grid item xs={9}/>
									<Grid item xs={3}>
										<Divider/>
									</Grid>
								</Grid>
								<div style={{...style.transactionInfoRow, marginTop: 10}}>
									<Typography style={{fontSize: 16}}>
										New Quantity of {aboveOrBelowRegularIndicator + " " + this.props.securityName}
									</Typography>
									<Typography style={{...style.rightSideBalances, fontSize: 16}}>
										{!isNaN(newSecurityQuantity) ? numberWithCommas(newSecurityQuantity) : "/"}
									</Typography>
								</div>
							</Grid>
						</Grid>
					</DialogContent>


					<DialogActions style={{marginTop: 20}}>
						<Button
							color="primary"
							size="large"
							onClick={this.closeConfirmationModal}
							{...injectPropsForSafari2}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={this.attemptConfirm}
							{...injectPropsForSafari}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

const style: any = {
	transactionInfoRow: {
		display: "flex",
		justifyContent: "space-between",
	},
	rightSideBalances: {
		marginLeft: 20,
	}
};

export default connect((store: IStore, props: IBuySecuritiesModalProps): IBuySecuritiesModalProps => {
	return {
		availableBalance: store.tradingStore.users[store.tradingStore.currentUser].availableBalance,
		user: store.tradingStore.users[store.tradingStore.currentUser],
		error: store.tradingStore.tradingError,
		fullOffer: store.tradingStore.sellOffers[props.offerID],
		...props,
	}
})(BuySecuritiesModal)
