import React from 'react';
import {Paper, Typography, withStyles} from "@material-ui/core";
import FinancialSummaryComponent from "../components/FinancialSummaryComponent";

export interface IFinancialSummaryProps {
	classes: any;
}

const FinancialSummary: React.FunctionComponent<IFinancialSummaryProps> = (props: IFinancialSummaryProps): JSX.Element => {

	const {classes} = props;

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography variant={"h5"} style={{marginBottom: 20}}>
					Financial Summary
				</Typography>
				<FinancialSummaryComponent/>
			</div>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

// @ts-ignore
export default withStyles(styles)(FinancialSummary);
