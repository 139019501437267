import React, {ReactNode, useState} from 'react';
import {IFinancialSummaryEntry} from "../services/financialSummary";
import {Divider, Tooltip, Typography} from "@material-ui/core";
import {numberWithCommas} from "../services/utils";
import {ExpandMore} from "@material-ui/icons";

export interface IFSDataDisplayProps extends IFinancialSummaryEntry {
	camelCaseLabel: string;
	ignoreTotalColor: boolean;
}

const FSDataDisplay: React.FunctionComponent<IFSDataDisplayProps> = (props: IFSDataDisplayProps): JSX.Element => {

	const {label, numbers, total, description, ignoreTotalColor} = props;

	const [arrowActive, updateArrowActive] = useState(false);
	const [showNumbers, updateShowNumbers] = useState(false);

	function handleExpandToggle(): void {
		updateArrowActive(!arrowActive);
		updateShowNumbers(!showNumbers);
	}

	function determineArrow(): string {
		if (arrowActive) {
			return "fsc-icon-button-rotate";
		}

		return "";
	}

	function makeNumberList(numbers: number[] = []): ReactNode {
		if (numbers.length < 1) {
			return null;
		}

		return numbers.map((num: number, i: number) => {

			let displayString: string = "$" + numberWithCommas(Math.abs(num).toFixed(2));
			if (num < 1) {
				displayString = "(" + displayString + ")"
			}

			return (
				<div
					key={`num-list-${label}-${num}` + Math.random()}
					className="fsc-number-row"
					style={{...style.flexRow, justifyContent: "flex-end"}}
				>
					<Typography>
						{displayString}
					</Typography>
					<div style={{width: 20}}/>
				</div>
			);
		});
	}

	// @ts-ignore
	let displayTotal: string = "$" + numberWithCommas(Math.abs(props.total).toFixed(2));

	let totalColor: string = "green";
	// @ts-ignore
	if (props.total < 1) {
		totalColor = "red";
		displayTotal = "(" + displayTotal + ")"
	}

	if (ignoreTotalColor) {
		totalColor = "black";
	}


	return (
		<div style={style.container}>
			<div style={style.flexItem}>
				<Tooltip
					title={description}
					placement={"top"}
				>
					<Typography variant={"subtitle1"}>
						{props.label}
					</Typography>
				</Tooltip>
			</div>

			<div style={{...style.flexCol, width: 100}}>
				{showNumbers && (
					<React.Fragment>
						<div
							style={style.flexCol}
							className="fsc-number-controller"
						>
							{
								// @ts-ignore
								makeNumberList(numbers)
							}
						</div>

						<div style={style.flexRow}>
							<div style={{width: "100%", height: 1, backgroundColor: "black"}}/>
							<div style={{width: 20}}/>
						</div>
					</React.Fragment>
				)}

				<div style={{...style.flexRow, justifyContent: "flex-end"}}>
					<Typography style={{fontWeight: "bold", color: totalColor}}>
						{
							displayTotal
						}
					</Typography>

					<div style={{width: 20}}>
						{(numbers && numbers.length > 1) && (
							<div
								id="icon-expand-button"
								className={"fsc-icon-button " + determineArrow()}
								onClick={handleExpandToggle}
							>
								<ExpandMore/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const style: any = {
	container: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-between",
		marginTop: 10,
	},
	flexItem: {
		display: "flex"
	},
	flexCol: {
		display: "flex",
		flexDirection: "column",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
};

export default FSDataDisplay;
