import {
	withStyles,
	Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import "./DemoIntro.css";

interface IProps {
	classes: any;
}

const about = [
	"The website provides a demo version of the Exchange. Currently, the Exchange offers prediction options in Greater Vancouver Area only. These options are written on so-called “neighborhood” residential real estate properties. There are seven neighborhoods: Vancouver Westside, Vancouver Eastside, North Vancouver, West Vancouver, Burnaby, Richmond and New Westminster. There are two types of the underlying properties, two bedroom, two bathroom apartment, and three bedroom, two bathroom detached house. The options have two expiration cycles (six or twelve months). They are similar to so-called “binomial” options traded at NADEX (North American Derivatives Exchange), and at CBOE (Chicago Board Options Exchange). Binomial options pay a fixed amount if an event happens, and nothing if it doesn’t.",
	"In the Exchange, the event is defined as the actual average price of the neighborhood property (apartment or house) at a future pre-determined date (either June 30 or December 31).",
	"Major feature of the options traded at Exchange is that their prices reflect the probability of a future event happening and thus range from zero to $1.00 (100% probability or certainty).",
	"The major difference between binomial and conventional options is that the holders of binomial options cannot “exercise” them (cannot buy or sell) the underlying asset. In our case, the holder cannot buy or sell the apartment or a house.",
	"The Exchange will be selling the options in a contract (“The Contract”) that consists of two options with two mutually exclusive events. (The actual price will be either above or below the price in the option contract). The Contracts will sell shares in lots of 1,000.",
	"At this stage, the Exchange is offering trading only with “play” money. Trading with real money is planned for the next stage of the project.",
	"Once we will have finished testing the beta version of the platform in Vancouver, the Exchange will open for trading in six other Canadian cities (Calgary, Edmonton, Winnipeg, Toronto, Montreal and Halifax). Trading with real money will begin 12 months after beta testing (in 2021)."
];
const investmentInformation = "We plan to finish the first round of financing by the end of March 2020. If interested, please contact:";

const DemoIntro: React.FunctionComponent<IProps> = ({classes}: IProps): JSX.Element => (

	<Paper className={classes.paper}>
		<div className={classes.contentWrapper}>
			<Typography variant="h5">
				About the Exchange
			</Typography>
			<Typography>
				<br/>
				{about[0]}
				<br/>
				<br/>
				{about[1]}
				<br/>
				<br/>
				{about[2]}
				<br/>
				<br/>
				{about[3]}
				<br/>
				<br/>
				{about[4]}
				<br/>
				<br/>
				An example of a Contract in the Exchange would be: “The average price of a 3 bedroom, 2 bathroom detached house on a standard city 133 x 33 feet lot on Vancouver Westside will be <b>$3.5 million and above/below $3.5 million on June 30, 2020</b>”. It consists of two securities, “above” security (price at expiration will be $3.5 million and above), and a “below” security (“price at expiration will be below $3.5 million”).
				<br/>
				<br/>
				{about[5]}
				<br/>
				<br/>
				{about[6]}
			</Typography>
			<br/>
			<br/>
			<Typography variant="h5">
				Investment Information
			</Typography>
			<br/>
			<Typography>
				{investmentInformation}
			</Typography>
			<br/>
			<Typography>

				<b>Momo Deretic</b>
				<br/>

				<a href="tel:6043587349" className="anchor-fix">(604) 358-7349</a>
				<br/>

				<a
					href="mailto:momo@jazmodesoftware.com?subject=Real Estate Securities Trading (Alpha)"
					target="_blank"
					className="anchor-fix"
				>
					momo@jazmodesoftware.com
				</a>
				<br/>
				<a href="https://jazmodesoftware.com/" target="_blank" className="anchor-fix">jazmodesoftware.com</a>
			</Typography>
		</div>
	</Paper>
);

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	card: {
		width: 345,
	},
	media: {
		height: 360,
	},
	text: {
		margin: '0px 16px',
	}
});

// @ts-ignore
export default withStyles(styles)(DemoIntro)
