import React, {ReactNode} from "react";
import {getSorting, stableSort, styles} from "./tableUtils";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import EnhancedTableHead from "./EnhancedTableHead";
import TableRow from "@material-ui/core/TableRow";
import {IHistory, IOfferFilled, Listing} from "../../services/redux/trading/tradingStore";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/initialStore";
import {numberWithCommas} from "../../services/utils";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

export enum tableOrder {
	ASCENDING = "asc",
	DESCENDING = "desc",
}

export enum historyOfOfferTradingTableType {
	BUY = "BUY",
	SELL = "SELL",
	BOTH = "BOTH",
}

interface IProps {
	classes?: any;
	variety: historyOfOfferTradingTableType,
	data: any[],
	dispatch?: any;
}

interface IHistoryOfOffersState {
	order: tableOrder,
	orderBy: string; // TODO
	selected: any[];
	page: number;
	rowsPerPage: number;
	selectedIndex?: number;
	tableKey: any;
}

const historyOffersColumns: any[] = [
	{
		numeric: true,
		id: "type",
		label: "Trade Type",
	},
	{numeric: true, id: "securityID", label: "Property Type"},
	{numeric: true, id: "date", label: "Date"},
	{numeric: true, id: "amount", label: "Amount"},
	{numeric: true, id: "price", label: "Price"},
	{numeric: true, id: "moneyMoved", label: "Money Transferred"},
	{numeric: true, id: "active", label: "Active / Inactive"},
	{numeric: true, id: "aboveMarketValue", label: "Above/Below Market Value"}
];

class HistoryOfOffers extends React.Component<IProps, IHistoryOfOffersState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			page: 0,
			rowsPerPage: 10,
			tableKey: Math.random() * Math.random() * Math.random(),
		};
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
			tableKey: Math.random() * Math.random() * Math.random(),
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
	private createRows(rows: any[]): any {
		return rows.map((row: any, i: number) => {

			// @ts-ignore
			const type: string = row.type;
			return (
				<TableRow
					hover
					tabIndex={-1}
					key={i + type}
					style={{
						height: 69,
						whiteSpace: "pre",
						lineHeight: 1.7,
					}}
				>
					<TableCell>{type}</TableCell>
					<TableCell>
						{
							row.bedrooms + " Bedroom(s)," + " " +
							row.bathrooms + " Bathroom(s)," + "\n" +
							row.lotType + ", " +
							row.market
						}
					</TableCell>
					<TableCell align="right">{moment(row.date).format("MMM Do YYYY")}</TableCell>
					<TableCell align="right">{numberWithCommas(row.initialAmount)}</TableCell>
					<TableCell align="right">{"$" + row.price.toFixed(2)}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.moneyMoved.toFixed(2))}</TableCell>
					<TableCell align="right">{row.active === true ? "Active" : "Inactive"}</TableCell>
					<TableCell align="right">{row.aboveMarketValue === true ? "Above" : "Below"}</TableCell>

				</TableRow>
			);
		})
	}

	public render(): JSX.Element {

		const {data, classes} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		const rowsToShow: any = stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		return (
			<React.Fragment>
				<Paper className={classes.root}>
					<div className={classes.tableWrapper}>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							key={this.state.tableKey}
						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onRequestSort={this.handleRequestSort}
								rowCount={data.length}
								columns={historyOffersColumns}
								includeSpaceForButton={false}
							/>

							<TableBody>
								{rows}
								{emptyRows > 0 && (
									<TableRow style={{height: 55 * emptyRows}}>
										<TableCell colSpan={6}/>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>

					<TablePagination
						rowsPerPageOptions={[10, 15, 20]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={(this.changePage as any)}
						onChangeRowsPerPage={(this.changeRowsPerPage as any)}
					/>
				</Paper>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps): IProps => {

	let data: any[];
	const userID: number = store.tradingStore.currentUser;

	if (props.variety === historyOfOfferTradingTableType.BUY) {
		// Buy Offers

		// @ts-ignore
		// data = store.tradingStore.history.filter((item: IHistory) => {
		// 	return item.listerID === userID && item.listingType === Listing.BID;
		// }).map((item: IHistory, i: number) => {
		// 	return {
		// 		...item,
		// 		...store.tradingStore.securities[item.securityID],
		// 		security: store.tradingStore.securities[item.securityID],
		// 		id: item.securityID,
		// 		quantity: item.quantity,
		// 	};
		// });

		data = Object.keys(store.tradingStore.bidHistory)
			.filter((key: string) => {
				return userID === store.tradingStore.bidHistory[key].userID;
			}).map((key: string): any => {
				return {
					...store.tradingStore.securities[store.tradingStore.bidHistory[key].securityID],
					...store.tradingStore.bidHistory[key],
					type: store.tradingStore.bidHistory[key].listingType,
					moneyMoved: store.tradingStore.bidHistory[key].amount * store.tradingStore.bidHistory[key].price,
				}
			});


	} else if (props.variety === historyOfOfferTradingTableType.SELL) {
		// Sell Offers

		// @ts-ignore
		// data = store.tradingStore.history.filter((item: IHistory) => {
		// 	return item.listerID === userID && item.listingType === Listing.OFFER;
		// }).map((item: IHistory, i: number) => {
		// 	return {
		// 		...item,
		// 		...store.tradingStore.securities[item.securityID],
		// 		security: store.tradingStore.securities[item.securityID],
		// 		id: item.securityID,
		// 		quantity: item.quantity,
		// 	};
		// });

		data = Object.keys(store.tradingStore.offerHistory)
			.filter((key: string) => {
				return userID === store.tradingStore.offerHistory[key].userID;
			}).map((key: string): any => {
				return {
					...store.tradingStore.securities[store.tradingStore.offerHistory[key].securityID],
					...store.tradingStore.offerHistory[key],
					type: store.tradingStore.offerHistory[key].listingType,
					moneyMoved: store.tradingStore.offerHistory[key].amount * store.tradingStore.offerHistory[key].price,
				}
			});

	} else {
		// Both

		const buy = Object.keys(store.tradingStore.bidHistory)
			.filter((key: string) => {
				return userID === store.tradingStore.bidHistory[key].userID;
			}).map((key: string): any => {
				return {
					...store.tradingStore.securities[store.tradingStore.bidHistory[key].securityID],
					...store.tradingStore.bidHistory[key],
					type: store.tradingStore.bidHistory[key].listingType,
					moneyMoved: store.tradingStore.bidHistory[key].amount * store.tradingStore.bidHistory[key].price,
				}
			});

		const sell = Object.keys(store.tradingStore.offerHistory)
			.filter((key: string) => {
				return userID === store.tradingStore.offerHistory[key].userID;
			}).map((key: string): any => {
				return {
					...store.tradingStore.securities[store.tradingStore.offerHistory[key].securityID],
					...store.tradingStore.offerHistory[key],
					type: store.tradingStore.offerHistory[key].listingType,
					moneyMoved: store.tradingStore.offerHistory[key].amount * store.tradingStore.offerHistory[key].price,
				}
			});

		data = cloneDeep(buy);
		data = data.concat(sell);
	}

	return ({
		...props,
		data,
	})
})(HistoryOfOffers));
