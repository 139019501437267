import {ISecuritiesType, ISecurity, IUser} from "./tradingStore";
import sample from "lodash/sample";
import cloneDeep from "lodash/cloneDeep";
import random from "lodash/random";

const possibleDeposits: number[] = [];
const minimumBalance: number = 100000;
let deposit;
for (deposit = 1000; deposit < minimumBalance; deposit += 1000) {
	possibleDeposits.push(deposit);
}

const possibleWithdrawals: number[] = [];
const minimumWithdrawals: number = 25000;
let withdrawal;
for (withdrawal = 1000; withdrawal < minimumWithdrawals; withdrawal += 1000) {
	possibleWithdrawals.push(withdrawal);
}

const maxAmountOwned = 10000;
const ownedIteration = 1000;

const possibleSecuritiesOwned: number[] = [];
let security;
for (security = 0; security < maxAmountOwned; security += ownedIteration) {
	possibleSecuritiesOwned.push(security);
}

type partialUser = {
	deposits: number[],
	withdrawals: number[],
	availableBalance: number,
	investedBalance: number,
	securities: ISecuritiesType,
}
const base = `${process.env.PUBLIC_URL}/profile_pictures/`;
function createURL(number: number, male: boolean): string {
	return male ? `${base}male${number}.jpg` : `${base}female${number}.jpg`
}

export default (securities: { [key: number]: ISecurity }): { [key: number]: IUser } => {
	const securityKeys = Object.keys(securities);

	function generateSecurities(): ISecuritiesType {
		const sec: ISecuritiesType = {};
		securityKeys.forEach((key) => {
			const amountAboveOwned = sample(possibleSecuritiesOwned);
			const amountBelowOwned = (amountAboveOwned + 0) as number;
			sec[key] = {
				amountOwned: Math.round(amountAboveOwned + amountBelowOwned),
				amountListed: 0,
				amountAboveOwned,
				amountAboveListed: 0,
				amountBelowOwned,
				amountBelowListed: 0,
			};
		});
		return sec
	}

	function createPartial(): partialUser {
		const deposits: number[] = [];
		const withdrawals: number[] = [];
		let availableBalance: number = 0;

		// do withdrawals first so enough deposits will be made
		while (Math.abs(availableBalance) < minimumWithdrawals) {
			const newWithdrawal = sample(possibleWithdrawals);
			withdrawals.push(newWithdrawal);
			availableBalance = Math.round(availableBalance - newWithdrawal)
		}

		while (availableBalance < minimumBalance) {
			const newDeposit = sample(possibleDeposits);
			deposits.push(newDeposit);
			availableBalance = Math.round(availableBalance + newDeposit)
		}

		return {
			deposits,
			withdrawals,
			availableBalance,
			investedBalance: 0,
			securities: generateSecurities(),
		}
	}

	const users: { [key: number]: IUser } = {
		0: {
			...createPartial(),
			photoURL: createURL(1, true),
			firstName: "Tim",
			lastName: "Lowe",
			email: "tim.lowe@bigbusiness.ca",
			phoneNumber: "(604) 555-1234",
			address: {
				city: "Vancouver",
				street: "122 Walter Hardwick Ave 305",
				postalCode: "V5Y 0C9"
			},
			id: 0,
		},
		1: {
			...createPartial(),
			photoURL: createURL(2, true),
			firstName: "John",
			lastName: "Hail",
			email: "JPowers@realestateinvestor.com",
			phoneNumber: "(775) 555-4321",
			address: {
				city: "Vancouver",
				street: "13308 Ash St Vancouver",
				postalCode: "V5Z 3E3"
			},
			id: 1,
		},
		2: {
			...createPartial(),
			photoURL: createURL(3, true),
			firstName: "Derek",
			lastName: "Schneider",
			email: "ds_trader@realestateinvestment.org",
			phoneNumber: "(604) 555-8080",
			address: {
				city: "Vancouver",
				street: "275 28th Ave E",
				postalCode: "V5V 2M5"
			},
			id: 2,
		},
		3: {
			...createPartial(),
			photoURL: createURL(4, true),
			firstName: "Thomas",
			lastName: "Ether",
			email: "mrether@remaxing.com",
			phoneNumber: "(604) 555-9898",
			address: {
				city: "Vancouver",
				street: "106 588 45th Ave W",
				postalCode: "V5Z 4S3"
			},
			id: 3,
		},
		4: {
			...createPartial(),
			photoURL: createURL(5, true),
			firstName: "Elliot",
			lastName: "Diamond",
			email: "mrether@remaxing.com",
			phoneNumber: "(604) 555-0001",
			address: {
				city: "Vancouver",
				street: "3007 8th Ave W",
				postalCode: "V6K 2C2"
			},
			id: 4,
		},
		5: {
			...createPartial(),
			photoURL: createURL(1, false),
			firstName: "Anna",
			lastName: "Bostock",
			email: "ABostock@annatheagent.ca",
			phoneNumber: "(604) 555-3999",
			address: {
				city: "Vancouver",
				street: "201 151 14th Ave W",
				postalCode: "V5Y 1W8"
			},
			id: 5,
		},
		6: {
			...createPartial(),
			photoURL: createURL(2, false),
			firstName: "Ashley",
			lastName: "Evanson",
			email: "evanson@evansonfinancial.com",
			phoneNumber: "(604) 555-1231",
			address: {
				city: "Vancouver",
				street: "1949 Comox St 305",
				postalCode: "V6G 1R7"
			},
			id: 6,
		},
		7: {
			...createPartial(),
			photoURL: createURL(3, false),
			firstName: "Gracy",
			lastName: "Smith",
			email: "gracy_smith@tel.net",
			phoneNumber: "(604) 555-7777",
			address: {
				city: "Vancouver",
				street: "1410 Tolmie St",
				postalCode: "V6R 4B3"
			},
			id: 7,
		},
		8: {
			...createPartial(),
			photoURL: createURL(4, false),
			firstName: "Elizabeth",
			lastName: "Grammar",
			email: "eliza_grammar12@workfromhome.com",
			phoneNumber: "(604) 555-7890",
			address: {
				city: "Vancouver",
				street: "303 621 57th Ave W",
				postalCode: "V6P 6P5"
			},
			id: 8,
		},
		9: {
			...createPartial(),
			photoURL: createURL(5, false),
			firstName: "Jane",
			lastName: "Ishmael",
			email: "JaneI@homesforsale.ca",
			phoneNumber: "(604) 555-2323",
			address: {
				city: "Vancouver",
				street: "2425 7th Ave W",
				postalCode: "V6K 1Y6"
			},
			id: 9,
		},
	};

	// distribute securities so everyone doesn't have an equal amount of above and below
	let iterations = 1000;
	const numberOfUsers = Object.keys(users).length;
	while (iterations > 0) {
		const user1ID: number = random(numberOfUsers - 1);
		const user2ID: number = random(numberOfUsers - 1);
		const securityKey: number = sample(securityKeys) as unknown as number;
		const marketValueKey: string = sample([true, false]) ? "amountAboveOwned" : "amountBelowOwned";
		const user1Available: number = users[user1ID].securities[securityKey][marketValueKey];
		const user1TotalAvailable: number = users[user1ID].securities[securityKey].amountOwned;
		const user2Available: number = users[user2ID].securities[securityKey][marketValueKey];
		const user2TotalAvailable: number = users[user2ID].securities[securityKey].amountOwned;
		const maxAmount: number = Math.floor(user1Available / ownedIteration);
		const transferAmount = Math.floor(random(0, maxAmount) * ownedIteration);
		users[user1ID].securities[securityKey][marketValueKey] = Math.round(user1Available - transferAmount);
		users[user1ID].securities[securityKey].amountOwned = Math.round(user1TotalAvailable - transferAmount);
		users[user2ID].securities[securityKey][marketValueKey] = Math.round(user2Available + transferAmount);
		users[user2ID].securities[securityKey].amountOwned = Math.round(user2TotalAvailable + transferAmount);
		iterations--;
	}

	console.log(users);

	return cloneDeep(users) as { [key: number]: IUser };
}
