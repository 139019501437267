import React, {useEffect, useState} from 'react';
import {
	Button,
	Dialog, DialogActions, DialogContent,
	DialogTitle,
	FormControl,
	Grid, Input,
	InputLabel,
	Paper, Select, Tooltip,
	Typography,
	withStyles
} from "@material-ui/core";
import {IStore} from "../services/redux/initialStore";
import {IUser} from "../services/redux/trading/tradingStore";
import {connect} from "react-redux";
import {changeUser, markPageAsVisited} from "../services/redux/trading/tradingActions";
import values from "lodash/values";
import {numberWithCommas} from "../services/utils";

export interface IProfileProps {
	classes?: any;
	dispatch: any,
	user: IUser;
	users: IUser[];
	firstTime?: boolean;
}

function createUserItem(user: IUser): JSX.Element {
	return (<option key={`user_${user.id}`}
	                value={user.id}>{user.firstName + " " + user.lastName + " ($" + numberWithCommas(user.availableBalance) + ")"}</option>)
}

const changeUserTooltip = "Changing the user is only available in this alpha, it allows the switching of accounts in order to get a better perspective of how the product will work in later releases.";

const Profile: React.FunctionComponent<IProfileProps> = (props: IProfileProps): JSX.Element => {

	const {classes, dispatch, user, users, firstTime} = props;

	const [modalOpen, setModalOpen] = useState(false);

	function openChangeUserModal(): void {
		setModalOpen(true);
	}

	const handleChange = event => {
		dispatch(changeUser(parseFloat(event.target.value)));
		setModalOpen(false);
	};

	const injectPropsForSafari: any = {
		onTouchTap: openChangeUserModal,
	};

	// first time visit tracking
	async function handleCloseFirstTimeModal(): Promise<void> {
		await dispatch((markPageAsVisited("/my-portfolio/profile")));
	}

	// @ts-ignore
	const firstTimeModalOpen: boolean = firstTime;
	const injectPropsForSafari2: any = {
		onTouchTap: handleCloseFirstTimeModal,
	};

	return (
		<Paper className={classes.paper}>
			<Dialog
				open={firstTimeModalOpen}
				onClose={handleCloseFirstTimeModal}
				fullWidth={true}
				maxWidth={"sm"}
			>
				<DialogTitle id="customized-dialog-title">
					Accounts Explanation
				</DialogTitle>

				<DialogContent>
					<Typography>
						The profile page provides an overview of the currently logged in user. For the sake of the alpha version of the exchange, there are 10 pre-inflated personas, all with their own unique portfolio and balances. In order to get a better idea on the market, you may use this page to switch which user you are logged in as.
					</Typography>

					<DialogActions style={{marginTop: 20}}>
						<Button
							color="primary"
							size="large"
							onClick={handleCloseFirstTimeModal}
							{...injectPropsForSafari2}
						>
							Close
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>


			<div className={classes.contentWrapper}>
				<Grid
					container
					spacing={24}
					alignItems={"center"}
					justify={"center"}
					alignContent={"center"}
				>
					<Grid item xs={12} sm={12} md={3} container justify={"center"} alignItems={"center"}>
						<img
							src={user.photoURL}
							className="account-profile-image"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={7} container direction="column" justify={"center"}
					      alignItems={"center"}>
						<Typography variant={"h4"} style={{textAlign: "center"}}>
							{user.firstName + " " + user.lastName}
						</Typography>

						<Typography variant={"subtitle1"} style={{textAlign: "center"}}>
							{user.address.street + " " + user.address.city + " " + user.address.postalCode}
						</Typography>

						<Typography variant={"subtitle2"} style={{textAlign: "center"}}>
							{"Email: " + user.email}
						</Typography>
						<Typography variant={"subtitle2"} style={{textAlign: "center"}}>
							{"Phone: " + user.phoneNumber}
						</Typography>
					</Grid>
				</Grid>

				<div style={{display: "flex", justifyContent: "center", marginTop: 80}}>
					<Tooltip title={changeUserTooltip} placement="top">
						<Button
							onClick={openChangeUserModal}
							variant="contained"
							color="primary"
							size="large"
							{...injectPropsForSafari}
						>
							Change User
						</Button>
					</Tooltip>
				</div>
			</div>

			<Dialog
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			>
				<DialogTitle>Select a User</DialogTitle>
				<DialogContent>
					<form className={classes.container}>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="user-id">User ID</InputLabel>
							<Select
								native
								value={user.id}
								onChange={handleChange}
								input={<Input id="user-id"/>}
							>
								{users.map(createUserItem)}
							</Select>
						</FormControl>
					</form>
				</DialogContent>
			</Dialog>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProfileProps) => {

	function alphabeticalSort(a, b): any {
		if (a.firstName < b.firstName) {
			return -1;
		}
		if (a.firstName > b.firstName) {
			return 1;
		}
		return 0;
	}

	return {
		user: store.tradingStore.users[store.tradingStore.currentUser],
		users: values(store.tradingStore.users).sort(alphabeticalSort),
		firstTime: store.tradingStore.firstTimePages.includes("/my-portfolio/profile"),
		...props,
	}
})(Profile));
