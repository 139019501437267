import React from "react";
import "./ShowCaseButton.css";

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	light?: boolean;
	slim?: boolean;
	onClick?(): void;
}


const ShowcaseButton: React.FC<IProps> = (props) => {

	return (
		<button
			onClick={props.onClick}
			className={(props.light ? "showcase-button-lt " : "showcase-button-dark ") + props.className}
			style={{
				padding: props.slim ? "5px 15px" : "10px 15px",
				justifyContent: "center",
				...props.style,
			}}
		>
			{props.children}
		</button>
	);
};

ShowcaseButton.defaultProps = {
	light: true,
	slim: false,
	className: "",
	onClick: null,
};

export default ShowcaseButton
