import moment from "moment";

export function numberWithCommas(x: number | string): string {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function validateEmail(email: string): boolean {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export function validCreditCardNumber(creditCardNumber: string): boolean {
	const regex = new RegExp("^[0-9]{16}$");
	return regex.test(creditCardNumber)
}

export function validCVV(cvv: string): boolean {
	const re = /^[0-9]{3,4}$/;
	return re.test(cvv)
}

export function validMonth(month: number): boolean {
	if (Math.floor(month) !== month) {
		return false;
	}
	return month >= 1 && month <= 12;
}

export function validYear(year: number): boolean {
	if (Math.floor(year) !== year) {
		return false;
	}
	const currentYear = moment().year();
	return year >= currentYear - 1 && year <= currentYear + 50;
}

export function isInFuture(month: number, year: number): boolean {
	return moment().isBefore(moment().year(year).month(month));
}
