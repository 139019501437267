import cloneDeep from "lodash/cloneDeep";
import sample from "lodash/sample";
import random from "lodash/random";
import moment, {Moment} from "moment";
import initialUsers from "./initialUsers";
import {
	bathroomPrice,
	bedroomPrice,
	ISecurity,
	ITradingStore,
	Listing,
	LotType,
	lotTypePrice, marketGrowth,
	marketPrice,
	minuteIncrement,
	numberOfOffers,
	possibleAboveMarketValues,
	possibleAmounts,
	possibleMarkets,
	possiblePrices
} from "./tradingStore";

export default function createDefaultTradingStore(): ITradingStore {
	// reset memo
	priceMemo = {};

	// Create Securities -- changed to descriptions of houses
	let numberOfSecurities = 0;
	const securities: { [key: number]: ISecurity } = {};

	// https://github.com/Metroxe/realestate-securities-alpha/issues/62
	// https://github.com/Metroxe/realestate-securities-alpha/issues/90
	// https://github.com/Metroxe/realestate-securities-alpha/issues/126
	[
		[2, 2, LotType.STANDARD_LOT],
		[3, 2, LotType.DETACHED_LOT],
	].forEach(([bedrooms, bathrooms, lotType]: any) => {
		possibleMarkets.forEach((market) => {
			const name = `${bedrooms} Bedroom, ${bathrooms} Bathroom, ${lotType}, ${market}`;
			const priceAsOf = Math.round(bedrooms * bedroomPrice + bathrooms * bathroomPrice + lotTypePrice[lotType] + marketPrice[market]);
			const priceAtExpiration = Math.round((priceAsOf * marketGrowth[market]) / 1000) * 1000;
			const issueDate = moment().startOf("day").subtract(2, "months");
			const expirationDate = issueDate.clone().add(1, "year").set('month', 5).endOf('month');
			securities[numberOfSecurities] = cloneDeep({
				name, bedrooms, bathrooms, lotType, market, priceAsOf, priceAtExpiration,
				id: numberOfSecurities,
				totalIssued: 0,
				aboveIssued: 0,
				belowIssued: 0,
				issueDate: issueDate.toISOString(),
				expirationDate: expirationDate.toISOString(),
			});
			numberOfSecurities++;
		})
	});

	// starts at 0
	const users = initialUsers(securities);
	let numberOfUsers = Object.keys(users).length;
	let numberOfBuyOffers = numberOfUsers * numberOfSecurities;
	let numberOfSellOffers = numberOfUsers * numberOfSecurities;
	let i;

	const store: ITradingStore = {
		sellOffers: {},
		buyOffers: {},
		users,
		securities,
		currentUser: random(numberOfUsers - 1),
		userIncrement: numberOfUsers + 1,
		sellOfferIncrement: numberOfSellOffers + 1,
		buyOfferIncrement: numberOfBuyOffers + 1,
		securitiesIncrement: numberOfSecurities + 1,
		history: [],
		offerHistory: {},
		bidHistory: {},
		firstTimePages: [
			"/my-portfolio/profile",
		],
	};

	// Create Buy Offers
	i = numberOfBuyOffers;
	while (i > 0) {
		const userID: number = random(numberOfUsers - 1);
		const securityID: number = random(numberOfSecurities - 1);
		const amount: number = sample(possibleAmounts) as number;
		const price: number = smartSamplePrice(securityID);
		const aboveMarketValue: boolean = sample(possibleAboveMarketValues) as boolean;
		const priceAsOf: number = securities[securityID].priceAsOf;
		const total: number = amount * price;
		const listingType: Listing = Listing.BID;
		if (store.users[userID].availableBalance >= total) {
			store.buyOffers[i] = {userID, securityID, amount, price, aboveMarketValue, priceAsOf, listingType};
			store.users[userID].investedBalance += total;
			store.users[userID].availableBalance -= total;

			// bid history
			store.bidHistory[i] = {
				initialAmount: amount,
				active: true,
				lookupID: i,
				date: moment().startOf("day").toISOString(),
				...store.buyOffers[i],
			};
		}

		i--;
	}

	// Create Sell Offers
	i = numberOfSellOffers;
	while (i > 0) {
		const userID: number = random(numberOfUsers - 1);
		const securityID: number = random(numberOfSecurities - 1);
		const amount: number = sample(possibleAmounts) as number;
		const price: number = smartSamplePrice(securityID);
		const aboveMarketValue: boolean = sample(possibleAboveMarketValues) as boolean;
		const priceAsOf: number = securities[securityID].priceAsOf;
		const listingType: Listing = Listing.OFFER;
		store.sellOffers[i] = {userID, securityID, amount, price, aboveMarketValue, priceAsOf, listingType};
		store.users[userID].securities[securityID].amountListed += amount;
		if (aboveMarketValue) {
			store.users[userID].securities[securityID].amountAboveListed += amount;
		} else {
			store.users[userID].securities[securityID].amountBelowListed += amount;
		}

		// offer history
		store.offerHistory[i] = {
			initialAmount: amount,
			active: true,
			lookupID: i,
			date: moment().startOf("day").toISOString(),
			...store.sellOffers[i],
		};

		i--;
	}

	// Simulate Bid Transactions
	let time: Moment = moment().startOf("hour").subtract(minuteIncrement, "minute");
	i = numberOfOffers;

	while (i > 0) {
		if (sample([true, false])) {
			simulateBid();
		} else {
			simulateOffer();
		}
		time.subtract(minuteIncrement, "minute");
		i--;
	}

	calculateTotalSecurities();
	balanceAboveAndBelow();

	function simulateBid() {
		const user1ID: number = random(numberOfUsers - 1);
		const user2ID: number = random(numberOfUsers - 1);

		if (user1ID === user2ID) {
			simulateBid();
			return;
		}

		const securityID: number = random(numberOfSecurities - 1);
		const amount: number = sample(possibleAmounts) as number;
		const price: number = smartSamplePrice(securityID);
		const total: number = Math.floor(amount * price);

		if (store.users[user1ID].availableBalance - total > 0) {

			store.users[user1ID].availableBalance -= total;
			store.users[user2ID].availableBalance += total;

			const aboveMarketValue = sample(possibleAboveMarketValues) as boolean;

			store.history.push({
				date: time.clone().toISOString(),
				listingType: Listing.BID,
				listerID: user1ID,
				acceptorID: user2ID,
				quantity: amount,
				price,
				securityID,
				aboveMarketValue,
			});


			store.bidHistory[store.buyOfferIncrement] = {
				userID: user1ID,
				securityID,
				amount,
				price,
				aboveMarketValue,
				priceAsOf: store.securities[securityID].priceAsOf,
				lookupID: store.buyOfferIncrement,
				initialAmount: amount,
				active: false,
				date: time.clone().toISOString(),
				listingType: Listing.BID,
			};

			store.buyOfferIncrement++;

		} else {
			simulateBid();
		}
	}

	function simulateOffer() {
		const user1ID: number = random(numberOfUsers - 1);
		const user2ID: number = random(numberOfUsers - 1);

		if (user1ID === user2ID) {
			simulateOffer();
			return;
		}

		const securityID: number = i % numberOfSecurities;
		const amount: number = sample(possibleAmounts) as number;
		const price: number = smartSamplePrice(securityID);
		const total: number = Math.floor(amount * price);

		if (store.users[user2ID].availableBalance - total > 0) {

			store.users[user1ID].availableBalance += total;
			store.users[user2ID].availableBalance -= total;

			const aboveMarketValue = sample(possibleAboveMarketValues) as boolean;

			store.history.push({
				date: time.clone().toISOString(),
				listingType: Listing.OFFER,
				listerID: user1ID,
				acceptorID: user2ID,
				quantity: amount,
				price,
				securityID,
				aboveMarketValue,
			});

			store.offerHistory[store.sellOfferIncrement] = {
				userID: user1ID,
				securityID,
				amount,
				price,
				aboveMarketValue,
				priceAsOf: store.securities[securityID].priceAsOf,
				lookupID: store.sellOfferIncrement,
				initialAmount: amount,
				active: false,
				date: time.clone().toISOString(),
				listingType: Listing.OFFER,
			};

			store.sellOfferIncrement++;

		} else {
			simulateOffer();
		}
	}

	// calculate total amount issued for each security
	function calculateTotalSecurities(): void {
		// reset because can be run multiple time
		for (const securityKey in store.securities) {
			store.securities[securityKey].totalIssued = 0;
			store.securities[securityKey].aboveIssued = 0;
			store.securities[securityKey].belowIssued = 0;
		}

		for (const userKey in store.users) {
			for (const securityKey in store.users[userKey].securities) {
				if (!store.users[userKey].securities.hasOwnProperty(securityKey)) {
					continue;
				}
				const owned = store.users[userKey].securities[securityKey].amountOwned;
				const ownedAbove = store.users[userKey].securities[securityKey].amountAboveOwned;
				const ownedBelow = store.users[userKey].securities[securityKey].amountBelowOwned;
				const listed = store.users[userKey].securities[securityKey].amountListed;
				const listedAbove = store.users[userKey].securities[securityKey].amountAboveListed;
				const listedBelow = store.users[userKey].securities[securityKey].amountBelowListed;
				store.securities[securityKey].totalIssued = Math.floor(store.securities[securityKey].totalIssued + owned + listed);
				store.securities[securityKey].aboveIssued = Math.floor(store.securities[securityKey].aboveIssued + ownedAbove + listedAbove);
				store.securities[securityKey].belowIssued = Math.floor(store.securities[securityKey].belowIssued + ownedBelow + listedBelow);
			}
		}
	}

	// because there needs to be an even amount of above and below issued, I will give the difference to the users.
	function balanceAboveAndBelow(): void {
		for (const securityKey in store.securities) {
			const {aboveIssued, belowIssued} = store.securities[securityKey];
			const giveAbove = belowIssued > aboveIssued;
			const difference = Math.abs(belowIssued - aboveIssued);
			const luckyUserID = random(numberOfUsers - 1);
			if (giveAbove) {
				store.users[luckyUserID].securities[securityKey].amountAboveOwned = Math.floor(store.users[luckyUserID].securities[securityKey].amountAboveOwned + difference);
			} else {
				store.users[luckyUserID].securities[securityKey].amountBelowOwned = Math.floor(store.users[luckyUserID].securities[securityKey].amountBelowOwned + difference)
			}
			store.users[luckyUserID].securities[securityKey].amountOwned = Math.floor(store.users[luckyUserID].securities[securityKey].amountOwned + difference)
		}
		calculateTotalSecurities();
	}

	return store;
}

// this function will pick close by values from the last result
let priceMemo: {[key: number]: number} = {}; // stores last index used from price array

function smartSamplePrice(securityID: number): number {
	let index: number;

	if (!priceMemo[securityID]) {
		index = random(0, possiblePrices.length - 1);
	} else {
		index = random(-4, 4) + priceMemo[securityID];

		// lower bound
		if (index < 0) {
			index = 0;
		}

		// upper bound
		if (index > possiblePrices.length - 1) {
			index = possiblePrices.length - 1;
		}
	}

	priceMemo[securityID] = index;
	return possiblePrices[index];
}
