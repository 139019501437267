import * as React from "react";
import {tableOrder} from "./SecuritiesTable";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {ReactNode} from "react";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {buyColumns, IColumns} from "./tableUtils";

interface IEnhancedTableHeadProps {
	numSelected?: number;
	onRequestSort?: (event: any, property: any) => void;
	order?: tableOrder;
	orderBy?: string;
	rowCount?: number;
	columns: IColumns[];
	includeSpaceForButton?: boolean;
}

interface IEnhancedTableHeadState {

}

class EnhancedTableHead extends React.Component<IEnhancedTableHeadProps, IEnhancedTableHeadState> {

	public static defaultProps: IEnhancedTableHeadProps = {
		columns: [],
		includeSpaceForButton: true,
	};

	constructor(props: IEnhancedTableHeadProps) {
		super(props);
		this.state= {};

		this.createSortHandler = this.createSortHandler.bind(this);
		this.createHeaders = this.createHeaders.bind(this);
	}

	private createSortHandler(property: any): (event: any) => void {
		return (event: any) => {
			if (this.props.onRequestSort) {
				this.props.onRequestSort(event, property);
			}
		}
	}

	private createHeaders(columns: IColumns[]): ReactNode {
		return columns.map((column: any, i: number) => {
			return (
				<TableCell
					key={column.id}
					align={column.numeric ? "right" : "left"}
					padding={column.disablePadding ? 'none' : 'default'}
					sortDirection={this.props.orderBy === column.id ? this.props.order : false}
				>
					<Tooltip
						title={"Sort by " + column.label}
						placement={column.numeric ? 'bottom-end' : 'bottom-start'}
						enterDelay={300}
					>
						<TableSortLabel
							active={this.props.orderBy === column.id}
							direction={this.props.order}
							onClick={this.createSortHandler(column.id)}
						>
							{column.label}
						</TableSortLabel>
					</Tooltip>
				</TableCell>
			)
		})
	}


	public render(): JSX.Element {
		return (
			<TableHead>
				<TableRow>
					{this.createHeaders(this.props.columns)}

					{this.props.includeSpaceForButton && (
						<TableCell padding="default">
						</TableCell>
					)}
				</TableRow>
			</TableHead>
		);
	}
}

export default EnhancedTableHead;
