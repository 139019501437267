import moment, {Moment} from "moment";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {Paper, Table, TableBody, TableCell, TablePagination, TableRow, withStyles} from "@material-ui/core";
import {buyColumns, getSorting, IColumns, stableSort, styles} from "./tableUtils";
import {numberWithCommas} from "../../services/utils";
import EnhancedTableHead from "./EnhancedTableHead";
import {tableOrder} from "./CurrentOffers";
import {IStore} from "../../services/redux/initialStore";
import {store} from "../../services/redux";

interface IRow {
	name: string;
	totalIssued: number;
	aboveIssued: number;
	belowIssued: number;
	expirationDate: number;
	priceAtExpiration: number;
}

interface IProps {
	allRows: IRow[];
	classes?: any;
}

interface IState {
	order: tableOrder,
	orderBy: string;
	selected: any[];
	page: number;
	rowsPerPage: number;
	showModal: boolean;
	selectedIndex?: number;
}

class SecuritiesIssuedTable extends React.Component<IProps, IState> {

	public static defaultProps: IProps = {
		allRows: [],
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			page: 0,
			rowsPerPage: Object.keys(store.getState().tradingStore.securities).length, // number of securities in the demo
			showModal: false,
		};

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
	private createRows(rows: IRow[]): any {
		return rows.map((row: IRow, i: number) => {

			return (
				<TableRow
					hover
					tabIndex={-1}
					key={"history-table-row-" + `${i}`}
					style={{
						height: 48,
					}}
				>
					<TableCell align="left">{row.name}</TableCell>
					<TableCell>{moment(row.expirationDate).format("MMM Do YYYY")}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.priceAtExpiration)}</TableCell>

					<TableCell align="right">{numberWithCommas(row.aboveIssued)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.belowIssued)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.totalIssued)}</TableCell>
				</TableRow>
			);
		})
	}

	public render(): JSX.Element {

		const {classes, allRows} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		// @ts-ignore

		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, allRows.length - page * rowsPerPage);
		const rowsToShow: any = stableSort(allRows, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		return (
			<Paper className={classes.root}>
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleRequestSort}
							rowCount={allRows.length}
							columns={[
								{numeric: false, id: "name", label: "Security"},
								{
									id: "expirationDate",
									numeric: true,
									label: "Expiration Date",
								},
								{
									id: "priceAtExpiration",
									numeric: true,
									label: "Price at Expiration",
								},
								{numeric: true, id: "aboveIssued", label: "Above Market Shares Issued"},
								{numeric: true, id: "belowIssued", label: "Below Market Shares Issued"},
								{numeric: true, id: "totalIssued", label: "Total Issued"},
							]}
							includeSpaceForButton={false}
						/>

						<TableBody>
							{rows}

							{emptyRows > 0 && (
								<TableRow style={{height: 48 * emptyRows}}>
									<TableCell colSpan={6}/>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>

				{/*<TablePagination*/}
					{/*rowsPerPageOptions={[20, 40, 60]}*/}
					{/*component="div"*/}
					{/*count={allRows.length}*/}
					{/*rowsPerPage={rowsPerPage}*/}
					{/*page={page}*/}
					{/*backIconButtonProps={{*/}
						{/*'aria-label': 'Previous Page',*/}
					{/*}}*/}
					{/*nextIconButtonProps={{*/}
						{/*'aria-label': 'Next Page',*/}
					{/*}}*/}
					{/*onChangePage={(this.changePage as any)}*/}
					{/*onChangeRowsPerPage={(this.changeRowsPerPage as any)}*/}
				{/*/>*/}
			</Paper>
		);
	}
}

function mapStateToProps(store: IStore, props: IProps): IProps {

	function mapHistoryToRow({name, totalIssued, aboveIssued, belowIssued, expirationDate, priceAtExpiration}): IRow {

		return {
			name,
			totalIssued,
			aboveIssued,
			belowIssued,
			expirationDate,
			priceAtExpiration,
		}
	}

	const securities: any = Object.keys(store.tradingStore.securities).map(k => store.tradingStore.securities[k]);

	return {
		// @ts-ignore
		allRows: securities.map(mapHistoryToRow),
		...props
	}
}

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps)(SecuritiesIssuedTable));
