import {Divider, Paper, Typography, withStyles} from "@material-ui/core";
import React, {ReactNode, useState} from "react";
import {IStore} from "../services/redux/initialStore";
import {connect} from "react-redux";
import {ISecuritiesType, ISecurity} from "../services/redux/trading/tradingStore";
import PayoutProjectionDataRow from "../components/PayoutProjectionDataRow";
import {numberWithCommas} from "../services/utils";

export interface IPayoutProjectionProps {
	classes?: any;
	data?: any[];
}

const PayoutProjection: React.FunctionComponent<IPayoutProjectionProps> = (props: IPayoutProjectionProps): JSX.Element => {

	const {classes, data} = props;
	const [total, updateTotal] = useState(0);

	function addTo(more: number): void {
		updateTotal(total + more);
	}

	function removeFrom(less: number): void {
		if ((total - less) < 0) {
			updateTotal(0);
		} else {
			updateTotal(total - less);
		}
	}

	function makeRows(rows: any[]): ReactNode {
		return rows.map((row: any, i: number) => {
			return (
				<React.Fragment>
					<PayoutProjectionDataRow
						name={row.name}
						expiry={row.expirationDate}
						amountAboveOwned={row.amountAboveOwned}
						amountBelowOwned={row.amountBelowOwned}
						add={addTo}
						remove={removeFrom}
						initialValue={row.priceAsOf}
					/>
					<Divider style={{color: "gray"}}/>
				</React.Fragment>
			);
		})
	}

	// @ts-ignore
	const rows: ReactNode = makeRows(data);

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography variant="h4">
					Payout Projection
				</Typography>
				<Typography style={{marginBottom: 10}}>
					This page should show a list of all the securities you own with the name, shares you own at below market value, shares you own at above market value, the expiry date, the money that should be made at payout for above and below.
					<br/>
				</Typography>
				<Divider/>

				<div style={{marginTop: 30}}>
					{rows}
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						marginTop: 40,
					}}
				>
					<div>
						<Typography variant="h4" style={{color: "gray"}}>
							Total
						</Typography>
					</div>

					<div>
						<Typography variant="h4">
							{"$" + numberWithCommas(total.toFixed(2))}
						</Typography>
					</div>
				</div>
			</div>
		</Paper>
	);

};

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		minWidth: 900,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
});

export default withStyles(styles)(connect((store: IStore, props: IPayoutProjectionProps): IPayoutProjectionProps => {

	const userID: number = store.tradingStore.currentUser;
	const userSecurities: ISecuritiesType = store.tradingStore.users[userID].securities;

	const ownedSecurities: any[] = [];

	for (const security in userSecurities) {
		if (userSecurities[security].amountOwned > 0) {
			ownedSecurities.push({
				...store.tradingStore.securities[security],
				...userSecurities[security],
			});
		}
	}


	return {
		data: ownedSecurities,
	}
})(PayoutProjection));
