import * as React from "react";
import {ReactNode} from "react";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "../ResearchCenter.css";

interface INewsProps {

}

const testObj: any = {
	src: "http://skatecanada.ns.ca/wp-content/uploads/2019/02/PJ_2016.07.07_Modern-News-Consumer_0-01.png",
	title: "New Discovery",
	date: "Wednesday 9:00am",
	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
};

const testData: any[] = [
	{
		src: process.env.PUBLIC_URL + "/news1.jpg",
		title: "31 ways home buyers, sellers and owners can save money",
		date: "Source: Real Estate Board of Greater Vancouver",
		body: "Are you looking to save money? We can help! Here's 31 ways BC home buyers, sellers, and owners can save money.",
		link: "https://www.rebgv.org/news-archive/31-ways-home-buyers--sellers-and-owners-can-save-money.html",
	},
	{
		src: process.env.PUBLIC_URL + "/news2.png",
		title: "Curb appeal - 6 ways to improve your home's resale value",
		date: "Source: Real Estate Board of Greater Vancouver",
		body: "Before a buyer even steps through your front door, you’ve created a first impression. Keeping the outside of your home clean and tidy improves your home’s curb appeal and will make a difference when you’re looking to sell. Here’s six ways you can make your home look great and improve its resale value without spending all your money.",
		link: "https://www.rebgv.org/news-archive/curb-appeal---6-ways-to-improve-your-home-s-resale-value.html",
	},
	{
		src: process.env.PUBLIC_URL + "/news3.jpg",
		title: "We need to rethink rental in the 21st Century",
		date: "Source: Canada Mortgage and Housing Corporation",
		body: "Ten key themes emerged from our first-ever National Housing Conference. Get the full list here.",
		link: "https://www.cmhc-schl.gc.ca/en/housing-observer-online/2019-housing-observer/key-themes-from-2018-national-housing-conference",
	},
	{
		src: process.env.PUBLIC_URL + "/news4.jpg",
		title: "In-depth economic report on the mortgage market in Canada",
		date: "Source: Canada Mortgage and Housing Corporation",
		body: "New report fills in some data gaps by providing a broad view of the residential mortgage industry.",
		link: "https://www.cmhc-schl.gc.ca/en/housing-observer-online/2019-housing-observer/new-publication-residential-mortgage-industry-report",
	},
	{
		src: process.env.PUBLIC_URL + "/news6.jpg",
		title: "Could price growth expectations be driving up home prices?",
		date: "Source: Canada Mortgage and Housing Corporation",
		body: "Fundamentals alone can’t explain overheating. Learn about the influence of subjective factors.",
		link: "https://www.cmhc-schl.gc.ca/en/housing-observer-online/2019-housing-observer/homebuyer-motivations-price-growth-expectations",
	},
	{
		src: process.env.PUBLIC_URL + "/news5.jpg",
		title: "Japan exports dip as China-US trade war takes toll",
		date: "Source: Yahoo Finance",
		body: "TOKYO — Japan's exports declined in June as shipments of goods were hit by trade disputes between China and the U.S., the Finance Ministry reported Thursday. The data released by the Finance Ministry on Thursday show exports fell 6.8% in June from a year earlier, the seventh straight month of decline, while imports fell 5.2%.",
		link: "https://ca.finance.yahoo.com/news/japans-exports-fall-june-hit-025212280.html",
	},
];

testData.forEach(function (item) {
	new Image().src = item.src
});


const News: React.FunctionComponent<INewsProps> = (props: INewsProps): JSX.Element => {

	function createNewsItems(items: any[]): ReactNode {
		return items.map((item: any, i: number) => {
			return (
					<Grid
						item={true}
						xs={12}
						sm={12}
						md={6}
						lg={4}
						xl={3}
					>
				<a href={item.link} target="_blank">
						<Paper
							style={{
								height: "100%",
								overflow: "hidden",
							}}
							className="news-item"
						>
							<div
								style={{
									height: 200,
									overflow: "hidden"
								}}
							>
								<img
									src={item.src}
									alt="News"
									style={{
										width: "100%",
										height: "100%",
										objectFit: "cover",
									}}
									className="news-image"
								/>
							</div>
							<div style={{padding: 15}}>
								<Typography
									style={{
										fontSize: 22,
										fontWeight: "bold",
									}}
								>
									{item.title}
								</Typography>

								<Typography
									style={{
										color: "gray",
									}}
								>
									{item.date}
								</Typography>

								<Typography style={{marginTop: 10}}>
									{item.body.length > 240 ? item.body.substr(0, 230) + "..." : item.body}
								</Typography>
							</div>
						</Paper>
				</a>
					</Grid>
			);
		})
	}

	const newsArr: any[] = [];

	let i: number;
	for (i = 0; i < 18; i++) {
		newsArr.push({...testObj});
	}

	return (
		<Grid
			container={true}
			spacing={40}
		>
			<Grid
				container
				item
				xs={12}
			>
				<Paper style={{width: "100%"}} className="news-item">
					<a href="https://www.rebgv.org/news-archive/fewer-commercial-real-estate-sales-to-start-2019.html" target="_blank">
						<Grid container xs={12}>
							<Grid item xs={12} sm={12} md={12} lg={6} style={{overflow: "hidden"}}>
								<img
									style={{
										width: "100%",
										height: "100%",
									}}
									src={process.env.PUBLIC_URL + "/news0.jpg"}
									className="news-image"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={6}
								style={{
									padding: 20,
								}}
							>
								<Typography
									style={{
										fontSize: 26,
										fontWeight: "bold",
									}}
								>
									{"Fewer commercial real estate sales to start 2019"}
								</Typography>

								<Typography
									style={{
										color: "gray",
									}}
								>
									{"Source: Real Estate Board of Greater Vancouver"}
								</Typography>

								<Typography style={{marginTop: 10}}>
									{"Commercial real estate sales in the Lower Mainland declined in the first quarter (Q1) of 2019 compared to the active market experienced across the region last year. There were 318 commercial real estate sales in the Lower Mainland in Q1 2019, a 42.1 per cent decrease over the 549 sales in Q1 2018, according to data from Commercial Edge, a commercial real estate system operated by the Real Estate Board of Greater Vancouver (REBGV)."}
								</Typography>
							</Grid>
						</Grid>
					</a>
				</Paper>
			</Grid>


			{createNewsItems(testData)}
		</Grid>
	);
};

export default News;
