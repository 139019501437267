import React from 'react';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from '../components/Navigator';
import Header from '../components/Header';
import {Route} from "react-router";
import AdminPortal from "./AdminPortal";
import BuyOffers from "./BuyOffers";
import Summary from "./Summary";
import SellOffers from "./SellOffers";
import DemoIntro from "./DemoIntro";
import News from "./research_center/News";
import RealEstateMarket from "./research_center/RealEstateMarket";
import Instructions from "./Instructions";
import InternalMarket from "./InternalMarket";
import EntireTradingHistory from "./EntireTradingHistory";
import UsersHistoryPage from "./UsersHistoryPage";
import Profile from "./Profile";
import FinancialSummary from "./FinancialSummary";
import UsefulLinks from "./UsefulLinks";
import ListOfSecurities from "./ListOfSecurities";
import YourBidsPage from "./YourBidsPage";
import YourOffersPage from "./YourOffersPage";
import {IconButton, Snackbar} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import DepositFunds from "./DepositFunds";
import WithdrawFunds from "./WithdrawFunds";
import PayoutProjection from "./PayoutProjection";
import YourOrdersPage from "./YourOrdersPage";
import Glossary from "./Glossary";

export interface IRootProps {
	snackBarMessage?: string;
	classes: any;
}

export interface IRootState {
	mobileOpen: boolean;
	showSnackBar: boolean;
}

class Root extends React.Component<IRootProps, IRootState> {

	public static defaultProps: IRootProps = {
		classes: "",
	};

	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false,
			showSnackBar: false,
		};
		this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
	}

	componentWillReceiveProps(nextProps: Readonly<IRootProps>, nextContext: any): void {
		if (nextProps.snackBarMessage !== this.props.snackBarMessage && nextProps.snackBarMessage !== undefined) {
			this.setState({showSnackBar: true});
		}
	}

	handleSnackBarClose = () => {
		this.setState({
			showSnackBar: false,
		})
	};

	handleDrawerToggle = () => {
		this.setState(state => ({mobileOpen: !state.mobileOpen}));
	};

	render() {
		const {classes} = this.props;

		return (
			<MuiThemeProvider theme={theme}>
				<div className={classes.root}>
					<CssBaseline/>
					<nav className={classes.drawer}>
						<Hidden smUp implementation="js">
							<Navigator
								PaperProps={{style: {width: drawerWidth}}}
								variant="temporary"
								open={this.state.mobileOpen}
								onClose={this.handleDrawerToggle}
							/>
						</Hidden>
						<Hidden xsDown implementation="css">
							<Navigator PaperProps={{style: {width: drawerWidth}}}/>
						</Hidden>
					</nav>
					<div className={classes.appContent}>
						<Header onDrawerToggle={this.handleDrawerToggle}/>
						<main className={classes.mainContent}>
							<Route path="/demo-intro" exact component={DemoIntro}/>
							<Route path="/instructions" exact component={Instructions}/>
							<Route path="/admin" exact component={AdminPortal}/>
							{/*<Route path="/glossary" exact component={Glossary}/>*/}

							<Route path="/my-portfolio/holdings" exact component={Summary}/>
							<Route path="/my-portfolio/financial-summary" exact component={FinancialSummary}/>
							<Route path="/my-portfolio/payout-projection" exact component={PayoutProjection}/>
							<Route path="/my-portfolio/deposit-funds" exact component={DepositFunds}/>
							<Route path="/my-portfolio/withdraw-funds" exact component={WithdrawFunds}/>
							<Route path="/my-portfolio/profile" exact component={Profile}/>

							<Route path="/trading/buy-orders" exact component={BuyOffers}/>
							<Route path="/trading/sell-orders" exact component={SellOffers}/>
							{/*<Route path="/trading/portfolio" exact component={Summary}/>*/}
							<Route path="/trading/my-trading-history" exact component={UsersHistoryPage}/>
							<Route path="/trading/my-orders" exact component={YourOrdersPage}/>
							{/*<Route path="/trading/my-bids" exact component={YourBidsPage}/>*/}
							{/*<Route path="/trading/my-offers" exact component={YourOffersPage}/>*/}

							<Route path="/internal-market/statistics" exact component={InternalMarket}/>
							<Route path="/internal-market/trading-history" exact component={EntireTradingHistory}/>
							<Route path="/internal-market/securities" exact component={ListOfSecurities}/>

							<Route path="/information-center/news" exact component={News}/>
							{/*<Route path="/information-center/macroeconomic-data" exact component={MacroEconomic}/>*/}
							{/*<Route path="/information-center/cmhc" exact component={BankOfCanada}/>*/}
							<Route path="/information-center/real-estate-market" exact component={RealEstateMarket}/>
							<Route path="/information-center/useful-links" exact component={UsefulLinks}/>
							{/*<Route path="/information-center/yahoo-finance" exact component={YahooFinance}/>*/}
							{/*<Route path="/information-center/cnbc" exact component={TestResearchCenter}/>*/}
						</main>
					</div>
				</div>

				<Snackbar
					anchorOrigin={{vertical: "top", horizontal: "right"}}
					open={this.state.showSnackBar}
					onClose={this.handleSnackBarClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={
						<span id="message-id">
							{this.props.snackBarMessage}
						</span>
					}

				/>
			</MuiThemeProvider>
		);
	}
}
// action={[
// 		<IconButton
// 			key="close"
// 			aria-label="Close"
// 			color="inherit"
// 			className={classes.close}
// 			onClick={this.handleSnackBarClose}
// 		>
// 			<Close/>
// 		</IconButton>,
// ]}


let theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		h5: {
			fontWeight: 500,
			fontSize: 26,
			letterSpacing: 0.5,
		},
	},
	palette: {
		primary: {
			light: '#63ccff',
			main: '#009be5',
			dark: '#006db3',
		},
	},
	shape: {
		borderRadius: 8,
	},
});

theme = {
	...theme,
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: '#18202c',
			},
		},
		MuiButton: {
			label: {
				textTransform: 'initial',
			},
			contained: {
				boxShadow: 'none',
				'&:active': {
					boxShadow: 'none',
				},
			},
		},
		MuiTabs: {
			root: {
				marginLeft: theme.spacing.unit,
			},
			indicator: {
				height: 3,
				borderTopLeftRadius: 3,
				borderTopRightRadius: 3,
				backgroundColor: theme.palette.common.white,
			},
		},
		MuiTab: {
			root: {
				textTransform: 'initial',
				margin: '0 16px',
				minWidth: 0,
				[theme.breakpoints.up('md')]: {
					minWidth: 0,
				},
			},
			labelContainer: {
				padding: 0,
				[theme.breakpoints.up('md')]: {
					padding: 0,
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: theme.spacing.unit,
			},
		},
		MuiTooltip: {
			tooltip: {
				borderRadius: 4,
				fontSize: 14,
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#404854',
			},
		},
		MuiListItemText: {
			primary: {
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'inherit',
				marginRight: 0,
				'& svg': {
					fontSize: 20,
				},
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		},
	},
	props: {
		MuiTab: {
			disableRipple: true,
		},
	},
	mixins: {
		...theme.mixins,
		toolbar: {
			minHeight: 48,
		},
	},
};

const drawerWidth = 256;

const styles = {
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	mainContent: {
		flex: 1,
		padding: '48px 36px 0',
		background: '#eaeff1',
	},
};

// class Paperbase extends React.Component<any, any> {
// 	state = {
// 		mobileOpen: false,
// 	};
//
// 	handleDrawerToggle = () => {
// 		this.setState(state => ({mobileOpen: !state.mobileOpen}));
// 	};
//
// 	render() {
// 		const {classes} = this.props;
////
// 		return (
// 			<MuiThemeProvider theme={theme}>
// 				<div className={classes.root}>
// 					<CssBaseline/>
// 					<nav className={classes.drawer}>
// 						<Hidden smUp implementation="js">
// 							<Navigator
// 								PaperProps={{style: {width: drawerWidth}}}
// 								variant="temporary"
// 								open={this.state.mobileOpen}
// 								onClose={this.handleDrawerToggle}
// 							/>
// 						</Hidden>
// 						<Hidden xsDown implementation="css">
// 							<Navigator PaperProps={{style: {width: drawerWidth}}}/>
// 						</Hidden>
// 					</nav>
// 					<div className={classes.appContent}>
// 						<Header onDrawerToggle={this.handleDrawerToggle}/>
// 						<main className={classes.mainContent}>
//
// 							<SecuritiesTable
// 								variety={securitiesTradingTableType.BUY}
// 								data={fakeTestData}
// 							/>
// 						</main>
// 					</div>
// 				</div>
// 			</MuiThemeProvider>
// 		);
// 	}
// }

// @ts-ignore
export default withStyles(styles as any)(connect((store: IStore, props: IRootProps): IRootProps => {
	return {
		...props,
		snackBarMessage: store.tradingStore.toastMessage
	}
})(Root));
