import {store} from "./redux";
import {Listing} from "./redux/trading/tradingStore";

export interface IFinancialSummaryEntry {
	label?: string;
	numbers?:  number[];
	total?: number;
	description?: string;
}

export interface IFinancialSummary {
	deposits: IFinancialSummaryEntry,
	withdrawals: IFinancialSummaryEntry,
	bidsSoldTo: IFinancialSummaryEntry,
	offersAccepted: IFinancialSummaryEntry,
	bidsAccepted: IFinancialSummaryEntry,
	offersPurchased: IFinancialSummaryEntry,
	invested: IFinancialSummaryEntry,
	availableBalance: IFinancialSummaryEntry,
	profit: IFinancialSummaryEntry,
}

function round(number: number): number {
	return Math.floor(number * 100) / 100;
}

export function getFinancialSummary(): IFinancialSummary {
	const {history, currentUser, users} = store.getState().tradingStore;
	const {investedBalance, deposits, withdrawals, availableBalance} = users[currentUser];
	let totalDeposit: number = 0;
	deposits.forEach(n => totalDeposit = Math.round(totalDeposit + n));
	let totalWithdrawals: number = 0;
	withdrawals.forEach(n => totalWithdrawals = Math.round(totalWithdrawals - n));

	const fs: IFinancialSummary = {
		deposits: {
			label: "Deposit(s)",
			numbers: deposits,
			total: totalDeposit,
			description: "The money that has been deposited from external sources."
		},
		withdrawals: {
			label: "Withdrawal(s)",
			numbers: withdrawals.map(n => -Math.abs(n)),
			total: totalWithdrawals,
			description: "The money that has been withdrawn from the exchange."
		},
		bidsSoldTo: {
			label: "Buy order(s) sold to",
			numbers: [],
			total: 0,
			description: "The money that has been gained by selling securities to buy orders."
		},
		offersAccepted: {
			label: "Sell order(s) accepted",
			numbers: [],
			total: 0,
			description: "The money that has been gained by listing sell orders and selling securities."
		},
		bidsAccepted: {
			label: "Bid(s) accepted",
			numbers: [],
			total: 0,
			description: "The money spent on listing buy orders and buying securities."
		},
		offersPurchased: {
			label: "Sell order(s) purchased",
			numbers: [],
			total: 0,
			description: "The money spent on buying securities from sell orders."
		},
		invested: {
			label: "Invested in sell orders",
			total: round(investedBalance),
			description: "The money that is sitting in your current sell orders. This money is held up because it needs to be available when/if the sell order is accepted by another user. This is the same as the number in the side bar."
		},
		availableBalance: {
			label: "Available balance",
			total: round(availableBalance),
			description: "The money that is free to be used in trading or cashed out."
		},
		profit: {
			label: "Profit",
			total: round(totalDeposit - (investedBalance + availableBalance + totalWithdrawals)),
			description: "The sum of 'available balance', 'invested balance', and 'withdrawals' subtracted from the `deposits`. This indicates all of the money made in comparison to the money put into the trading system."
		}
	};

	history.forEach(({listingType, listerID, securityID, acceptorID, price, quantity}) => {
		if (securityID !== listerID && securityID !== acceptorID) {
			return;
		}

		let key: keyof IFinancialSummary;
		let total: number = round(price * quantity);

		// Offers
		if (listingType === Listing.OFFER) {

			//offersAccepted
			if (listerID === currentUser) {
				key = "offersAccepted";
			}

			//offersPurchased
			else {
				key = "offersPurchased";
				total = total * -1;
			}
		}

		// Bids
		else {
			//bidsAccepted
			if (listerID === currentUser) {
				key = "bidsAccepted";
				total = total * -1;
			}

			//bidsSoldTo
			else {
				key = "bidsSoldTo";
			}
		}

		// @ts-ignore
		fs[key].numbers.push(total);
		// @ts-ignore
		fs[key].total = round(fs[key].total + total);
	});

	return fs;
}
