import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Route, withRouter, Link} from "react-router-dom";
import {categories} from "./Navigator";

function Header(props) {
	const {classes, onDrawerToggle, match} = props;

	let headerName: string = match.path;
	for (let category of categories) {
		for (let child of category.children) {
			if (child.to === match.url) {
				headerName = (child as any)._id;
			}
		}
	}
	if (match.url.includes("trading") && (!(match.url === "trading-history"))) {
		headerName = "Trading";
	} else if (match.url.includes("information-center")) {
		headerName = "Information Center";
	} else if (match.url.includes("internal-market")) {
		headerName = "Internal Market";
	} else if (match.url.includes("my-portfolio")) {
		headerName = "My Portfolio";
	}

	return (
		<React.Fragment>
			<AppBar color="primary" position="sticky" elevation={0}>
				<Toolbar>
					<Grid container spacing={8} alignItems="center">
						<Hidden smUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={onDrawerToggle}
									className={classes.menuButton}
								>
									<MenuIcon/>
								</IconButton>
							</Grid>
						</Hidden>
					</Grid>
				</Toolbar>
			</AppBar>
			<AppBar
				component="div"
				className={classes.secondaryBar}
				color="primary"
				position="static"
				elevation={0}
			>
				<Toolbar>
					<Grid container alignItems="center" spacing={8}>
						<Grid item xs>
							<Typography color="inherit" variant="h5">
								{headerName}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Route path="/trading/*" render={(props) => {

				let value: number;
				switch (props.match.url) {
					// case("/trading/portfolio"):
					// 	value = 0;
					// 	break;
					case("/trading/buy-orders"):
						value = 0;
						break;
					case("/trading/sell-orders"):
						value = 1;
						break;
					case("/trading/my-trading-history"):
						value = 2;
						break;
					case("/trading/my-orders"):
						value = 3;
						break;
					// case("/trading/my-bids"):
					// 	value = 4;
					// 	break;
					// case("/trading/my-offers"):
					// 	value = 5;
					// 	break;
					default:
						value = 0;
				}

				return (
					<AppBar
						component="div"
						className={classes.secondaryBar}
						color="primary"
						position="static"
						elevation={0}
					>
						<Tabs value={value}>
							{/*<Link to="/trading/portfolio">*/}
								{/*<Tab label="Portfolio"/>*/}
							{/*</Link>*/}
							<Link to="/trading/buy-orders">
								<Tab label="Buy Orders"/>
							</Link>
							<Link to="/trading/sell-orders">
								<Tab label="Sell Orders"/>
							</Link>
							<Link to="/trading/my-trading-history">
								<Tab label="My Trading History"/>
							</Link>
							<Link to="/trading/my-orders">
								<Tab label="My Orders"/>
							</Link>
							{/*<Link to="/trading/my-bids">*/}
								{/*<Tab label="My Bids"/>*/}
							{/*</Link>*/}
							{/*<Link to="/trading/my-offers">*/}
								{/*<Tab label="My Offers"/>*/}
							{/*</Link>*/}
						</Tabs>
					</AppBar>
				);
			}}/>

			<Route path="/information-center/*" render={(props) => {

				let value: number;
				// switch (props.match.url) {
				// 	case("/information-center/news"):
				// 		value = 0;
				// 		break;
				// 	case("/information-center/macroeconomic-data"):
				// 		value = 1;
				// 		break;
				// 	case("/information-center/cmhc"):
				// 		value = 2;
				// 		break;
				// 	case("/information-center/real-estate-market"):
				// 		value = 3;
				// 		break;
				// 	case ("/information-center/yahoo-finance"):
				// 		value = 4;
				// 		break;
				// 	case ("/information-center/cnbc"):
				// 		value = 4;
				// 		break;
				// 	default:
				// 		value = 0;
				// }

				switch (props.match.url) {
					case("/information-center/news"):
						value = 0;
						break;
					case("/information-center/real-estate-market"):
						value = 1;
						break;
					case ("/information-center/useful-links"):
						value = 2;
						break;
					default:
						value = 0;
				}

				return (
					<AppBar
						component="div"
						className={classes.secondaryBar}
						color="primary"
						position="static"
						elevation={0}
					>
						<Tabs value={value}>
							<Link to="/information-center/news">
								<Tab label="News Feed"/>
							</Link>
							{/*<Link to="/information-center/macroeconomic-data">*/}
							{/*<Tab label="Macroeconomic Data"/>*/}
							{/*</Link>*/}
							{/*<Link to="/information-center/cmhc">*/}
							{/*<Tab label="Bank of Canada / CMHC"/>*/}
							{/*</Link>*/}
							<Link to="/information-center/real-estate-market">
								<Tab label="Real Estate Markets"/>
							</Link>

							<Link to="/information-center/useful-links">
								<Tab label="Useful Links"/>
							</Link>

							{/*<Link to="/information-center/cnbc">*/}
							{/*<Tab label="CNBC"/>*/}
							{/*</Link>*/}
							{/*<Link to="/information-center/yahoo-finance">*/}
							{/*<a href="https://ca.finance.yahoo.com/" target="_blank">*/}
							{/*<Tab label="Yahoo Finance"/>*/}
							{/*</a>*/}
							{/*</Link>*/}

						</Tabs>
					</AppBar>
				);
			}}/>

			<Route path="/internal-market/*" render={(props) => {

				let value: number;
				switch (props.match.url) {
					case("/internal-market/statistics"):
						value = 0;
						break;
					case("/internal-market/trading-history"):
						value = 1;
						break;
					case("/internal-market/securities"):
						value = 2;
						break;
					default:
						value = 0;
						break;
				}

				return (
					<AppBar
						component="div"
						className={classes.secondaryBar}
						color="primary"
						position="static"
						elevation={0}
					>
						<Tabs value={value}>
							<Link to="/internal-market/statistics">
								<Tab label="StatisticsPage"/>
							</Link>
							<Link to="/internal-market/trading-history">
								<Tab label="Trading History"/>
							</Link>
							<Link to="/internal-market/securities">
								<Tab label="Securities"/>
							</Link>
						</Tabs>
					</AppBar>
				);
			}}/>

			<Route path="/my-portfolio/*" render={(props) => {

				let value: number;
				switch (props.match.url) {
					case("/my-portfolio/holdings"):
						value = 0;
						break;
					case("/my-portfolio/financial-summary"):
						value = 1;
						break;
					case("/my-portfolio/payout-projection"):
						value = 2;
						break;
					case("/my-portfolio/deposit-funds"):
						value = 3;
						break;
					case("/my-portfolio/withdraw-funds"):
						value = 4;
						break;
					case("/my-portfolio/profile"):
						value = 5;
						break;
					default:
						value = 0;
						break;
				}

				return (
					<AppBar
						component="div"
						className={classes.secondaryBar}
						color="primary"
						position="static"
						elevation={0}
					>
						<Tabs value={value}>
							<Link to="/my-portfolio/holdings">
								<Tab label="Holdings"/>
							</Link>
							<Link to="/my-portfolio/financial-summary">
								<Tab label="Financial Summary"/>
							</Link>
							<Link to="/my-portfolio/payout-projection">
								<Tab label="Payout Projection"/>
							</Link>
							<Link to="/my-portfolio/deposit-funds">
								<Tab label="Deposit Funds"/>
							</Link>
							<Link to="/my-portfolio/withdraw-funds">
								<Tab label="Withdraw Funds"/>
							</Link>
							<Link to="/my-portfolio/profile">
								<Tab label="Profile"/>
							</Link>
						</Tabs>
					</AppBar>
				);
			}}/>
		</React.Fragment>
	);
}

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
	secondaryBar: {
		zIndex: 0,
	},
	menuButton: {
		marginLeft: -theme.spacing.unit,
	},
	iconButtonAvatar: {
		padding: 4,
	},
	link: {
		textDecoration: 'none',
		color: lightColor,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
	button: {
		borderColor: lightColor,
	},
});

export default withStyles(styles)(withRouter(Header));
