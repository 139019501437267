import React from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import SecuritiesTable, {securitiesTradingTableType} from '../components/TableView/SecuritiesTable';
import {IOfferFilled} from "../services/redux/trading/tradingStore";
import PostBuyOfferModal from "../components/modals/PostBuyOfferModal";
import {Button} from "@material-ui/core";

interface IBuyOffersProps {
	classes: any,
	data: IOfferFilled[],
	dispatch: any,
}

interface IBuyOffersState {
	showPostBuyOfferModal: boolean;
}

class BuyOffers extends React.Component<IBuyOffersProps, IBuyOffersState> {

	public constructor(props: IBuyOffersProps) {
		super(props);
		this.state = {
			showPostBuyOfferModal: false,
		};

		this.toggleShowPostModal = this.toggleShowPostModal.bind(this);
	}

	private toggleShowPostModal(): void {
		this.setState({
			showPostBuyOfferModal: !this.state.showPostBuyOfferModal,
		});
	}

	public render(): JSX.Element {

		const injectPropsForSafari: any = {
			onTouchTap: this.toggleShowPostModal,
		};

		return (
			<React.Fragment>
				<PostBuyOfferModal
					open={this.state.showPostBuyOfferModal}
					onClose={this.toggleShowPostModal}
				/>

				<Paper className={this.props.classes.paper}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginTop: 20,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={this.toggleShowPostModal}
							{...injectPropsForSafari}
						>
							Post a Buy Order
						</Button>
					</div>

					<div className={this.props.classes.contentWrapper}>
						<SecuritiesTable
							variety={securitiesTradingTableType.BUY}
							data={this.props.data}
						/>
					</div>
				</Paper>
			</React.Fragment>
		);
	}
}

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps) => {
	const data: IOfferFilled[] = Object.keys(store.tradingStore.buyOffers)
		.filter((key: string) => {
			return store.tradingStore.currentUser !== store.tradingStore.buyOffers[key].userID;
		})
		.map((key: string): any => {
			return {
				...store.tradingStore.buyOffers[key],
				user: store.tradingStore.users[store.tradingStore.buyOffers[key].userID],
				security: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID],
				id: parseInt(key),
				expirationDate: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID].expirationDate,
				priceAtExpiration: store.tradingStore.securities[store.tradingStore.buyOffers[key].securityID].priceAtExpiration,
			}
		});

	return {
		data,
		...props
	}
})(BuyOffers));
