import React, {ReactNode, useState} from "react";
import {Button, Divider, Grid, InputAdornment, Paper, TextField, Typography, withStyles} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {numberWithCommas} from "../services/utils";
import {IStore} from "../services/redux/initialStore";
import {connect} from "react-redux";
import {deposit} from "../services/redux/trading/tradingActions";
import {tradingType} from "../services/redux/trading/TradingReducer";

export interface IDepositFundsProps {
	classes?: any;
	currentBalance?: number;
	dispatch?: any;
	errorMessage?: string;
	history?: any;
}

const DepositFunds: React.FunctionComponent<IDepositFundsProps> = (props: IDepositFundsProps): JSX.Element => {

	const {classes} = props;
	const [credNumber, updateCredNumber] = useState("");
	const [cvv, updateCvv] = useState("");
	const [month, updateMonth] = useState();
	const [year, updateYear] = useState(2019);
	const [realValueToDeposit, updateRealValueToDeposit] = useState();
	const [displayDepositValue, updateDisplayDepositValue] = useState();

	function handleCreditCardInputChange(values: any): void {
		updateCredNumber(values.value);
	}

	function handleCVVInputChange(values: any): void {
		updateCvv(values.value);
	}

	function handleMonthInputChange(values: any): void {
		if (values.formattedValue < 13) {
			updateMonth(values.value);
		} else {
			updateMonth(12);
		}
	}

	function handleYearInputChange(values: any): void {
		if (values.formattedValue > 2028) {
			updateYear(2028);
		} else {
			updateYear(values.formattedValue);
		}
	}

	function handleYearBlur(): void {
		if (year < 2019) {
			updateYear(2019);
		} else if (year > 2028) {
			updateYear(2028);
		}
	}

	function handleDepositInputChange(values: any): void {
		if (values.floatValue > 1000000000) {
			updateDisplayDepositValue(1000000000);
			updateRealValueToDeposit(1000000000);
		} else {
			updateDisplayDepositValue(values.formattedValue);
			updateRealValueToDeposit(values.floatValue);
		}

	}

	const creditCardInputProps: any = {
		label: "Credit Card Number",
		style: {
			marginRight: 20,
			marginTop: 20,
		}
	};

	const cvvInputProps: any = {
		label: "CVV",
		style: {
			marginTop: 20,
		}
	};

	const expiryMonthProps: any = {
		label: "Expiry Month",
		style: {
			marginRight: 20,
			marginTop: 20,
		}
	};

	const expiryYearProps: any = {
		label: "Expiry Year",
		style: {
			marginTop: 20,
		}
	};

	const depositProps: any = {
		label: "Amount",
		style: {
			marginTop: 20,
		}
	};

	/**
	 *  ATTEMPT DEPOSIT
	 *
	 */
	async function attemptDeposit(): Promise<void> {
		const action = await deposit(realValueToDeposit, credNumber, cvv, parseFloat(month.toString()), parseFloat(year.toString()));
		await props.dispatch(action);

		if (action.type === tradingType.EDIT_STORE) {
			// @ts-ignore
			props.history.push("/my-portfolio/financial-summary");
		} else {
		}
	}

	// @ts-ignore
	const currentBalance: string = numberWithCommas(props.currentBalance.toFixed(2));
	const thisDeposit: string = realValueToDeposit ? "$" + numberWithCommas(realValueToDeposit.toFixed(2)) : "";
	// @ts-ignore
	const finalBalance: string = realValueToDeposit ? "$" + numberWithCommas((props.currentBalance + realValueToDeposit).toFixed(2)) : "";

	const injectPropsForSafari: any = {
		onTouchTap: attemptDeposit,
	};

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold"}}>
					Deposit Funds
				</Typography>
				<Typography>
					Enter your credit card details and a specified amount to deposit money into your 'Available Balance'.
				</Typography>
				<Divider light style={{height: 2, marginTop: 25, marginBottom: 25}}/>


				<Grid container spacing={24}>
					<Grid container item xs={12} sm={12} md={6}>
						<Grid item xs={12}>
							<NumberFormat
								onValueChange={handleCreditCardInputChange}
								format="####-####-####-####"
								customInput={TextField}
								{...creditCardInputProps}
							/>
							<NumberFormat
								onValueChange={handleCVVInputChange}
								format="####"
								customInput={TextField}
								{...cvvInputProps}
							/>
						</Grid>

						<Grid item xs={12}>
							<NumberFormat
								value={month}
								onValueChange={handleMonthInputChange}
								format="##"
								customInput={TextField}
								{...expiryMonthProps}
							/>
							<NumberFormat
								value={year}
								onValueChange={handleYearInputChange}
								onBlur={handleYearBlur}
								format="####"
								customInput={TextField}
								{...expiryYearProps}
							/>
						</Grid>

						<Grid item xs={12}>
							<NumberFormat
								value={displayDepositValue}
								onValueChange={handleDepositInputChange}
								thousandSeparator={true}
								allowNegative={false}
								prefix={"$"}
								decimalScale={2}
								customInput={TextField}
								{...depositProps}
							/>
						</Grid>
					</Grid>




					<Grid container item xs={12} sm={12} md={6}>
						<Grid item container xs={12} style={{marginTop: 30}}>
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									Balance before depositing
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{"$" + currentBalance}
								</Typography>
							</Grid>
						{/*</Grid>*/}

						{/*<Grid item container xs={12}>*/}
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									This deposit
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{thisDeposit}
								</Typography>
							</Grid>
						{/*</Grid>*/}

						{/*<Grid item container xs={12}>*/}
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									Final balance
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{finalBalance}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{props.errorMessage &&
                <div>
                    <p style={{color: "red"}}>
						{props.errorMessage}
                    </p>
                </div>
				}

				<div style={{marginTop: 30}}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						disabled={!credNumber || !cvv || !month || !year || !displayDepositValue}
						onClick={attemptDeposit}
						{...injectPropsForSafari}
					>
						Deposit Funds
					</Button>
				</div>
			</div>
		</Paper>
	)
};

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	},
	balanceInfo: {
		fontSize: 18,
	},
	textRight: {
		textAlign: "right",
	},
	grayText: {
		color: "gray",
	},
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IDepositFundsProps) => {
	return {
		currentBalance: store.tradingStore.users[store.tradingStore.currentUser].availableBalance,
		errorMessage: store.tradingStore.depositError,
		...props,
	}
})(DepositFunds));
