import * as React from "react";
import {Checkbox, Divider, Radio, Typography} from "@material-ui/core";
import {numberWithCommas} from "../services/utils";

export interface IPayoutSelectorHelperProps {
	title: string;
	amount: number;
	checked: boolean;
	checkChanger: any;
	side: "LEFT" | "RIGHT"
}

const PayoutSelectorHelper: React.FunctionComponent<IPayoutSelectorHelperProps> = (props: IPayoutSelectorHelperProps): JSX.Element => {

	const {title, amount, checked, checkChanger, side} = props;

	return (
		<div style={style.container}>
			<Checkbox
				color="primary"
				onChange={() => {
					checkChanger(side)
				}}
				checked={checked}
			/>

			<div style={style.rightHalf}>
				<Typography
					variant="subtitle2"
					style={style.title}
				>
					{title}
				</Typography>

				<Typography
					variant="subtitle1"
					style={{
						...style.alignTextRight,
						...style.amountText,
						color: checked ? "black" : "gray"
					}}
				>
					{numberWithCommas(amount)}
				</Typography>

				<Divider/>

				<Typography
					variant="subtitle1"
					style={{
						...style.alignTextRight,
						...style.valueText,
						color: checked ? "black" : "gray"
					}}
				>
					{"$" + numberWithCommas(amount.toFixed(2))}
				</Typography>
			</div>
		</div>
	);
};

const style: any = {
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	rightHalf: {
		paddingLeft: 10,
	},
	title: {
		color: "gray",
		fontSize: 11,
	},
	alignTextRight: {
		textAlign: "right",
	},
	amountText: {
		fontSize: 16,
	},
	valueText: {
		fontSize: 17,
	}
};

export default PayoutSelectorHelper;
