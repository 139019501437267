import {Grid, Paper, Tooltip, Typography} from "@material-ui/core";
import * as React from "react";

export interface IUsefulLinkProps {
	imageSource?: string;
	title: string;
	body: string;
	link?: string;
}

const UsefulLink: React.FunctionComponent<IUsefulLinkProps> = (props: IUsefulLinkProps): JSX.Element => {

	const {imageSource, title, body, link} = props;

	return (
		<a href={link} target="_blank">
			<Paper>
				<Grid
					container
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={3}
					>
						<div
							style={{
								height: "100%",
								overflow: "hidden"
							}}
						>
							<img
								src={imageSource}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "contain",
								}}
							/>
						</div>
					</Grid>


					<Grid
						item
						xs={12}
						sm={12}
						md={9}
						style={{padding: 15, minHeight: 180}}
					>
						<Typography
							variant={"h6"}
						>
							{title}
						</Typography>

						<Typography
							variant={"body1"}
						>
							{body}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</a>
	)
};

UsefulLink.defaultProps = {
	imageSource: process.env.PUBLIC_URL + "rem-article-0.jpg",
	title: "Title",
	body: "Body",
	link: "",
};

export default UsefulLink;
