import * as React from "react";
import {Paper, Typography, withStyles} from "@material-ui/core";
import CurrentOffers, {cancelTradingTableType} from "../components/TableView/CurrentOffers";
import HistoryOfOffers from "../components/TableView/HistoryOfOffers";

export interface IYourOrdersPageProps {
	classes?: any;
}

const YourOrdersPage: React.FC<IYourOrdersPageProps> = (props: IYourOrdersPageProps): JSX.Element => {

	const {classes} = props;

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold", marginTop: 20}}>
					Current Orders
				</Typography>
				<CurrentOffers variety={cancelTradingTableType.BOTH}/>

				<div style={{height: 30}}/>
				<Typography style={{fontSize: 24, fontWeight: "bold", marginTop: 20}}>
					History of Orders
				</Typography>
				<HistoryOfOffers variety={cancelTradingTableType.BOTH}/>
			</div>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 1400,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	}
});

// @ts-ignore
export default withStyles(styles)(YourOrdersPage);

