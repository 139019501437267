import {Card, CardContent, Tooltip, Typography, withStyles, Zoom} from "@material-ui/core";
import * as React from "react";

export interface IInternalMarketCardProps {
	classes?: any;
	title: string;
	securityName: string;
	value: any;
	toolTitle?: string;
}

const InternalMarketCard: React.FunctionComponent<IInternalMarketCardProps> = (props: IInternalMarketCardProps): JSX.Element => {

	const {classes, title, securityName, value, toolTitle} = props;

	return (
		<Tooltip
			disableFocusListener
			TransitionComponent={Zoom}
			title={toolTitle ? toolTitle : title}
		>
			<Card className={classes.card}>
				<CardContent>
					<Typography variant="h6">
						{title}
					</Typography>

					<Typography className={classes.title} color="textSecondary" gutterBottom>
						{securityName}
					</Typography>

					<Typography variant="h5">
						{value}
					</Typography>
				</CardContent>
			</Card>
		</Tooltip>
	);
};

const styles = {
	card: {
		height: "100%",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
};

export default withStyles(styles)(InternalMarketCard);
