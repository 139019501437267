import {IAction} from "../index";
import {tradingType} from "./TradingReducer";
import {store} from "../";
import {IStore} from "../initialStore";
import {IOffer, IUser, Listing} from "./tradingStore";
import createDefaultTradingStore from "./createDefaultTradingStore";
import cloneDeep from "lodash/cloneDeep";
import isNaN from "lodash/isNaN";
import moment from "moment";
import {
	isInFuture,
	numberWithCommas,
	validateEmail,
	validCreditCardNumber,
	validCVV,
	validMonth,
	validYear
} from "../../utils";

export type ITradingPayload = any;

export function listBuyOffer(securityID: number, amount: number, price: number, aboveMarketValue: boolean = true): IAction<tradingType, IStore | string> {
	if (amount < 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a minimum amount of 1."
		}
	}

	if (price < 0.01) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a price of at least 1 cent."
		}
	}

	if (price > 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a price less than or equal to $1.00."
		}
	}

	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const userID = s.tradingStore.currentUser;
	const user: IUser = s.tradingStore.users[userID];
	const total: number = amount * price;
	if (total > user.availableBalance) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You do not have enough available balance."
		}
	}
	const priceAsOf = s.tradingStore.securities[securityID].priceAsOf;

	s.tradingStore.buyOffers[s.tradingStore.buyOfferIncrement] = {
		userID,
		securityID,
		amount,
		price,
		aboveMarketValue,
		priceAsOf,
		listingType: Listing.BID
	};

	s.tradingStore.bidHistory[s.tradingStore.buyOfferIncrement] = {
		userID,
		securityID,
		amount,
		price,
		aboveMarketValue,
		priceAsOf,
		initialAmount: amount,
		active: true,
		lookupID: s.tradingStore.buyOfferIncrement,
		date: moment().toISOString(),
		listingType: Listing.BID
	};

	s.tradingStore.users[userID].availableBalance -= total;
	s.tradingStore.users[userID].investedBalance += total;
	s.tradingStore.buyOfferIncrement++;

	s.tradingStore.toastMessage = `Buy order listed, $${numberWithCommas(total.toFixed(2))} was invested.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}


export function removeBuyOffer(offerID: number): IAction<tradingType, IStore | string> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const offer: IOffer = s.tradingStore.buyOffers[offerID];
	const investedAmount: number = offer.amount * offer.price;
	s.tradingStore.users[offer.userID].availableBalance += investedAmount;
	s.tradingStore.users[offer.userID].investedBalance -= investedAmount;
	s.tradingStore.bidHistory[offerID].active = false;

	delete s.tradingStore.buyOffers[offerID];

	s.tradingStore.toastMessage = `Buy order cancelled, $${numberWithCommas(investedAmount.toFixed(2))} debited to available balance.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function sellToBuyOffer(offerID: number, amount: number): IAction<tradingType, IStore | string> {

	if (isNaN(amount)) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "Please enter a valid amount.",
		}
	}

	if (amount < 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a minimum amount of 1."
		}
	}

	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const userID = s.tradingStore.currentUser;
	const user: IUser = s.tradingStore.users[userID];
	const offer: IOffer = s.tradingStore.buyOffers[offerID];
	const total: number = offer.price * amount;

	if (amount > user.securities[offer.securityID].amountOwned) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You do not have enough of this security available to make this transaction.",
		}
	}

	if (offer.aboveMarketValue) {
		if (amount > user.securities[offer.securityID].amountAboveOwned) {
			return {
				type: tradingType.SHOW_TRADING_ERROR,
				payload: "You do not have enough of this security that is issued for above market value available to make this transaction.",
			}
		}
	} else {
		if (amount > user.securities[offer.securityID].amountBelowOwned) {
			return {
				type: tradingType.SHOW_TRADING_ERROR,
				payload: "You do not have enough of this security that is issued for below market value available to make this transaction.",
			}
		}
	}

	if (amount > offer.amount) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "There is not enough of this security listed to fulfill this transaction.",
		}
	}

	s.tradingStore.buyOffers[offerID].amount -= amount;
	s.tradingStore.users[userID].availableBalance += total;
	s.tradingStore.users[offer.userID].investedBalance -= total;
	s.tradingStore.users[offer.userID].securities[offer.securityID].amountOwned += amount;
	s.tradingStore.users[userID].securities[offer.securityID].amountOwned -= amount;
	if (offer.aboveMarketValue) {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountAboveOwned += amount;
		s.tradingStore.users[userID].securities[offer.securityID].amountAboveOwned -= amount;
	} else {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountBelowOwned += amount;
		s.tradingStore.users[userID].securities[offer.securityID].amountBelowOwned -= amount;
	}

	// history
	s.tradingStore.history.push(cloneDeep({
		date: moment().toISOString(),
		listingType: Listing.BID,
		listerID: offer.userID,
		acceptorID: userID,
		quantity: amount,
		price: offer.price,
		securityID: offer.securityID,
		aboveMarketValue: offer.aboveMarketValue,
	}));

	s.tradingStore.bidHistory[offerID].amount -= amount;

	if (s.tradingStore.buyOffers[offerID].amount <= 0) {
		s.tradingStore.bidHistory[offerID].active = false;
		delete s.tradingStore.buyOffers[offerID];
	}

	s.tradingStore.toastMessage = `Buy order accepted, $${numberWithCommas(total.toFixed(2))} was credited from your available balance.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function listSellOffer(securityID: number, amount: number, price: number, aboveMarketValue: boolean = true): IAction<tradingType, IStore | string> {
	if (amount < 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a minimum amount of 1."
		}
	}

	if (price < 0.01) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a price of at least 1 cent."
		}
	}

	if (price > 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a price less than or equal to $1.00."
		}
	}

	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const userID = s.tradingStore.currentUser;
	const user: IUser = s.tradingStore.users[userID];
	if (amount > user.securities[securityID].amountOwned) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You do not have enough of that security."
		}
	}

	if (aboveMarketValue) {
		if (amount > user.securities[securityID].amountAboveOwned) {
			return {
				type: tradingType.SHOW_TRADING_ERROR,
				payload: "You do not have enough of that security that is issued for above market value."
			}
		}
	} else {
		if (amount > user.securities[securityID].amountBelowOwned) {
			return {
				type: tradingType.SHOW_TRADING_ERROR,
				payload: "You do not have enough of that security that is issued for below market value."
			}
		}
	}

	const priceAsOf = s.tradingStore.securities[securityID].priceAsOf;

	s.tradingStore.sellOffers[s.tradingStore.sellOfferIncrement] = {
		userID,
		securityID,
		amount,
		price,
		aboveMarketValue,
		priceAsOf,
		listingType: Listing.OFFER
	};

	s.tradingStore.offerHistory[s.tradingStore.sellOfferIncrement] = {
		userID,
		securityID,
		amount,
		price,
		aboveMarketValue,
		priceAsOf,
		initialAmount: amount,
		active: true,
		lookupID: s.tradingStore.sellOfferIncrement,
		date: moment().toISOString(),
		listingType: Listing.OFFER
	};

	user.securities[securityID].amountOwned -= amount;
	user.securities[securityID].amountListed += amount;

	if (aboveMarketValue) {
		user.securities[securityID].amountAboveOwned -= amount;
		user.securities[securityID].amountAboveListed += amount;
	} else {
		user.securities[securityID].amountBelowOwned -= amount;
		user.securities[securityID].amountBelowListed += amount;
	}

	s.tradingStore.sellOfferIncrement++;

	s.tradingStore.toastMessage = `Sell order listed, ${amount} of the security has been moved from owned to listed.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function removeSellOffer(offerID: number): IAction<tradingType, IStore | string> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const offer: IOffer = s.tradingStore.sellOffers[offerID];
	s.tradingStore.users[offer.userID].securities[offer.securityID].amountListed -= offer.amount;
	s.tradingStore.users[offer.userID].securities[offer.securityID].amountOwned += offer.amount;

	if (offer.aboveMarketValue) {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountAboveListed -= offer.amount;
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountAboveOwned += offer.amount;
	} else {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountBelowListed -= offer.amount;
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountBelowOwned += offer.amount;
	}

	s.tradingStore.offerHistory[offerID].active = false;

	delete s.tradingStore.sellOffers[offerID];

	s.tradingStore.toastMessage = `Sell order cancelled, ${offer.amount} of the security has been moved from listed to owned.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function buyFromSellOffer(offerID: number, amount: number): IAction<tradingType, IStore | string> {

	if (isNaN(amount)) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "Please enter a valid amount.",
		}
	}

	if (amount < 1) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "You must have a minimum amount of 1."
		}
	}

	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const userID = s.tradingStore.currentUser;
	const user: IUser = s.tradingStore.users[userID];
	const offer: IOffer = s.tradingStore.sellOffers[offerID];
	const total: number = offer.price * amount;

	if (amount > offer.amount) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "There is not enough of this security available to make this transaction.",
		}
	}

	if (total > user.availableBalance) {
		return {
			type: tradingType.SHOW_TRADING_ERROR,
			payload: "Not enough available funds.",
		}
	}

	s.tradingStore.sellOffers[offerID].amount -= amount;
	s.tradingStore.users[userID].availableBalance -= total;
	s.tradingStore.users[offer.userID].availableBalance += total;
	s.tradingStore.users[offer.userID].securities[offer.securityID].amountListed -= amount;
	s.tradingStore.users[userID].securities[offer.securityID].amountOwned += amount;

	if (offer.aboveMarketValue) {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountAboveListed -= amount;
		s.tradingStore.users[userID].securities[offer.securityID].amountAboveOwned += amount;
	} else {
		s.tradingStore.users[offer.userID].securities[offer.securityID].amountBelowListed -= amount;
		s.tradingStore.users[userID].securities[offer.securityID].amountBelowOwned += amount;
	}

	// history
	const time = moment();

	s.tradingStore.history.push(cloneDeep({
		date: time.toISOString(),
		listingType: Listing.OFFER,
		listerID: offer.userID,
		acceptorID: userID,
		quantity: amount,
		price: offer.price,
		securityID: offer.securityID,
		aboveMarketValue: offer.aboveMarketValue,
	}));

	s.tradingStore.offerHistory[offerID].amount -= amount;

	if (s.tradingStore.sellOffers[offerID].amount <= 0) {
		s.tradingStore.offerHistory[offerID].active = false;
		delete s.tradingStore.sellOffers[offerID];
	}

	// s.tradingStore.toastMessage = `Offer purchased, ${offer.amount} of the security has been transferred.`;
	s.tradingStore.toastMessage = `Sell order purchased, ${amount} of the security has been transferred.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function removeTradingError(): IAction<tradingType, IStore> {
	return {
		type: tradingType.CLEAR_TRADING_ERROR,
	}
}

export function removeDepositError(): IAction<tradingType, IStore> {
	return {
		type: tradingType.CLEAR_DEPOSIT_ERROR,
	}
}

export function removeWithdrawalError(): IAction<tradingType, IStore> {
	return {
		type: tradingType.CLEAR_WITHDRAWAL_ERROR,
	}
}

export function changeUser(userID: number): IAction<tradingType.CHANGE_USER, number> {
	return {
		type: tradingType.CHANGE_USER,
		payload: userID,
	}
}

export function withdrawal(amount: number, email: string): IAction<tradingType, IStore | string> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	const availableBalance = s.tradingStore.users[s.tradingStore.currentUser].availableBalance;
	if (amount > availableBalance) {
		return {
			type: tradingType.SHOW_WITHDRAWAL_ERROR,
			payload: "There is insufficient funds in your available balance to make this withdrawal.",
		}
	}

	if (!validateEmail(email)) {
		return {
			type: tradingType.SHOW_WITHDRAWAL_ERROR,
			payload: `"${email}" is not a proper email. Please input a valid email.`,
		}
	}

	s.tradingStore.users[s.tradingStore.currentUser].withdrawals.push(amount);
	s.tradingStore.users[s.tradingStore.currentUser].availableBalance -= amount;

	s.tradingStore.toastMessage = `Withdrawal of $${numberWithCommas(amount.toFixed(2))} has been processed and credited from your available balance.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function deposit(amount: number, creditCardNumber: string, cvv: string, month: number, year: number): IAction<tradingType, IStore | string> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);

	if (!validCreditCardNumber(creditCardNumber)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: `${creditCardNumber} is not a valid credit card number`
		}
	}

	if (!validCVV(cvv)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: `${cvv} is not a valid CVV`
		}
	}

	if (!validCVV(cvv)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: `${cvv} is not a valid CVV`
		}
	}

	if (!validMonth(month)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: `Please input a valid expiry month between 1 and 12 inclusively.`
		}
	}

	if (!validYear(year)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: `Please input a valid expiry year.`
		}
	}

	if (!isInFuture(month, year)) {
		return {
			type: tradingType.SHOW_DEPOSIT_ERROR,
			payload: "The date input for this credit card is expired."
		}
	}

	s.tradingStore.users[s.tradingStore.currentUser].deposits.push(amount);
	s.tradingStore.users[s.tradingStore.currentUser].availableBalance += amount;

	s.tradingStore.toastMessage = `Deposit of $${numberWithCommas(amount.toFixed(2))} has been processed and debited from your available balance.`;

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function resetStore(): IAction<tradingType.EDIT_STORE, IStore> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	s.tradingStore = createDefaultTradingStore();
	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}

export function markPageAsVisited(page: string = ""): IAction<tradingType.EDIT_STORE, IStore> {
	const s: IStore = cloneDeep(store.getState() as unknown as IStore);
	if (s.tradingStore.firstTimePages.includes(page)) {
		s.tradingStore.firstTimePages.splice(s.tradingStore.firstTimePages.indexOf(page), 1);
	}

	return {
		type: tradingType.EDIT_STORE,
		payload: s,
	}
}
