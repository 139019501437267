import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {Typography, withStyles} from "@material-ui/core";
import {ReactNode} from "react";

export interface IInstructionsProps {
	classes?: any;
}


const Instructions: React.FunctionComponent<IInstructionsProps> = (props: IInstructionsProps): JSX.Element => {

	const {classes} = props;

	function createImage(name: string): ReactNode {
		return (
			<div className={classes.imageDiv}>
				<img src={`${process.env.PUBLIC_URL}/instructions_screenshots/${name}.png`} alt={name}
				     className={classes.image} key={name}/>
			</div>
		)
	}

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography>
					<Typography variant="h4">
						Instructions for Using Real Estate Securities Trading Exchange Platform
					</Typography>
					<br/>
					This is a user’s guide for using Real Estate Prediction Securities Exchange (“The Exchange”).
					<br/>
					<br/>
					The website provides a demo version of the Exchange. Currently, the Exchange offers prediction options in Greater Vancouver Area only. These options are written on so-called “neighborhood” residential real estate properties. There are seven neighborhoods: Vancouver Westside, Vancouver Eastside, North Vancouver, West Vancouver, Burnaby, Richmond and New Westminster. There are two types of the underlying properties, two bedroom, two bathroom apartment, and three bedroom, two bathroom detached house. The options have two expiration cycles (six or twelve months). They are similar to so-called “binomial” options traded at NADEX (North American Derivatives Exchange), and at CBOE (Chicago Board Options Exchange). Binomial options pay a fixed amount if an event happens, and nothing if it doesn’t.
					<br/>
					<br/>
					In the Exchange, the event is defined as the actual average price of the neighborhood property (apartment or house) at a future pre-determined date (either June 30 or December 31).
					<br/>
					<br/>
					Major feature of the options traded at Exchange is that their prices reflect the probability of a future event happening and thus range from zero to $1.00 (100% probability or certainty).
					<br/>
					<br/>
					The major difference between binomial and conventional options is that the holders of binomial options cannot “exercise” them (cannot buy or sell) the underlying asset. In our case, the holder cannot buy or sell the apartment or a house.
					<br/>
					<br/>
					The Exchange will be selling the options in a contract (“The Contract”) that consists of two options with two mutually exclusive events. (The actual price will be either above or below the price in the option contract). The Contracts will sell shares in lots of 1,000.
					<br/>
					<br/>
					An example of a Contract in the Exchange would be: “The average price of a 3 bedroom, 2 bathroom detached house on a standard city 133 x 33 feet lot on Vancouver Westside will be <b>$3.5 million and above/below $3.5 million on June 30, 2020</b>”. It consists of two securities, “above” security (price at expiration will be $3.5 million and above), and a “below” security (“price at expiration will be below $3.5 million”).
					<br/>
					<br/>
					These are two mutually exclusive events, as at expiration, the actual price can either $3.5 million and above, or below $3.5 million, but not both.
					<br/>
					<br/>
					If at expiration date the actual price of the house is $3.56 million, than “above” security is “in the money”, and he/she will be paid $1.00 per each share they hold in their closing portfolio, and “below” security will be “out of the money” and will be paid zero for the shares.
					<br/>
					<br/>
					The other outcome is that the actual price is $3.4 million, in which case “above” security will pay zero for the shares, but “below” security will be “in the money” and will pay $1.00 for per each share they hold in their closing portfolio.
					<br/>
					<br/>
					In order to trade in the market, the traders will have to register, create the profile, open an account and deposit funds and purchase contracts from the Exchange. For the sake of convenience, the demo version already created ten profiles (“users”). They already “opened” an account, “purchased” the contracts from the Exchange and “traded” (bought and sold) options in both the “above” and “below” markets and thus have their “portfolios”, “trading profits” and “trading history”. The new user just has to select which one of these profiles they want to assume, and can trade right away.
					<br/>
					<br/>
					The Exchange will use data from MLS and Greater Vancouver Real Estate Board to calculate the actual price of the real estate property (apartment or a house in a given neighborhood) at expiration.
					<br/>
					<br/>
					At this stage, the Exchange is offering the trading  with “play” money only. Trading with real money is planned for the next stage of the project.
					<br/>
					<br/>
					<Typography variant="h4">
						Menu
					</Typography>
					<u>The menu is given in the side bar</u>
					<br/>
					<br/>
					The sidebar is the main navigation tool to access all other parts of the website. There are three
					main sections: <b>Overview</b>, <b>Demonstration</b>, and <b>Contact Information</b>.
					<br/>
					<br/>

					<Typography variant="h4">
						Overview
					</Typography>
					Overview provides access to the ‘Demo Intro’ and ‘Instructions’, which both provide further details on this website and its purpose.
					{createImage("sidebar_overview")}
					<br/>
					<br/>

					<Typography variant="h4">
						Demo
					</Typography>
					The demonstration portion provides access to the market. It has four main sections, <b>My portfolio</b>, <b>Trading</b>, <b>Internal Market</b>, and <b>Information Center</b>.
					<br/>
					<br/>
					<i>Note: All of the links can be clicked on to access information on these sites.</i>
					{createImage("sidebar_demonstration")}
					<br/>
					<br/>

					<Typography variant="h5">
						My Portfolio
					</Typography>
					When you click on <b>My portfolio</b>, the screen will automatically open the <b>Holdings tab</b>. Besides <b>Holdings</b>, you will see the following tabs:  <b>Financial summary</b>, <b>Payoff Projection</b>, <b>Deposit Funds</b>, <b>Withdraw Funds</b> and <b>Profile</b>.
					<br/>
					<br/>

					<Typography variant="h6">
						Holdings
					</Typography>
					The purpose of the <b>Holdings</b> tab is to give you an overview of the shares available in your portfolio. Here is an explanation of the columns, from left to right:
					<br/>
					<br/>
					<b>Property type:</b> There are two main residential real estate properties available: two-bedroom, two-bathroom apartment, and three-bedroom, two-bathroom detached house on a standard city 133 by 33 feet lot. The properties can be located in one of seven neighborhoods: Vancouver Westside, Vancouver Eastside, North Vancouver, West Vancouver, Richmond, Burnaby and New Westminster.<br/>
					<b>Expiration date:</b> When the security will expire<br/>
					<b>Price:</b> Price at expiration date<br/>
					<b>Above Market Available:</b> The number of “above” shares available<br/>
					<b>Below Market Available:</b> The number of “below” shares available<br/>
					<b>Above Market Invested:</b> The number of “above” shares invested<br/>
					<b>Below Market Invested:</b> The number of “below” shares invested<br/>
					<b>Total:</b> Total number of both “above” and “below” available and invested shares<br/>
					<b>Total Share:</b> Percentage of holdings out of total number of shares issued by the Exchange
					<br/>
					<br/>
					{createImage("portfolio_portfolio_breakdown")}
					<br/>
					<br/>

					<Typography variant="h6">
						Financial Summary
					</Typography>
					The ‘Financial Summary’ page is a “cash flow statement”. It gives you a breakdown of money invested and gains from trading on the market. It displays your deposits and withdrawals, the amount of money paid for the share purchases and the amount of money gained from selling the shares. If you hover your cursor over individual entries, it will display further details. Each entry can be expanded or collapsed to show history.
					{createImage("account_financial_summary")}
					<br/>
					<br/>

					<Typography variant="h6">
						Payoff projection
					</Typography>
					This page gives you the possibility to calculate your potential payout (profits and losses) if the market were to close today with different scenarios of future outcomes. Please note that the actual payout (profit or loss) will depend on the actual future price of the underlying real estate asset at the expiration date.
					{createImage("payoff_projection")}
					<br/>
					<br/>

					<Typography variant="h6">
						Deposit funds
					</Typography>
					This option allows you to deposit funds for trading purposes. It allows you to enter the details of your credit card number and the amount you want to deposit into your account.
					{createImage("deposit_funds")}
					<br/>
					<br/>

					<Typography variant="h6">
						Withdraw funds
					</Typography>
					This option allows you to withdraw funds from your account. It asks you to enter your email address for interac payment and the amount you want to withdraw from your account.
					{createImage("withdraw_funds")}
					<br/>
					<br/>

					<Typography variant="h6">
						Profile
					</Typography>
					The demo provides you with ten fictitious users to choose from. When you click on “Profile” tab, the screen is displaying the profile of current user, which means you are logged on as him/her. If you want to select another user, you should click on “Change user” button on the screen.
					{createImage("account_profile")}
					<br/>
					<br/>

					<Typography variant="h5">
						Trading
					</Typography>
					This is the main part of the exchange where trading takes place. It allows you to post buy and sell orders and to accept other traders’ posted orders. At the top of the screen you have the following tabs:
					<br/>
					<br/>
					<b>Buy Orders:</b> Displays all the posted buy orders by other traders.<br/>
					<b>Sell Orders:</b>  Displays all the sell orders posted by other traders.<br/>
					<b>My Trading History:</b>  Displays your trading history up to that point. It includes all executed orders up to the present time.<br/>
					<b>My Orders:</b>  Displays your current posted orders.
					<br/>
					<br/>
					<Typography variant="h6">
						Buy orders
					</Typography>
					If you want to purchase shares of particular security, you have to post the buy order. To post it, you first have to go to <b>Buy Orders</b> and then click on the <b>Post a Buy Order</b> button. A Dialogue box will open, and you can then choose (1) security by scrolling down the box, (2) whether you want to post an “above” or “below” order, (3) then you enter the number of lots you want to purchase, and (4) the price per share you are willing to pay. Once you press <b>Post</b> button, the software will first check whether you have enough money to fill in the order, and then it will set aside the required amount of money from your available balance and place it in the invested balance.
					<br/>
					<br/>
					{createImage("bid_table")}
					<br/>
					<br/>
					{createImage("bid_post")}
					<br/>
					<br/>

					<Typography variant="h6">
						Sell orders
					</Typography>
					Likewise, if you want to sell the shares of a particular security, you will have to post a sell order. To post it, you will first have to go to <b>Sell Orders</b> and then click on the <b>Post sell order</b> button. A Dialogue box will open, and you can then choose (1) security by scrolling down the box, (2) whether you want to post an “above” or “below” order (3) then you enter the number of lots you want to sell, and (4) the price per share you are willing to sell.
					<br/>
					<br/>
					Once you press “post a sell order” button, the software will first check that you have enough shares available, and if you do, then that number of shares will be set aside in case other trader accepts the order.
					<br/>
					<br/>
					<i>
						You can cancel your posted buy and sell orders at any time if you change your mind until they get accepted. Also, when you are accepting them, you can partially fill the orders (in the multiple of lots).
					</i>
					<br/>
					<br/>
					{createImage("offer_table")}
					<br/>
					<br/>
					{createImage("offer_post")}
					<br/>
					<br/>
					<Typography variant="h6">
						My Trading History
					</Typography>
					This page displays your trading history. It shows the date of trade, action (accepting or posting buy and sell orders), listing type, property type, price and type of security (above or below). It shows your executed buy and sell orders.
					<br/>
					<br/>
					{createImage("your_history")}
					<br/>
					<br/>

					<Typography variant="h6">
						My Orders
					</Typography>
					This page displays your <b>current</b> buy and sell orders. They are listed by property type, expiration date, amount left, price as of expiration date, and the type of security (above or below).
					<br/>
					<br/>
					{createImage("portfolio_current_orders")}
					<br/>
					<br/>

					<Typography variant="h5">
						Internal Market
					</Typography>
					This section provides an overview of the securities and the trading statistics.
					<br/>
					<br/>

					<Typography variant="h6">
						Statistics
					</Typography>
					This tab displays the summary statistics for the market and for each individual security. The top of the page provides the information on the market activity, such as daily volume of trading (in shares and in dollar value), most active securities (in terms of volume and dollar value), and highest and lowest average price in the market.
					<br/>
					<br/>
					In the lower part of the page, you can select any security and get a breakdown of data from the last day/week/month. After you make a selection, it will display the graph with the movement of prices in the given time frame, and the summary statistics, such as average price, price change, quantity traded, lowest and highest price and the number of trades. If you hover your cursor over any box of data, it will display a tooltip with a more detailed explanation of the data shown.
					{createImage("internal_market_statistics")}
					<br/>
					<br/>

					<Typography variant="h6">
						Trading History
					</Typography>
					This tab displays all the trading history from the beginning up to the current date. It provides the date, listing type (buy or sell orders), property type, price type of security (above or below) price, quantity and monetary value of the trade.
					{createImage("internal_market_history")}
					<br/>
					<br/>

					<Typography variant="h6">
						Securities
					</Typography>
					This tab provides a list of all securities currently traded in the market. There are seven neighborhoods, two types of properties, and two market options, above and below. This makes for 56 distinct securities. For each security, it displays:  property type/neighborhood, price at expiration date, expiration date, number of shares issued for above/below and the total number of shares issued.
					<br/>
					<br/>
					{createImage("internal_market_securities")}
					<br/>
					<br/>
					<Typography variant="h5">
						Information Center
					</Typography>
					This section provides the links to different sources of information that can help you in your decision making in terms of trading strategies.
					<br/>
					<br/>

					<Typography variant="h6">
						News Feed
					</Typography>
					This tab shows the articles from various news media regarding the relevant real estate news. The page provides blurbs the relevant articles and also allows you to open the article upon clicking on them.
					<br/>
					<br/>
					<i>This news feed will update in real time in beta version of the software.</i>
					<br/>
					<br/>
					{createImage("research_center_news_feed")}
					<br/>
					<br/>

					<Typography variant="h6">
						Real Estate Markets
					</Typography>
					The <b>Real Estate Markets</b> tab shows each of the different markets that will be available in the simulation (current demo only covers Vancouver). You may select any of the different markets to view a list of relevant articles to the market (in the current version of the software, these articles are placeholders).
					<br/>
					<br/>
					{createImage("research_center_markets")}
					<br/>
					<br/>

					<Typography variant="h6">
						Useful Links
					</Typography>
					This section provides a list of websites that cover the residential real estate in Vancouver and Canada. You can click on any of the links and their websites will open in a new tab of your browser.
					<br/>
					<br/>
					{createImage("research_center_useful_links")}
					<br/>
					<br/>

					<Typography variant="h5">
						Contact Information
					</Typography>
					This section provides contact details for the owner of the website.
					<br/>
					<br/>
					{createImage("sidebar_contact_information")}
					<br/>
					<br/>

				</Typography>
			</div>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	card: {
		width: 345,
	},
	media: {
		height: 360,
	},
	text: {
		margin: '0px 16px',
	},
	image: {
		maxHeight: "750px",
		maxWidth: "80%",
		objectFit: "contain"
	},
	imageDiv: {
		alignItems: "center",
		width: "100%",
		textAlign: "center",
		paddingTop: 15,
		paddingBottom: 15,
	}
});

// @ts-ignore
export default withStyles(styles)(Instructions);
