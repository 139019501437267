import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import Timeline from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import {Link, withRouter} from "react-router-dom";
import Home from "@material-ui/icons/Home";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import PermIdentity from "@material-ui/icons/PermIdentity";
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import BarChart from '@material-ui/icons/BarChart';
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import {Language, LibraryBooks, More, Store} from "@material-ui/icons";
import {numberWithCommas} from "../services/utils";
import Tooltip from '@material-ui/core/Tooltip';
import {Typography} from "@material-ui/core";

interface IProps {
	availableBalance: number;
	investedBalance: number;
}

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const calculationTooltip: ReactNode = (
	<React.Fragment>
		<Typography>
			<b>Invested:</b> The amount of money currently allocated to buy orders. Money in buy orders, must be taken from available balance, so the money can be given to the seller when the buy order is accepted.
			<br/>
			<b>Available:</b> The amount of money that is free to be cashed out or used in purchasing securities.
		</Typography>
	</React.Fragment>
);

function Navigator(props) {
	const {classes, match, staticContext, ...other} = props;

	function createChildren(children: any[]): ReactNode {
		return children.map((child: any, i: number) => {

			let active: boolean = false;
			if (match.url === child.to) {
				active = true;
			}
			if (child.id === "Trading" && (
				match.url.includes("buy-orders") ||
				match.url.includes("sell-orders") ||
				// match.url.includes("portfolio") ||
				match.url.includes("my-trading-history") ||
				match.url.includes("my-bids") ||
				match.url.includes("my-offers") ||
				match.url.includes("my-orders")
			)) {
				active = true;
			}

			if (child.id === "Internal Market" && (
				match.url.includes("statistics") ||
				(match.url.includes("trading-history") && !match.url.includes("my-trading-history")) ||
				match.url.includes("securities")
			)) {
				active = true
			}

			if (child.id === "Information Center" && (
				match.url.includes("news") ||
				match.url.includes("macroeconomic-data") ||
				match.url.includes("cmhc") ||
				match.url.includes("real-estate-market") ||
				match.url.includes("useful-links") ||
				match.url.includes("yahoo-finance") ||
				match.url.includes("cnbc")
			)) {
				active = true;
			}

			if (child.id === "My Portfolio" && (
				match.url.includes("profile") ||
				match.url.includes("portfolio") ||
				match.url.includes("financial-summary") ||
				match.url.includes("payout-projection") ||
				match.url.includes("deposit-funds") ||
				match.url.includes("withdraw-funds")
			)) {
				active = true
			}

			const classesArr = [classes.item, classes.itemActionable];
			if (active) {
				classesArr.push(classes.itemActiveItem);
			}

			return (
				<Link to={child.to} key={child.id} onClick={props.onClose}>
					<ListItem
						button
						dense
						key={child.id}
						className={classNames(classesArr)}
					>
						<ListItemIcon>{child.icon}</ListItemIcon>
						<ListItemText
							classes={{
								primary: classes.itemPrimary,
								textDense: classes.textDense,
							}}
						>
							{child.id}
						</ListItemText>
					</ListItem>
				</Link>
			);
		});
	}

	function createCategory(categories: any[]): ReactNode {
		return categories.map((category: any, i: number) => {

			const children: ReactNode = createChildren(category.children);

			return (
				<React.Fragment key={"category" + i}>
					<ListItem className={classes.categoryHeader}>
						<ListItemText
							classes={{
								primary: classes.categoryHeaderPrimary,
							}}
						>
							{category.id}
						</ListItemText>

					</ListItem>
					{children}
				</React.Fragment>
			);
		});
	}

	const profit = (-props.startingBalance + props.investedBalance + props.availableBalance).toFixed(2);
	let profitString;
	let profitColor;
	if (profit > 0) {
		profitString = `$ ${numberWithCommas(profit)} Profit`;
		profitColor = 'green';
	} else {
		profitString = `$ ${numberWithCommas(Math.abs(profit))} Loss`;
		profitColor = 'red';
	}

	return (
		<Drawer variant="permanent" {...other}>
			<List disablePadding>
				<ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory, classes.logoContainer)}>
					<img src="/logo_white.png" className={classes.logo}/>
				</ListItem>
				{/*https://github.com/Metroxe/realestate-securities-alpha/issues/117*/}
				{/*<LightTooltip title={calculationTooltip} placement="right" interactive>*/}
				{/*<ListItem className={classNames(classes.item, classes.itemCategory)}>*/}
				{/*	<List>*/}
				{/*		<ListItem className={classNames(classes.item)}>*/}
				{/*			<ListItemIcon>*/}
				{/*				<BarChart/>*/}
				{/*			</ListItemIcon>*/}
				{/*			<ListItemText*/}
				{/*				classes={{*/}
				{/*					primary: classes.itemPrimary,*/}
				{/*				}}*/}
				{/*			>*/}
				{/*				$ {numberWithCommas(props.investedBalance.toFixed(2))} Invested*/}
				{/*			</ListItemText>*/}
				{/*		</ListItem>*/}
				{/*		<ListItem className={classNames(classes.item)}>*/}
				{/*			<ListItemIcon>*/}
				{/*				<AccountBalanceWallet/>*/}
				{/*			</ListItemIcon>*/}
				{/*			<ListItemText*/}
				{/*				classes={{*/}
				{/*					primary: classes.itemPrimary,*/}
				{/*				}}*/}
				{/*			>*/}
				{/*				$ {numberWithCommas(props.availableBalance.toFixed(2))} Available*/}
				{/*			</ListItemText>*/}
				{/*		</ListItem>*/}
				{/*	</List>*/}
				{/*</ListItem>*/}
				{/*</LightTooltip>*/}


				{createCategory(categories)}

				<div
					style={{
						marginTop: 20
					}}
				>
					<div
						style={{
							width: "100%",
							height: 2,
							backgroundColor: "white",
						}}
					/>
					<ListItem className={classes.categoryHeader}>
						<ListItemText
							classes={{
								primary: classes.categoryHeaderPrimary,
							}}
						>
							Contact Information
						</ListItemText>

					</ListItem>

					<div>
						<div>
							<ListItem
								button
								dense
								className={classNames([classes.item])}
							>
								<ListItemIcon><PermIdentity/></ListItemIcon>
								<ListItemText
									classes={{
										primary: classes.itemPrimary,
										textDense: classes.textDense,
									}}
								>
									Momo Deretic
								</ListItemText>
							</ListItem>
						</div>

						<a href="tel:6043587349">
							<ListItem
								button
								dense
								className={classNames([classes.item, classes.itemActionable])}
							>
								<ListItemIcon><Phone/></ListItemIcon>
								<ListItemText
									classes={{
										primary: classes.itemPrimary,
										textDense: classes.textDense,
									}}
								>
									604 358 7349
								</ListItemText>
							</ListItem>
						</a>

						<a
							href="mailto:momo@jazmodesoftware.com?subject=Real Estate Securities Trading (Alpha)"
							target="_blank"
						>
							<ListItem
								button
								dense
								className={classNames([classes.item, classes.itemActionable])}
							>
								<ListItemIcon><Email/></ListItemIcon>
								<ListItemText
									classes={{
										primary: classes.itemPrimary,
										textDense: classes.textDense,
									}}
								>
									momo@jazmodesoftware.com
								</ListItemText>
							</ListItem>
						</a>

						<a href="https://jazmodesoftware.com/" target="_blank">
							<ListItem
								button
								dense
								className={classNames([classes.item, classes.itemActionable])}
							>
								<ListItemIcon><Language/></ListItemIcon>
								<ListItemText
									classes={{
										primary: classes.itemPrimary,
										textDense: classes.textDense,
									}}
								>
									jazmodesoftware.com
								</ListItemText>
							</ListItem>
						</a>
					</div>
				</div>

			</List>
		</Drawer>
	);
}

export const categories = [
	{
		id: 'Overview',
		children: [
			{
				active: true,
				id: 'Demo Intro',
				icon: <Home/>,
				to: "/demo-intro",
			},
			{
				active: true,
				id: 'Instructions',
				icon: <More/>,
				to: "/instructions",
			},
			// {
			// 	active: true,
			// 	id: 'Glossary',
			// 	icon: <LibraryBooks/>,
			// 	to: "/glossary",
			// },
		],
	},
	{
		id: 'Demonstration',
		children: [
			{
				id: 'My Portfolio',
				icon: <PeopleIcon/>,
				to: "/my-portfolio/holdings",
			},
			{
				id: 'Trading',
				icon: <SettingsIcon/>,
				to: "/trading/buy-orders"
			},
			{
				id: "Internal Market",
				icon: <Store/>,
				to: "/internal-market/statistics",
			},
			{
				id: 'Information Center',
				icon: <Timeline/>,
				to: "/information-center/news",
			},
		],
	},
];

if (process.env.NODE_ENV === "development") {
	categories[0].children.push({
		active: true,
		id: 'Admin Portal',
		icon: <PeopleIcon/>,
		to: "/admin",
	});
}

export const contactCategory = [
	{
		id: "Contact Information",
		children: [
			{
				id: "Momo Deretic",
				icon: <PermIdentity/>,
			},
			{
				id: "604 358 7349",
				icon: <Phone/>,
			},
			{
				id: "momo@jazmodesoftware.com",
				icon: <Email/>,
			},
		],
	},
];

const styles = theme => ({
	categoryHeader: {
		paddingTop: 16,
		paddingBottom: 16,
	},
	categoryHeaderPrimary: {
		color: theme.palette.common.white,
	},
	logo: {
		width: 125,
	},
	logoContainer: {
		justifyContent: "center",
		width: "100%",
	},
	item: {
		paddingTop: 4,
		paddingBottom: 4,
		color: 'rgba(255, 255, 255, 0.7)',
	},
	itemCategory: {
		backgroundColor: '#232f3e',
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 16,
		paddingBottom: 16,
	},
	firebase: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.common.white,
	},
	itemActionable: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	itemActiveItem: {
		color: '#4fc3f7',
	},
	itemPrimary: {
		color: 'inherit',
		fontSize: theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize,
		},
	},
	textDense: {},
	divider: {
		marginTop: theme.spacing.unit * 2,
	},
});

// @ts-ignore
export default withStyles(styles)(withRouter(connect((store: IStore, props: IProps): IProps => {
	return {
		availableBalance: store.tradingStore.users[store.tradingStore.currentUser].availableBalance,
		investedBalance: store.tradingStore.users[store.tradingStore.currentUser].investedBalance,
		...props,
	}
})(Navigator)));
