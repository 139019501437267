import {tableOrder} from "./SecuritiesTable";
import {IOffer} from "../../services/redux/trading/tradingStore";

export interface IColumns {
	id: string,
	numeric: boolean,
	label: string,
}

// ask spencer how these work if you don't get it :) the sorting relies on the id to match properties pumped out in the row objects... it's interesting
export const buyColumns: IColumns[] = [
	{
		id: "securityID",
		numeric: true,
		label: "Property Type",
	},
	{
		id: "expirationDate",
		numeric: true,
		label: "Expiration Date",
	},
	{
		id: "priceAtExpiration",
		numeric: true,
		label: "Price At Expiration",
	},
	{
		numeric: true,
		id: "priceAsOf",
		label: "Price, June 30 2019",
	},
	{
		id: "amount",
		numeric: true,
		label: "Quantity",
	},
	{
		id: "price",
		numeric: true,
		label: "Price Per Security",
	},
	{
		numeric: true,
		id: "aboveMarketValue",
		label: "Above/Below Market Value",
	}
];

export const portfolioTableColumns: IColumns[] = [
	{
		id: "securityID",
		numeric: true,
		label: "Property Type",
	},
	{
		id: "expirationDate",
		numeric: true,
		label: "Expiration Date",
	},
	{
		id: "priceAtExpiration",
		numeric: true,
		label: "Price at Expiration",
	},
	{
		id: "amountAboveOwned",
		numeric: true,
		label: "Above Market Available",
	},
	{
		id: "amountBelowOwned",
		numeric: true,
		label: "Below Market Available",
	},
	{
		id: "amountAboveListed",
		numeric: true,
		label: "Above Market Invested",
	},
	{
		id: "amountBelowListed",
		numeric: true,
		label: "Below Market Invested",
	},
	{
		id: "amt",
		numeric: true,
		label: "Total",
	},
	{
		id: "totalShare",
		numeric: true,
		label: "Total Share",
	},
];

export interface ITableRowEntry {
	id?: any;
	type: string;
	quantity: number;
	price: number;
}

export function createData(row: IOffer): any {
	return {
		id: Math.random() * Math.random() * Math.random() * Math.random() * Math.random(),
		...row,
	}
}

export function desc(a: any, b: any, orderBy: string): number {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
export function stableSort(array: any[], cmp: any): any {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

export function getSorting(order: tableOrder, orderBy: string): any {
	return order === tableOrder.DESCENDING ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 100,
	},
	tableWrapper: {
		overflowX: 'auto',
	},
});
