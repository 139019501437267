import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Root from './containers/Root';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from './services/redux';
import Landing from './containers/Landing';

ReactDOM.render((
	<Provider store={store}>
		{/*<PersistGate loading={null} persistor={persistor}>*/}
			<Router>
				<Switch>
					<Route path="/" exact component={Landing}/>
					<Route path={"/*"} component={Root}/>
				</Switch>
			</Router>
		{/*</PersistGate>*/}
	</Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
