import * as React from "react";
import {ReactNode, useRef, useState} from "react";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "../ResearchCenter.css";
import RealEstateMarketArticle from "../../components/RealEstateMarketArticle";
import random from "lodash/random";

interface IRealEstateMarketProps {

}

const testData: any[] = [
	// {
	// 	src: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/North_Vancouver_201807.jpg/1200px-North_Vancouver_201807.jpg",
	// 	title: "North Vancouver",
	// 	// date: moment().subtract("2 hours 10 minutes").format("dddd "),
	// 	date: "1 hour ago",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	// {
	// 	src: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Dunbar_view_fr_Dunbar_%26_18th.JPG",
	// 	title: "Vancouver West Side",
	// 	date: "2 hours ago",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	// {
	// 	src: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Sunrise_Park_in_East_Vancouver.JPG",
	// 	title: "Vancouver East Side",
	// 	date: "4 hours ago",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	// {
	// 	src: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Burnaby_BC_Aerial_view_2015.jpg/1200px-Burnaby_BC_Aerial_view_2015.jpg",
	// 	title: "Burnaby",
	// 	date: "Yesterday",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	// {
	// 	src: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Richmond_BC_overview_201807.jpg/1024px-Richmond_BC_overview_201807.jpg",
	// 	title: "Richmond",
	// 	date: "Yesterday",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	// {
	// 	src: "http://wanderersyouth.club/wp-content/uploads//2018/12/capital-of-british-columbia-canada-capital-british-columbia-canada.jpg",
	// 	title: "New Westminster",
	// 	date: "Yesterday",
	// 	body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	// },
	{ // Vancouver
		src: process.env.PUBLIC_URL + "rem-header-van.jpg",
		title: "Vancouver",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Calgary
		src: process.env.PUBLIC_URL + "rem-header-cal.jpg",
		title: "Calgary",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Edmonton
		src: process.env.PUBLIC_URL + "rem-header-edm.jpg",
		title: "Edmonton",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Winnipeg
		src: process.env.PUBLIC_URL + "rem-header-win.jpg",
		title: "Winnipeg",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Toronto
		src: process.env.PUBLIC_URL + "rem-header-tor.jpg",
		title: "Toronto",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Montreal
		src: process.env.PUBLIC_URL + "rem-header-mon.jpg",
		title: "Montreal",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
	{ // Halifax
		src: process.env.PUBLIC_URL + "rem-header-hal.jpg",
		title: "Halifax",
		date: "Yesterday",
		body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
	},
];

testData.forEach(function (item) {
	new Image().src = item.src
});

const randomArticleImages: string[] = [
	process.env.PUBLIC_URL + "rem-article-0.jpg",
	process.env.PUBLIC_URL + "rem-article-1.jpg",
	process.env.PUBLIC_URL + "rem-article-2.jpg",
	process.env.PUBLIC_URL + "rem-article-3.jpg",
	process.env.PUBLIC_URL + "rem-article-4.jpg",
	process.env.PUBLIC_URL + "rem-article-5.jpg",
	process.env.PUBLIC_URL + "rem-article-6.jpg",
	process.env.PUBLIC_URL + "rem-article-7.jpg",
	process.env.PUBLIC_URL + "rem-article-8.jpg",
	process.env.PUBLIC_URL + "rem-article-9.jpg",
];

const RealEstateMarket: React.FunctionComponent<IRealEstateMarketProps> = (props: IRealEstateMarketProps): JSX.Element => {

	const articleSectionRef = useRef(null);
	const [selectedRegion, updateSelectedRegion] = useState(undefined);

	function scrollToRef(ref: any): void {
		const spot: any = ref.current.offsetTop;
		setTimeout(() => {
			window.scrollTo({behavior: "smooth", top: spot - 100});
		}, 250)
	}

	function select(selection: string): void {
		// @ts-ignore
		updateSelectedRegion(selection);
		scrollToRef(articleSectionRef);
	}

	function createNewsItems(items: any[]): ReactNode {
		return items.map((item: any, i: number) => {

			const selected: boolean = item.title === selectedRegion;

			return (
				<Grid
					item={true}
					xs={12}
					sm={12}
					md={6}
					lg={4}
					xl={3}
					onClick={() => select(item.title)}
				>
					<Paper
						style={{
							height: "100%",
							overflow: "hidden",
							borderWidth: 2,
							borderStyle: "solid",
							borderColor: selected ? "black" : "transparent",
						}}
						className="news-item"
					>
						<div
							style={{
								height: 200,
								overflow: "hidden"
							}}
						>
							<img
								src={item.src}
								alt="News"
								style={{
									width: "100%",
									height: "100%",
								}}
								className="news-image"
							/>
						</div>
						<div style={{padding: 15}}>
							<Typography
								style={{
									fontSize: 22,
									fontWeight: "bold",
								}}
							>
								{item.title}
							</Typography>
						</div>
					</Paper>
				</Grid>
			);
		})
	}

	function makeArticles(list: any[]): ReactNode {
		return list.map((item: any, i: number) => {
			return (
				<Grid item xs={12}>
					<RealEstateMarketArticle
						title={`Real Estate Market Article for ${selectedRegion}`}
						body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"}
						imageSource={randomArticleImages[random(0, randomArticleImages.length - 1, )]}
					/>
				</Grid>
			);
		})
	}

	const articleFakeArr: number[] = [];
	let i: number;
	for (i = 0; i < 6; i++) {
		articleFakeArr.push(i);
	}

	return (
		<div>
			<Grid
				container={true}
				spacing={40}
				style={{marginBottom: 15}}
			>
				{createNewsItems(testData)}
			</Grid>

			<div ref={articleSectionRef}>
				{selectedRegion !== undefined && (
					<div style={{marginBottom: 30}}>
						<Typography variant={"h5"} style={{marginBottom: 20}}>
							{`Articles regarding ${selectedRegion}`}
						</Typography>
						<Grid
							container={true}
							spacing={24}
						>
							{makeArticles(articleFakeArr)}
						</Grid>
					</div>
				)}
			</div>
		</div>
	);
};

export default RealEstateMarket;
