import React, {ReactNode, useEffect, useRef, useState} from "react";
import {
	Button, Dialog, DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	TextField,
	Typography,
	withStyles
} from "@material-ui/core";
import {numberWithCommas} from "../services/utils";
import {IStore} from "../services/redux/initialStore";
import {connect} from "react-redux";
import NumberFormat from "react-number-format";
import {withdrawal} from "../services/redux/trading/tradingActions";
import {tradingType} from "../services/redux/trading/TradingReducer";

export interface IWithdrawFundsProps {
	classes?: any;
	currentBalance?: number;
	dispatch?: any;
	errorMessage?: string;
	history?: any;
}

const WithdrawFunds: React.FunctionComponent<IWithdrawFundsProps> = (props: IWithdrawFundsProps): JSX.Element => {

	const code: any = useRef(
		Math.floor(9 * Math.random()) + "" +
		Math.floor(9 * Math.random()) +
		Math.floor(9 * Math.random()) +
		Math.floor(9 * Math.random())
	);

	const {classes} = props;
	const [email, updateEmail] = useState("");
	const [realValueToWithdraw, updateRealValueToWithdraw] = useState();
	const [displayWithdrawValue, updateDisplayWithdrawValue] = useState();
	const [showCodeModal, updateShowCodeModal] = useState(false);

	function handleEmailChange(event: any): void {
		updateEmail(event.target.value);
	}

	function handleDepositInputChange(values: any): void {
		// @ts-ignore
		if (values.floatValue > props.currentBalance) {
			updateDisplayWithdrawValue(props.currentBalance);
			updateRealValueToWithdraw(props.currentBalance);
		} else {
			updateDisplayWithdrawValue(values.formattedValue);
			updateRealValueToWithdraw(values.floatValue);
		}

	}

	const withdrawInputProps: any = {
		label: "Amount",
	};

	async function attemptWithdraw(): Promise<void> {

		const action = await withdrawal(realValueToWithdraw, email);
		await props.dispatch(action);

		if (action.type === tradingType.EDIT_STORE) {
			updateShowCodeModal(true);

		} else {
		}

	}

	function handleDone(): void {
		// @ts-ignore
		props.history.push("/my-portfolio/financial-summary")
	}

	// @ts-ignore
	const currentBalance: string = numberWithCommas(props.currentBalance.toFixed(2));
	const thisWithdraw: string = realValueToWithdraw ? "$" + numberWithCommas(realValueToWithdraw.toFixed(2)) : "";
	// @ts-ignore
	const finalBalance: string = realValueToWithdraw ? "$" + numberWithCommas((props.currentBalance - realValueToWithdraw).toFixed(2)) : "";

	const injectPropsForSafari: any = {
		onTouchTap: handleDone,
	};

	const injectPropsForSafari2: any = {
		onTouchTap: attemptWithdraw,
	};

	return (
		<Paper className={classes.paper}>
			<Dialog
				open={showCodeModal}
				fullWidth={true}
				maxWidth={"sm"}
			>
				<DialogTitle id="customized-dialog-title">
					Withdrawal Successful
				</DialogTitle>

				<Divider light style={{height: 2, marginTop: 10, marginBottom: 10}}/>

				<DialogContent>
					<Typography
						variant="subtitle1"
					>
						We have sent an e-transfer to the email you entered. The answer to the security question is the following 4 digits. Please write this down, as when this pop-up closes, it will no longer be available.
					</Typography>

					<Typography
						variant="h2"
						style={{
							marginTop: 20,
							textAlign: "center",
						}}
					>
						{code.current}
					</Typography>

					<Typography
						variant="subtitle2"
						style={{
							marginTop: 40,
							color: "red"
						}}
					>
						*This is an example of what would happen in the fully released product when trying to withdraw money to your bank account In this demo these are arbitrary values, and no e-transfer or money has actually been sent.
					</Typography>
				</DialogContent>

				<DialogActions style={{marginTop: 20}}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={handleDone}
						{...injectPropsForSafari}
					>
						Done
					</Button>
				</DialogActions>
			</Dialog>

			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold"}}>
					Withdraw Funds
				</Typography>
				<Typography>
					Cash-out money from your available balance, please specify an email address and an e-transfer will be sent to the given address. This will remove the funds specified from your available balance.				</Typography>
				<Divider light style={{height: 2, marginTop: 25, marginBottom: 25}}/>

				<Grid container spacing={24}>
					<Grid container item xs={12} sm={12} md={6}>
						<Grid item xs={12}>
							<TextField
								label="Email Address"
								onChange={handleEmailChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<NumberFormat
								value={displayWithdrawValue}
								onValueChange={handleDepositInputChange}
								thousandSeparator={true}
								allowNegative={false}
								prefix={"$"}
								decimalScale={2}
								customInput={TextField}
								{...withdrawInputProps}
							/>
						</Grid>
					</Grid>


					<Grid container item xs={12} sm={12} md={6}>
						<Grid item container xs={12} style={{marginTop: 30}}>
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									Balance before withdrawing
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{"$" + currentBalance}
								</Typography>
							</Grid>
							{/*</Grid>*/}

							{/*<Grid item container xs={12}>*/}
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									This withdrawal
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{thisWithdraw}
								</Typography>
							</Grid>
							{/*</Grid>*/}

							{/*<Grid item container xs={12}>*/}
							<Grid item xs={6} style={{minHeight: 40}}>
								<Typography>
									Final balance
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.textRight + " " + classes.grayText + " " + classes.balanceInfo}>
									{finalBalance}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{props.errorMessage &&
                <div>
                    <p style={{color: "red"}}>
						{props.errorMessage}
                    </p>
                </div>
				}

				<div style={{marginTop: 30}}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						disabled={!email || !displayWithdrawValue}
						onClick={attemptWithdraw}
						{...injectPropsForSafari2}
					>
						Withdraw Funds
					</Button>
				</div>
			</div>
		</Paper>
	)
};

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	},
	balanceInfo: {
		fontSize: 18,
	},
	textRight: {
		textAlign: "right",
	},
	grayText: {
		color: "gray",
	},
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IWithdrawFundsProps) => {
	return {
		currentBalance: store.tradingStore.users[store.tradingStore.currentUser].availableBalance,
		errorMessage: store.tradingStore.withdrawalError,
		...props,
	}
})(WithdrawFunds));
