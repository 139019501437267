import {IStore} from "../../services/redux/initialStore";
import moment from "moment";
import {IHistory, ISecurity, Listing} from "../../services/redux/trading/tradingStore";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {tableOrder} from "./SecuritiesTable";
import {Paper, Table, TableBody, TableCell, TablePagination, TableRow, withStyles} from "@material-ui/core";
import {getSorting, IColumns, stableSort, styles} from "./tableUtils";
import {numberWithCommas} from "../../services/utils";
import EnhancedTableHead from "./EnhancedTableHead";

enum TransactionAction {
	ACCEPTED_BID = "You accepted a buy order",
	ACCEPTED_OFFER = "You accepted a sell order",
	POSTED_BID = "Your posted buy order was accepted",
	POSTED_OFFER = "Your posted sell order was accepted."
}

interface IRow {
	date: number;
	yourRole?: "lister" | "acceptor";
	listingType: Listing;
	price: number;
	quantity: number;
	total: number;
	security: ISecurity;
	securityID: number;
	aboveMarketValue: boolean;
	action: TransactionAction;
	expirationDate: any;
	priceAtExpiration: any;
}

const roleColumn: IColumns = {
	id: "yourRole",
	numeric: true,
	label: "Your Role",
};

const actionColumn: IColumns = {
	id: "action",
	numeric: true,
	label: "Action",
};

interface IProps {
	allRows?: IRow[],
	userRows?: IRow[],
	showAll?: boolean,
	classes?: any;
}

interface IState {
	order: tableOrder,
	orderBy: string;
	selected: any[];
	page: number;
	rowsPerPage: number;
	showModal: boolean;
	selectedIndex?: number;
}

class HistoryTable extends React.Component<IProps, IState> {

	public static defaultProps: IProps = {
		allRows: [],
		userRows: [],
	};

	private myColumns: IColumns[] = [
		{
			id: "date",
			numeric: true,
			label: "Date",
		},
		{
			id: "listingType",
			numeric: true,
			label: "Listing Type",
		},
		{
			id: "securityID",
			numeric: true,
			label: "Property Type",
		},
		{
			id: "price",
			numeric: true,
			label: "Price",
		},
		{
			id: "aboveMarketValue",
			numeric: true,
			label: "Above / Below Market Value",
		},
		{
			id: "quantity",
			numeric: true,
			label: "Quantity",
		},
		{
			id: "total",
			numeric: true,
			label: "Total",
		},
		{
			id: "expirationDate",
			numeric: true,
			label: "Expiration Date",
		},
		{
			id: "priceAtExpiration",
			numeric: true,
			label: "Price at Expiration",
		},
	];

	constructor(props: IProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			page: 0,
			rowsPerPage: 20,
			showModal: false,
		};

		if (props.showAll !== true) {
			// this.myColumns.splice(1, 0, roleColumn);
			this.myColumns.splice(1, 0, actionColumn);
		}

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
// {/*<TableCell align="right">{row.yourRole}</TableCell>*/}

	private createRows(rows: IRow[]): any {
		return rows.map((row: IRow, i: number) => {

			return (
				<TableRow
					hover
					tabIndex={-1}
					key={"history-table-row-" + `${i}`}
					style={{
						height: 48,
					}}
				>
					<TableCell align="right">{moment(row.date).format("MMM Do YYYY")}</TableCell>

					{!this.props.showAll && (
						<TableCell align="right">{row.action}</TableCell>
					)}

					<TableCell align="right">{row.listingType}</TableCell>

					<TableCell align="right">
						{
							row.security.bedrooms + " Bedroom(s)," + " " +
							row.security.bathrooms + " Bathroom(s)," + "\n" +
							row.security.lotType + ", " +
							row.security.market
						}
					</TableCell>

					<TableCell align="right">{"$" + numberWithCommas(row.price.toFixed(2))}</TableCell>
					<TableCell align="right">{row.aboveMarketValue === true ? "Above" : "Below"}</TableCell>
					<TableCell align="right">{numberWithCommas(row.quantity)}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.total)}</TableCell>

					<TableCell>{moment(row.expirationDate).format("MMM Do YYYY")}</TableCell>

					<TableCell align="right">{"$" + numberWithCommas(row.priceAtExpiration)}</TableCell>
				</TableRow>
			);
		})
	}

	public render(): JSX.Element {

		const {classes, showAll, allRows, userRows} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		// @ts-ignore
		const realData: IRow[] = showAll ? allRows : userRows;

		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, realData.length - page * rowsPerPage);
		const rowsToShow: any = stableSort(realData, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		return (
			<Paper className={classes.root}>
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={this.handleRequestSort}
							rowCount={realData.length}
							columns={this.myColumns}
							includeSpaceForButton={false}
						/>

						<TableBody>
							{rows}

							{emptyRows > 0 && (
								<TableRow style={{height: 48 * emptyRows}}>
									<TableCell colSpan={6}/>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>

				<TablePagination
					rowsPerPageOptions={[20, 40, 60]}
					component="div"
					count={realData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
					onChangePage={(this.changePage as any)}
					onChangeRowsPerPage={(this.changeRowsPerPage as any)}
				/>
			</Paper>
		);
	}
}

function mapStateToProps(store: IStore, props: IProps): IProps {
	const {currentUser, history, securities} = store.tradingStore;

	function mapHistoryToRow({date, listingType, price, quantity, listerID, acceptorID, securityID, aboveMarketValue}: IHistory): IRow {
		const role: any = {};
		if (listerID === currentUser) {
			role.yourRole = "lister";
			if (listingType === Listing.BID) {
				role.action = TransactionAction.POSTED_BID
			} else {
				role.action = TransactionAction.POSTED_OFFER
			}
		} else if (acceptorID === currentUser) {
			role.yourRole = "acceptor";
			if (listingType === Listing.BID) {
				role.action = TransactionAction.ACCEPTED_BID
			} else {
				role.action = TransactionAction.ACCEPTED_OFFER
			}
		}

		return {
			date: moment(date).valueOf(),
			listingType,
			price,
			quantity,
			total: Math.floor(price * quantity * 100) / 100,
			securityID,
			security: store.tradingStore.securities[securityID],
			aboveMarketValue,
			expirationDate: securities[securityID].expirationDate,
			priceAtExpiration: securities[securityID].priceAtExpiration,
			...role
		}
	}

	return {
		allRows: history.map(mapHistoryToRow),
		userRows: history.filter(({listerID, acceptorID}) => [listerID, acceptorID].includes(currentUser)).map(mapHistoryToRow),
		...props
	}
}

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps)(HistoryTable));
