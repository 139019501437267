import React, {ReactNode} from "react";
import {buyColumns, createData, getSorting, ITableRowEntry, numberWithCommas, stableSort, styles} from "./tableUtils";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import EnhancedTableHead from "./EnhancedTableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SellSecuritiesModal from "../modals/SellSecuritiesModal";
import {IOfferFilled, IUser} from "../../services/redux/trading/tradingStore";
import {connect} from "react-redux";
import BuySecuritiesModal from "../modals/BuySecuritiesModal";
import {IStore} from "../../services/redux/initialStore";
import moment from "moment";

export enum tableOrder {
	ASCENDING = "asc",
	DESCENDING = "desc",
}

export enum securitiesTradingTableType {
	BUY = "BUY", // BUY OFFERS (the page where the user can SELL to buy orders / post their own BUY offer)
	SELL = "SELL", // SELL OFFERS (the page where the user can BUY from sell offers / post their own SELL offer)
}

interface ISecuritiesTableProps {
	classes?: any;
	variety: securitiesTradingTableType,
	data: IOfferFilled[],
	dispatch?: any;
}

interface ISecuritiesTableState {
	order: tableOrder,
	orderBy: string; // TODO
	selected: any[];
	// data: any[],
	page: number;
	rowsPerPage: number;
	showModal: boolean;
	selectedIndex?: number;
}

class SecuritiesTable extends React.Component<ISecuritiesTableProps, ISecuritiesTableState> {

	constructor(props: ISecuritiesTableProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			// data: props.data,
			page: 0,
			rowsPerPage: 10,
			showModal: false,
		};

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
		this.openSellSecuritiesModal = this.openSellSecuritiesModal.bind(this);
		this.closeSecurityModal = this.closeSecurityModal.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
	private createRows(rows: IOfferFilled[]): any {
		return rows.map((row: IOfferFilled, i: number) => {

			let injectPropsForSafari: any = {
				onTouchTap: this.openSellSecuritiesModal(row.id),
			};

			return (
				<TableRow
					hover
					tabIndex={-1}
					key={row.id}
					style={{
						height: 69,
					}}
				>
					<TableCell>
						{
							row.security.bedrooms+ " Bedroom(s)," + " " +
							row.security.bathrooms+ " Bathroom(s)," + "\n" +
							row.security.lotType + ", " +
							row.security.market
						}
					</TableCell>
					<TableCell>{moment(row.security.expirationDate).format("MMM Do YYYY")}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.security.priceAtExpiration)}</TableCell>
					<TableCell align="right">{"$" + numberWithCommas(row.security.priceAsOf)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.amount)}</TableCell>
					<TableCell align="right">{"$" + row.price.toFixed(2)}</TableCell>
					<TableCell align="right">{row.aboveMarketValue === true ? "Above" : "Below"}</TableCell>

					<TableCell align="center">
						<Button
							variant="contained"
							color="primary"
							size="medium"
							onClick={this.openSellSecuritiesModal(row.id)}
							{...injectPropsForSafari}
						>
							{this.props.variety === securitiesTradingTableType.BUY ? "Sell Securities" : "Buy Securities"}
						</Button>
					</TableCell>
				</TableRow>
			);
		})
	}

	private openSellSecuritiesModal(id: number): () => void {
		const that: SecuritiesTable = this;

		// find the right offer in the array of offers from props based on the id of the row you click
		const index: number = this.props.data.findIndex((security: any) => security.id === id);

		return () => {
			that.setState({
				selectedIndex: index,
				showModal: true,
			})
		}
	}

	private closeSecurityModal(): void {
		this.setState({
			selectedIndex: undefined,
			showModal: false,
		})
	}

	public render(): JSX.Element {

		const {data, classes} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		const rowsToShow: any = stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		return (
			<React.Fragment>
				{(this.state.selectedIndex !== undefined && this.props.variety === securitiesTradingTableType.BUY) &&
				<SellSecuritiesModal
					open={this.state.showModal}
					quantity={this.props.data[this.state.selectedIndex as number].amount}
					price={this.props.data[this.state.selectedIndex as number].price}
					securityName={this.props.data[this.state.selectedIndex as number].security.name}
					securityID={this.props.data[this.state.selectedIndex as number].securityID}
					offerID={this.props.data[this.state.selectedIndex as number].id}
					onClose={this.closeSecurityModal}
				/>}

				{(this.state.selectedIndex !== undefined && this.props.variety === securitiesTradingTableType.SELL) &&
                <BuySecuritiesModal
                    open={this.state.showModal}
                    quantity={this.props.data[this.state.selectedIndex as number].amount}
                    price={this.props.data[this.state.selectedIndex as number].price}
                    securityName={this.props.data[this.state.selectedIndex as number].security.name}
                    securityID={this.props.data[this.state.selectedIndex as number].securityID}
                    offerID={this.props.data[this.state.selectedIndex as number].id}
                    onClose={this.closeSecurityModal}
                />}

				<Paper className={classes.root}>
					<div className={classes.tableWrapper}>
						<Table className={classes.table} aria-labelledby="tableTitle">
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onRequestSort={this.handleRequestSort}
								rowCount={data.length}
								columns={buyColumns}
							/>

							<TableBody>
								{rows}

								{emptyRows > 0 && (
									<TableRow style={{height: 69 * emptyRows}}>
										<TableCell colSpan={6}/>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>

					<TablePagination
						rowsPerPageOptions={[10, 15, 20]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={(this.changePage as any)}
						onChangeRowsPerPage={(this.changeRowsPerPage as any)}
					/>
				</Paper>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(SecuritiesTable));
