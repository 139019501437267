import {AnyAction, combineReducers, createStore, StoreEnhancer, applyMiddleware, Store} from 'redux'
import initialStore, {IStore} from "./initialStore";
import TradingReducer from "./trading/TradingReducer";
import logger from 'redux-logger';

const reducers = combineReducers({
	tradingStore: TradingReducer,
});

// const persistConfig = {
// 	key: 'root',
// 	storage,
// 	stateReconciler: hardSet,
// };

// const persistedReducer = persistReducer(persistConfig, reducers);


let store: Store<IStore>;
if(process.env.NODE_ENV === 'development') {
	const middleware: StoreEnhancer = applyMiddleware(
		logger,
	);
	store = createStore(reducers, initialStore, middleware);
} else {
	store = createStore(reducers, initialStore);
}

export { store }

export interface IAction<T, P> extends AnyAction {
	type: T;
	payload?: P;
	callback?: () => void;
}