import React, {ReactNode} from "react";
import HistoryTable from "../components/TableView/HistoryTable";
import {Paper, Typography, withStyles} from "@material-ui/core";

export interface IUsersHistoryPageProps {
	classes?: any;
}

const UsersHistoryPage: React.FunctionComponent<IUsersHistoryPageProps> = (props: IUsersHistoryPageProps): JSX.Element => {

	const {classes} = props;

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold"}}>
					Your Trading History
				</Typography>
				<div>
					<HistoryTable showAll={false} key={Math.random()}/>
				</div>
			</div>
		</Paper>
	)
};

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	}
});

export default withStyles(styles)(UsersHistoryPage);
