import {IAction} from "../index";
import {ITradingPayload} from "./tradingActions";
import cloneDeep from "lodash/cloneDeep";
import TradingStore, {ITradingStore} from "./tradingStore";


export enum tradingType {
	EDIT_STORE = "EDIT_STORE",
	SHOW_TRADING_ERROR = "SHOW_TRADING_ERROR",
	SHOW_WITHDRAWAL_ERROR = "SHOW_WITHDRAWAL_ERROR",
	SHOW_DEPOSIT_ERROR = "SHOW_DEPOSIT_ERROR",
	CLEAR_TRADING_ERROR = "CLEAR_TRADING_ERROR",
	CLEAR_WITHDRAWAL_ERROR = "CLEAR_WITHDRAWAL_ERROR",
	CLEAR_DEPOSIT_ERROR = "CLEAR_DEPOSIT_ERROR",
	CHANGE_USER = "CHANGE_USER",
	TOAST_MESSAGE = "TOAST_MESSAGE",
	TOAST_CLEAR = "TOAST_CLEAR",
}

export default function (store: ITradingStore = TradingStore, action: IAction<tradingType, ITradingPayload>): ITradingStore {

	let newStore: ITradingStore = cloneDeep(store);

	switch (action.type) {
		case(tradingType.EDIT_STORE):
			newStore = action.payload.tradingStore;
			delete newStore.tradingError;
			delete newStore.depositError;
			delete newStore.withdrawalError;
			break;
		case(tradingType.SHOW_TRADING_ERROR):
			newStore.tradingError = action.payload;
			break;
		case(tradingType.SHOW_WITHDRAWAL_ERROR):
			newStore.withdrawalError = action.payload;
			break;
		case(tradingType.SHOW_DEPOSIT_ERROR):
			newStore.depositError = action.payload;
			break;
		case(tradingType.CLEAR_TRADING_ERROR):
			delete newStore.tradingError;
			break;
		case(tradingType.CLEAR_DEPOSIT_ERROR):
			delete newStore.depositError;
			break;
		case(tradingType.CLEAR_WITHDRAWAL_ERROR):
			delete newStore.withdrawalError;
			break;
		case(tradingType.TOAST_MESSAGE):
			newStore.toastMessage = action.payload;
			break;
		case(tradingType.TOAST_CLEAR):
			delete newStore.toastMessage;
			break;
		case(tradingType.CHANGE_USER):
			newStore.currentUser = action.payload;
			break;
		default:
	}

	return newStore;
}