import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import * as React from "react";
import {Paper, Typography, withStyles} from "@material-ui/core";
import SecuritiesIssuedTable from "../components/TableView/SecuritiesIssuedTable";

export interface IListOfSecuritiesProps {
	classes?: any;
}

const ListOfSecurities: React.FunctionComponent<IListOfSecuritiesProps> = (props: IListOfSecuritiesProps): JSX.Element => {

	const {classes} = props;

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography style={{fontSize: 24, fontWeight: "bold"}}>
					Securities
				</Typography>
				<div>
					<SecuritiesIssuedTable/>
				</div>
			</div>
		</Paper>
	);
};

const styles = theme => ({
	paper: {
		maxWidth: 1100,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	}
});

export default withStyles(styles)(connect((store: IStore, props: IListOfSecuritiesProps) => {

	return {
		...props,
	}

})(ListOfSecurities));
