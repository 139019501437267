import React, {ReactNode} from "react";
import {
	getSorting,
	portfolioTableColumns,
	stableSort,
	styles
} from "./tableUtils";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import EnhancedTableHead from "./EnhancedTableHead";
import TableRow from "@material-ui/core/TableRow";
import {IOfferFilled, IUser} from "../../services/redux/trading/tradingStore";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/initialStore";
import {numberWithCommas} from "../../services/utils";
import moment from "moment";

export enum tableOrder {
	ASCENDING = "asc",
	DESCENDING = "desc",
}

interface IPortfolioTableProps {
	classes?: any;
	data: IOfferFilled[],
	dispatch?: any;
}

interface IPortfolioTableState {
	order: tableOrder,
	orderBy: string; // TODO
	selected: any[];
	// data: any[],
	page: number;
	rowsPerPage: number;
	showModal: boolean;
	selectedIndex?: number;
}

class PortfolioTable extends React.Component<IPortfolioTableProps, IPortfolioTableState> {

	constructor(props: IPortfolioTableProps) {
		super(props);
		this.state = {
			order: tableOrder.ASCENDING,
			orderBy: "",
			selected: [],
			// data: props.data,
			page: 0,
			rowsPerPage: 10,
			showModal: false,
		};

		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.changePage = this.changePage.bind(this);
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
	}

	/**
	 * Handles sorting the table when clicking the different columns.
	 * Toggles ascending / descending if clicking the same column
	 * as is currently sorting by.
	 *
	 * @param event
	 * @param property
	 */
	private handleRequestSort(event: any, property: string): void {
		const orderBy: string = property;
		let order: tableOrder = tableOrder.DESCENDING;

		if (this.state.orderBy === property && this.state.order === tableOrder.DESCENDING) {
			order = tableOrder.ASCENDING;
		}

		this.setState({
			order,
			orderBy,
		});
	}

	/**
	 * Updates the page currently being viewed of the table
	 *
	 * @param event
	 * @param page
	 */
	private changePage(event: React.MouseEvent<HTMLButtonElement>, page: number): void {
		this.setState({
			page,
		});
	}

	/**
	 * Update the number of rows to show per page of the table
	 *
	 * @param event
	 */
	private changeRowsPerPage(event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) {
		this.setState({
			rowsPerPage: (event as any).target.value,
		})
	}

	/**
	 * Creates the rows of the table
	 *
	 * @param rows
	 */
	private createRows(rows: any[]): any {
		return rows.map((row: any, i: number) => {
			return (
				<TableRow
					hover
					tabIndex={-1}
					key={row.id}
					style={{
						height: 69,
						whiteSpace: "pre",
						lineHeight: 1.7,
					}}
				>
					<TableCell>{row.name}</TableCell>
					<TableCell>{moment(row.expirationDate).format("MMM Do YYYY")}</TableCell>

					<TableCell align="right">{"$" + numberWithCommas(row.priceAtExpiration)}</TableCell>

					<TableCell align="right">{numberWithCommas(row.amountAboveOwned)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.amountBelowOwned)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.amountAboveListed)}</TableCell>
					<TableCell align="right">{numberWithCommas(row.amountBelowListed)}</TableCell>
					<TableCell align="right"><b>{numberWithCommas(row.amt)}</b></TableCell>
					<TableCell align="right"><b>{(row.totalShare * 100).toFixed(2) + "%"}</b></TableCell>
				</TableRow>
			);
		})
	}

	public render(): JSX.Element {

		const {data, classes} = this.props;
		const {order, orderBy, selected, rowsPerPage, page} = this.state;
		const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		const rowsToShow: any = stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		const rows: ReactNode = this.createRows(rowsToShow);

		return (
			<React.Fragment>
				<Paper className={classes.root}>
					<div className={classes.tableWrapper}>
						<Table className={classes.table} aria-labelledby="tableTitle">
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onRequestSort={this.handleRequestSort}
								rowCount={data.length}
								columns={portfolioTableColumns}
								includeSpaceForButton={false}
							/>

							<TableBody>
								{rows}

								{emptyRows > 0 && (
									<TableRow style={{height: 69 * emptyRows}}>
										<TableCell colSpan={6}/>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>

					<TablePagination
						rowsPerPageOptions={[10, 15, 20]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={(this.changePage as any)}
						onChangeRowsPerPage={(this.changeRowsPerPage as any)}
					/>
				</Paper>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(PortfolioTable));
