import * as React from "react";
import {
	Button,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle, Divider, FilledInput, FormControl,
	FormHelperText, Grid, InputLabel,
	MenuItem, OutlinedInput,
	Select, TextField,
	Typography,
	withStyles
} from "@material-ui/core";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/initialStore";
import {ISecurity, IUser} from "../../services/redux/trading/tradingStore";
import {ReactNode} from "react";
import {listBuyOffer, removeTradingError} from "../../services/redux/trading/tradingActions";
import {numberWithCommas} from "../../services/utils";

interface IPostBuyOfferModalProps {
	open: boolean;
	onClose: () => void;
	error?: string;
	classes?: any;
	user?: IUser;
	allSecurities: { [key: number]: ISecurity },
	dispatch?: any;
}

interface IPostBuyOfferModalState {
	selectedSecurity: any;
	quantity: any;
	dollarPerSecurity: any;
	centsPerSecurity: any;
	pricePerSecurity: any;
	above: any;
	priceInputDisplayValue: any; // starts at "0.00"
	showConfirmModal: boolean;
}

class PostBuyOfferModal extends React.Component<IPostBuyOfferModalProps, IPostBuyOfferModalState> {

	constructor(props: IPostBuyOfferModalProps) {
		super(props);
		this.state = {
			...this.state,
			selectedSecurity: "PLACEHOLDER", // TODO so this is dumb, the select component will not accept empty string to show the default for some reason. Really dumb.
			quantity: "1",
			dollarPerSecurity: "",
			centsPerSecurity: "",
			pricePerSecurity: null,
			above: "Above",
			priceInputDisplayValue: "0.00",
			showConfirmModal: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.determineQuantityOfSelectedSecurity = this.determineQuantityOfSelectedSecurity.bind(this);
		this.updateQuantity = this.updateQuantity.bind(this);
		this.updateDollarPerSecurity = this.updateDollarPerSecurity.bind(this);
		this.updateCentsPerSecurity = this.updateCentsPerSecurity.bind(this);
		this.determineRealPrice = this.determineRealPrice.bind(this);
		this.determineIfShowBottomSection = this.determineIfShowBottomSection.bind(this);
		this.prepareConfirmationModal = this.prepareConfirmationModal.bind(this);
		this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
		this.attemptConfirm = this.attemptConfirm.bind(this);
		this.prepareCloseModal = this.prepareCloseModal.bind(this);
		this.resetModalInfo = this.resetModalInfo.bind(this);
		this.handleAbove = this.handleAbove.bind(this);
		this.getRealQuantity = this.getRealQuantity.bind(this);
	}

	private handleChange(event: any): void {
		this.setState({
			selectedSecurity: event.target.value,
		});
	}

	private handleAbove(event: any): void {
		this.setState({
			above: event.target.value,
		});
	}

	private createSecurityDropDowns(securities: ISecurity[]): ReactNode {
		return securities.map((security: ISecurity, i: number) => {
			return (
				<MenuItem value={security.name}>{security.name}</MenuItem>
			);
		})
	}

	private determineQuantityOfSelectedSecurity(): any {
		if (this.state.selectedSecurity === "PLACEHOLDER") { // should never be true, but just in case
			return {};
		}

		const values: any[] = Object.values(this.props.allSecurities);
		let selectedSecurityIndex: any;

		let index: string;
		for (index in this.props.allSecurities) {
			if (this.props.allSecurities.hasOwnProperty(index)) {
				if (this.props.allSecurities[index].name === this.state.selectedSecurity) {
					selectedSecurityIndex = index;
					break;
				}
			}
		}

		return {
			// @ts-ignore
			available: this.props.user.securities[selectedSecurityIndex].amountOwned,
			// @ts-ignore
			availableAbove: this.props.user.securities[selectedSecurityIndex].amountAboveOwned,
			// @ts-ignore
			availableBelow: this.props.user.securities[selectedSecurityIndex].amountBelowOwned,
			// @ts-ignore
			listed: this.props.user.securities[selectedSecurityIndex].amountListed,
		}
	}

	private updateQuantity(event: any): void {
		const digitRegex: RegExp = RegExp("^[0-9]*$");
		const newVal: string = event.target.value;

		if (event.target.value.length > 8) {
			return;
		}

		this.setState({
			quantity: digitRegex.test(newVal) ? newVal : this.state.quantity,
		});
	}

	private updateDollarPerSecurity(event: any): void {
		const digitRegex: RegExp = RegExp("^[0-9]*$");
		const newVal: string = event.target.value;

		this.setState({
			dollarPerSecurity: digitRegex.test(newVal) ? newVal : this.state.dollarPerSecurity,
		});
	}

	private updateCentsPerSecurity(event: any): void {

		// grab the value that was typed
		let newVal: any = event.target.value;

		// get the new digit
		let newDigit: any = newVal.substring(4);

		// handle pasting or if somehow two key events manage to fire at once?
		if (newDigit.length > 1) {
			return;
		}

		const digitRegex: RegExp = RegExp("^[0-9]*$");
		if (!digitRegex.test(newDigit)) {
			return;
		}

		let deleting: boolean = false;
		if (newDigit === "") {
			deleting = true;
		}

		// multiple it by 10
		let multied: any;

		if (!deleting) {
			multied = (newVal * 10).toFixed(2);
		} else {
			multied = (newVal / 10).toFixed(2);
		}

		// assign the new actual data value to match
		let newCentsPerSecurity: any = multied * 100;

		// if multiplied amount is greater than 1, lower it to 1.00 and also the real data value back down to 100
		if (multied >= 1.00) {
			multied = (1).toFixed(2);
			newCentsPerSecurity = 100;
		}

		this.setState({
			centsPerSecurity: newCentsPerSecurity,
			priceInputDisplayValue: multied,
		}, () => {
		});
	}

	private determineRealPrice(): number {
		// const combinedString: string = this.state.dollarPerSecurity + "." + this.state.centsPerSecurity;

		// const parsedPrice: number = parseFloat(combinedString);
		const parsedPrice: number = this.state.centsPerSecurity / 100;

		return parsedPrice * (this.state.quantity * 1000);
	}

	private determineIfShowBottomSection(): boolean {
		let determineShow: boolean = true;

		if (!this.state.quantity) {
			determineShow = false;
		}

		if (isNaN(this.determineRealPrice())) {
			determineShow = false;
		}

		if (this.state.selectedSecurity === "PLACEHOLDER") {
			determineShow = false;
		}

		if (!determineShow) {
			if (this.props.error) {
				// this.props.dispatch(removeTradingError());
			}
			return false;
		} else {
			return true;
		}
	}

	private prepareConfirmationModal(): void {
		this.setState({showConfirmModal: true});
	}

	private closeConfirmationModal(): void {
		this.setState({showConfirmModal: false});
	}

	private async attemptConfirm(): Promise<void> {
		let selectedSecurityIndex: any;

		let index: string;
		for (index in this.props.allSecurities) {
			if (this.props.allSecurities.hasOwnProperty(index)) {
				if (this.props.allSecurities[index].name === this.state.selectedSecurity) {
					selectedSecurityIndex = index;
					break;
				}
			}
		}

		const combinedString: string = this.state.dollarPerSecurity + "." + this.state.centsPerSecurity;
		// const parsedPrice: number = parseFloat(combinedString);
		const parsedPrice: number = this.state.centsPerSecurity / 100;

		await this.props.dispatch(listBuyOffer(selectedSecurityIndex, parseInt(this.state.quantity, 10) * 1000, parsedPrice, this.state.above === "Above"));

		this.setState({
			showConfirmModal: false,
		}, () => {
			if (!this.props.error) {
				this.resetModalInfo(this.props.onClose);
			}
		});
	}

	private async prepareCloseModal(): Promise<void> {
		await this.props.dispatch(removeTradingError());
		this.resetModalInfo(this.props.onClose);
	}

	/**
	 * Reset information currently in the state when closed
	 * to prevent the form from looking pseudo-complete when it
	 * opens again. The forms are automatically cleared on their own.
	 *
	 */
	private resetModalInfo(callback: () => void = (): void => {
	}): void {
		this.setState({
			selectedSecurity: "PLACEHOLDER",
			quantity: null,
			dollarPerSecurity: null,
			centsPerSecurity: null,
			pricePerSecurity: null,
			priceInputDisplayValue: "0.00",
		}, callback)
	}

	private getRealQuantity(): string {
		const realQuantity: number = parseInt(this.state.quantity, 10) * 1000;
		if (isNaN(realQuantity)) {
			return "0";
		} else {
			return numberWithCommas(realQuantity);
		}
	}

	public render(): JSX.Element {

		const selectionOptions: any = this.createSecurityDropDowns(Object.values(this.props.allSecurities));
		const selectedSecurityUserInfo: any = this.determineQuantityOfSelectedSecurity();

		const showBottomSection: boolean = this.determineIfShowBottomSection();
		const realPrice: any = this.determineRealPrice();

		// @ts-ignore
		const availableBalance: any = this.props.user.availableBalance;

		const injectPropsForSafari: any = {
			onTouchTap: this.attemptConfirm,
		};

		const injectPropsForSafari2: any = {
			onTouchTap: this.closeConfirmationModal,
		};

		const injectPropsForSafari3: any = {
			onTouchTap: this.prepareCloseModal,
		};

		const injectPropsForSafari4: any = {
			onTouchTap: this.prepareConfirmationModal,
		};

		const realQuantity: string = this.getRealQuantity();

		return (
			<React.Fragment>
				<Dialog
					open={this.props.open}
					onClose={this.prepareCloseModal}
					fullWidth={true}
					maxWidth={"sm"}
				>
					<DialogTitle id="customized-dialog-title">
						Post Buy Order
					</DialogTitle>

					<DialogContent>
						<Grid container spacing={24}>
							<Grid item xs={12} sm={12} md={12}>
								<Typography style={{fontSize: 16}}>
									Select Security
								</Typography>
								<Grid container spacing={8}>

									<Grid item xs={12}>
										<FormControl className={this.props.classes.formControl} style={{width: "90%"}}>
											<Select
												value={this.state.selectedSecurity}
												onChange={this.handleChange}
												name="selectedSecurity"
												className={this.props.classes.selectEmpty}
												placeholder={"Test 22"}
												input={
													<OutlinedInput
														labelWidth={0}
														name="age"
														id="outlined-age-simple"
														placeholder={"test"}
														style={{
															marginTop: 7,
															color: this.state.selectedSecurity === "PLACEHOLDER" ? "#7c7c7c" : "black"
														}}
													/>
												}
											>
												<MenuItem value="PLACEHOLDER" disabled>
													Security
												</MenuItem>
												{selectionOptions}
											</Select>
										</FormControl>

										{/*{this.state.selectedSecurity !== "PLACEHOLDER" &&*/}
										{/*<div>*/}
										{/*<Typography style={{color: "gray", fontStyle: "italic"}}>*/}
										{/*You have {selectedSecurityUserInfo.available} of this security*/}
										{/*available. ({selectedSecurityUserInfo.listed} currently listed).*/}
										{/*</Typography>*/}
										{/*</div>*/}
										{/*}*/}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography style={{fontSize: 16}}>
									Market Value
								</Typography>
								<Grid container spacing={8}>

									<Grid item xs={12}>
										<FormControl className={this.props.classes.formControl}>
											<Select
												value={this.state.above}
												onChange={this.handleAbove}
												name="above"
												className={this.props.classes.selectEmpty}
												placeholder={"Above/Below"}
												input={
													<OutlinedInput
														labelWidth={0}
														name="age"
														id="outlined-age-simple"
														placeholder={"test"}
														style={{
															marginTop: 7,
															color: this.state.selectedSecurity === "PLACEHOLDER" ? "#7c7c7c" : "black"
														}}
													/>
												}
											>
												<MenuItem value="PLACEHOLDER" disabled>
													Above/Below
												</MenuItem>
												<MenuItem value="Above">Above</MenuItem>
												<MenuItem value="Below">Below</MenuItem>
											</Select>
										</FormControl>

									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography style={{fontSize: 16}}>
									Enter Quantity
								</Typography>

								<Grid container spacing={8} xs={12}>

									<Grid item xs={12} sm={5}>
										<FormControl className={this.props.classes.formControl}>
											<TextField
												style={{marginTop: 7}}
												label="Quantity"
												type="tel"
												margin="normal"
												variant="outlined"
												value={this.state.quantity}
												onChange={this.updateQuantity}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12} sm={7}>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												height: "100%"
											}}
										>
											<div
												style={{
													color: "gray",
													fontSize: 20,
												}}
											>
												{"x 1000 = "}
											</div>
											<div
												style={{
													fontSize: 20,
													marginLeft: 5,
												}}
											>
												{realQuantity}
											</div>
										</div>
									</Grid>

								</Grid>
							</Grid>

							<Grid item xs={12} sm={6} md={5}>
								<Typography style={{fontSize: 16}}>
									Enter Price Per Security
								</Typography>

								<Grid container spacing={8} xs={12}>
									<Grid item xs={2}>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												height: "100%"
											}}
										>
											<div
												style={{
													color: "gray",
													fontSize: 20,
												}}
											>
												{"$"}
											</div>
										</div>
									</Grid>
									<Grid item xs={5}>
										<TextField
											label="Cents"
											type="tel"
											margin="normal"
											variant="outlined"
											placeholder="00"
											value={this.state.priceInputDisplayValue}
											onChange={this.updateCentsPerSecurity}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<div>
							<Divider light style={{height: 2, marginTop: 10, marginBottom: 10}}/>

							{showBottomSection &&
                            <div>
                                <div>
                                    <p
                                        style={{
											textAlign: "center",
											fontSize: 28,
											color: "black",
										}}
                                    >
                                        Investing
                                        ${numberWithCommas(realPrice.toFixed(2))} in {numberWithCommas(this.state.quantity * 1000)} x {(this.state.above === "Above" ? "above" : "below") + " market value "} {this.state.selectedSecurity}
                                    </p>
                                </div>

                                <div>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <div style={style.transactionInfoRow}>
                                                <Typography>
                                                    Balance before posting
                                                </Typography>
                                                <Typography style={style.rightSideBalances}>
													{"$"}{numberWithCommas(availableBalance)}
                                                </Typography>
                                            </div>
                                            <div style={style.transactionInfoRow}>
                                                <Typography style={{color: "gray"}}>
                                                    This buy order
                                                </Typography>
                                                <Typography style={{...style.rightSideBalances, color: "gray"}}>
                                                    -{"$"}{numberWithCommas(realPrice.toFixed(2))}
                                                </Typography>
                                            </div>
                                            <Grid container>
                                                <Grid item xs={9}/>
                                                <Grid item xs={3}>
                                                    <Divider/>
                                                </Grid>
                                            </Grid>
                                            <div style={{...style.transactionInfoRow, marginTop: 10}}>
                                                <Typography style={{fontSize: 16}}>
                                                    Balance after posting
                                                </Typography>
                                                <Typography style={{...style.rightSideBalances, fontSize: 16}}>
													{"$"}{numberWithCommas(parseInt((availableBalance - realPrice).toFixed(2)))}
                                                </Typography>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <div style={style.transactionInfoRow}>
                                                <Typography>
                                                    Your quantity of {(this.state.above === "Above" ? "above" : "below") + " market value " + this.state.selectedSecurity} before posting
                                                </Typography>
                                                <Typography style={style.rightSideBalances}>
													{numberWithCommas(this.state.above === "Above" ? selectedSecurityUserInfo.availableAbove : selectedSecurityUserInfo.availableBelow)}
                                                </Typography>
                                            </div>
                                            <div style={style.transactionInfoRow}>
                                                <Typography style={{color: "gray"}}>
                                                    This buy order
                                                </Typography>
                                                <Typography style={{...style.rightSideBalances, color: "gray"}}>
													{!isNaN(parseInt(this.state.quantity as string, 10)) ? "+ " + numberWithCommas(this.state.quantity * 1000) : "/"}
                                                </Typography>
                                            </div>
                                            <Grid container>
                                                <Grid item xs={9}/>
                                                <Grid item xs={3}>
                                                    <Divider/>
                                                </Grid>
                                            </Grid>
                                            <div style={{...style.transactionInfoRow, marginTop: 10}}>
                                                <Typography style={{fontSize: 16}}>
                                                    Quantity of {(this.state.above === "Above" ? "above" : "below") + " market value " + this.state.selectedSecurity} when buy order completes
                                                </Typography>
                                                <Typography style={{...style.rightSideBalances, fontSize: 16}}>
													{!isNaN(parseInt(this.state.quantity as string, 10)) ? numberWithCommas(((parseInt(this.state.quantity, 10) * 1000) + parseInt(this.state.above === "Above" ? selectedSecurityUserInfo.availableAbove : selectedSecurityUserInfo.availableBelow, 10))) : "/"}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
									{this.props.error &&
                                    <div>
                                        <p style={{color: "red"}}>
											{this.props.error}
                                        </p>
                                    </div>
									}
                                </div>
                            </div>
							}

							<DialogActions style={{marginTop: 20}}>
								<Button
									color="primary"
									size="large"
									onClick={this.prepareCloseModal}
									{...injectPropsForSafari3}
								>
									Cancel
								</Button>
								<Button
									disabled={!showBottomSection}
									variant="contained"
									color="primary"
									size="large"
									onClick={this.prepareConfirmationModal}
									{...injectPropsForSafari4}
								>
									Confirm
								</Button>
							</DialogActions>
						</div>
					</DialogContent>
				</Dialog>


				<Dialog
					open={this.state.showConfirmModal}
					fullWidth={true}
					maxWidth={"sm"}
				>
					<DialogTitle id="customized-dialog-title">
						Are you sure you want to post this buy order?
					</DialogTitle>

					<Divider light style={{height: 2, marginTop: 10, marginBottom: 10}}/>

					<DialogContent>
						{showBottomSection && (
							<div>
								<div>
									<p
										style={{
											textAlign: "center",
											fontSize: 28,
											color: "black",
										}}
									>
										Investing
										${numberWithCommas(realPrice.toFixed(2))} in {numberWithCommas(this.state.quantity * 1000)} x {(this.state.above === "Above" ? "above" : "below") + " market value "} {this.state.selectedSecurity}
									</p>
								</div>

								<div>
									<Grid container spacing={24}>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div style={style.transactionInfoRow}>
												<Typography>
													Balance before posting
												</Typography>
												<Typography style={style.rightSideBalances}>
													{"$"}{numberWithCommas(availableBalance)}
												</Typography>
											</div>
											<div style={style.transactionInfoRow}>
												<Typography style={{color: "gray"}}>
													This buy order
												</Typography>
												<Typography style={{...style.rightSideBalances, color: "gray"}}>
													+{"$"}{numberWithCommas(realPrice.toFixed(2))}
												</Typography>
											</div>
											<Grid container>
												<Grid item xs={9}/>
												<Grid item xs={3}>
													<Divider/>
												</Grid>
											</Grid>
											<div style={{...style.transactionInfoRow, marginTop: 10}}>
												<Typography style={{fontSize: 16}}>
													Balance after offer completes
												</Typography>
												<Typography style={{...style.rightSideBalances, fontSize: 16}}>
													{"$"}{numberWithCommas((availableBalance + realPrice).toFixed(2))}
												</Typography>
											</div>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div style={style.transactionInfoRow}>
												<Typography>
													Your quantity of {(this.state.above === "Above" ? "above" : "below") + " market value " + this.state.selectedSecurity} before posting
												</Typography>
												<Typography style={style.rightSideBalances}>
													{numberWithCommas(this.state.above === "Above" ? selectedSecurityUserInfo.availableAbove : selectedSecurityUserInfo.availableBelow)}
												</Typography>
											</div>
											<div style={style.transactionInfoRow}>
												<Typography style={{color: "gray"}}>
													This buy order
												</Typography>
												<Typography style={{...style.rightSideBalances, color: "gray"}}>
													{!isNaN(parseInt(this.state.quantity as string, 10)) ? "+ " + numberWithCommas(this.state.quantity * 1000) : "/"}
												</Typography>
											</div>
											<Grid container>
												<Grid item xs={9}/>
												<Grid item xs={3}>
													<Divider/>
												</Grid>
											</Grid>
											<div style={{...style.transactionInfoRow, marginTop: 10}}>
												<Typography style={{fontSize: 16}}>
													Quantity of {(this.state.above === "Above" ? "above" : "below") + " market value " + this.state.selectedSecurity} when buy order completes
												</Typography>
												<Typography style={{...style.rightSideBalances, fontSize: 16}}>
													{!isNaN(parseInt(this.state.quantity as string, 10)) ? numberWithCommas((parseInt(this.state.above === "Above" ? selectedSecurityUserInfo.availableAbove : selectedSecurityUserInfo.availableBelow, 10) + (parseInt(this.state.quantity, 10) * 1000))) : "/"}
												</Typography>
											</div>
										</Grid>
									</Grid>
								</div>
							</div>
						)}
					</DialogContent>

					<DialogActions style={{marginTop: 20}}>
						<Button
							color="primary"
							size="large"
							onClick={this.closeConfirmationModal}
							{...injectPropsForSafari2}
						>
							Cancel
						</Button>
						<Button
							disabled={!showBottomSection}
							variant="contained"
							color="primary"
							size="large"
							onClick={this.attemptConfirm}
							{...injectPropsForSafari}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}

}

const style: any = {
	transactionInfoRow: {
		display: "flex",
		justifyContent: "space-between",
	},
	rightSideBalances: {
		marginLeft: 20,
	}
};

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2,
	},
	textInputPriceDecorationLayout: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	textInputPriceEntryDecorationStrings: {
		fontSize: 32,
		color: "gray",
		// marginTop: 15,
	}
});

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IPostBuyOfferModalProps) => {
	return {
		user: store.tradingStore.users[store.tradingStore.currentUser],
		allSecurities: store.tradingStore.securities,
		error: store.tradingStore.tradingError,
		...props,
	}
})(PostBuyOfferModal));
