import createDefaultTradingStore from "./createDefaultTradingStore";

export interface ITradingStore {
	sellOffers: { [key: number]: IOffer };
	buyOffers: { [key: number]: IOffer };
	users: { [key: number]: IUser },
	securities: { [key: number]: ISecurity },
	userIncrement: number;
	sellOfferIncrement: number;
	buyOfferIncrement: number;
	securitiesIncrement: number;
	currentUser: number;
	tradingError?: string;
	withdrawalError?: string;
	depositError?: string;
	history: IHistory[],
	bidHistory: {[key: number]: IPostHistory},
	offerHistory: {[key: number]: IPostHistory},
	toastMessage?: string;
	firstTimePages: string[];
}

export interface IPostHistory extends IOffer {
	lookupID: number;
	initialAmount: number;
	active: boolean;
	date: string; // ISO string
}

export interface IOffer {
	userID: number,
	securityID: number,
	amount: number,
	price: number,
	aboveMarketValue: boolean,
	priceAsOf: number,
	listingType: Listing,
}

export interface IOfferFilled {
	user: IUser,
	security: ISecurity,
	userID: number,
	securityID: number,
	amount: number,
	price: number,
	id: number;
	aboveMarketValue: boolean,
	priceAsOf: number,
	priceAtExpiration: number;
}

export interface ISecuritiesType {
	[key: number]: {
		amountOwned: number,
		amountListed: number,
		amountAboveOwned: number,
		amountAboveListed: number,
		amountBelowOwned: number,
		amountBelowListed: number,
	}
}

export interface IUser {
	deposits: number[],
	withdrawals: number[],
	availableBalance: number,
	investedBalance: number,
	securities: ISecuritiesType,
	firstName: string,
	lastName: string,
	photoURL: string,
	email: string,
	phoneNumber: string,
	address: {
		city: string,
		street: string,
		postalCode: string,
	},
	id: number,
}

export interface ISecurity {
	name: string,
	description?: string,
	bathrooms: number,
	bedrooms: number,
	market: Market,
	lotType: LotType,
	priceAsOf: number,
	priceAtExpiration: number,
	id: number,
	totalIssued: number,
	aboveIssued: number,
	belowIssued: number,
	expirationDate: string, // ISO string
	issueDate: string, // ISO string
}

export enum Listing {
	BID = "buy order",
	OFFER = "sell order",
}

export interface IHistory {
	date: string, // ISO string
	listingType: Listing,
	listerID: number,
	acceptorID: number,
	quantity: number,
	price: number,
	securityID: number,
	aboveMarketValue: boolean,
}

export enum Market {
	NORTH_VANCOUVER = "North Vancouver",
	VANCOUVER_WEST_SIDE = "Vancouver West Side",
	VANCOUVER_EAST_SIDE = "Vancouver East Side",
	BURNABY = "Burnaby",
	RICHMOND = "Richmond",
	NEW_WESTMINSTER = "New Westminster",
}


export enum LotType {
	DETACHED_LOT = "Detached",
	STANDARD_LOT = "Apartment",
}

const possiblePrices: number[] = [];
let price;
for (price = 0.01; price <= 1.00; price = Math.round( (price + 0.01) * 100 ) / 100) {
	possiblePrices.push(price);
}
export {possiblePrices}

const possibleAmounts: number[] = [];
let amount;
for (amount = 1000; amount < 15000; amount += 1000) {
	possibleAmounts.push(amount);
}
export {possibleAmounts}

export const possibleMarkets = Object.keys(Market).map(key => Market[key]);

export const possibleAboveMarketValues = [true, false];

export const bedroomPrice = 92500;
export const bathroomPrice = 45000;
export const lotTypePrice = {
	[LotType.DETACHED_LOT]: 800000,
	[LotType.STANDARD_LOT]: 600000,
};
export const marketPrice = {
	[Market.NORTH_VANCOUVER]: 600000,
	[Market.VANCOUVER_WEST_SIDE]: 500000,
	[Market.VANCOUVER_EAST_SIDE]: 457000,
	[Market.BURNABY]: 300000,
	[Market.RICHMOND]: 265000,
	[Market.NEW_WESTMINSTER]: 100000,
};
export const marketGrowth = {
	[Market.NORTH_VANCOUVER]: 1.05,
	[Market.VANCOUVER_WEST_SIDE]: 1.02,
	[Market.VANCOUVER_EAST_SIDE]: 0.95,
	[Market.BURNABY]: 0.98,
	[Market.RICHMOND]: 1.03,
	[Market.NEW_WESTMINSTER]: 1.01,
};
export const minuteIncrement = 15;
export const numberOfOffers = Math.floor( 24 * (60 / minuteIncrement) * 32); // 32 days of trades of 10 minute increments

export default createDefaultTradingStore();
