import React from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	FormControl,
	InputLabel,
	Input,
	Select,
	DialogActions
} from "@material-ui/core";
import {connect} from "react-redux";
import {IStore} from "../services/redux/initialStore";
import {changeUser, resetStore} from "../services/redux/trading/tradingActions";
import Typography from "@material-ui/core/Typography";

interface IProps {
	classes: any,
	currentUserID: number,
	userIDs: number[],
	dispatch: any,
	store: IStore
}

function createUserItem(id: number): JSX.Element {
	return (<option key={`user_${id}`} value={id}>User #{id}</option>)
}

function AdminPortal(props: IProps) {
	const {classes, store} = props;

	const [state, setState] = React.useState({
		open: false,
	});

	const handleChange = event => {
		props.dispatch(changeUser(parseFloat(event.target.value)));
		handleClose();
	};

	function reset() {
		props.dispatch(resetStore());
	}

	function handleClickOpen() {
		setState({...state, open: true});
	}

	function handleClose() {
		setState({...state, open: false});
	}

	function logRedux() {
		alert("check the console");
	}

	const injectPropsForSafari: any = {
		onTouchTap: reset,
	};

	const injectPropsForSafari2: any = {
		onTouchTap: handleClickOpen,
	};

	const injectPropsForSafari3: any = {
		onTouchTap: logRedux,
	};

	const injectPropsForSafari4: any = {
		onTouchTap: handleClose,
	};

	return (
		<Paper className={classes.paper}>
			<div className={classes.contentWrapper}>
				<Typography>{description}</Typography>
				<Button onClick={reset} color="primary" {...injectPropsForSafari}>
					Reset Store
				</Button>
				<Button onClick={handleClickOpen} color="secondary" {...injectPropsForSafari2}>
					Open user select dialog (User #{props.currentUserID})
				</Button>
				<Button onClick={logRedux} color="default" {...injectPropsForSafari3}>
					Log Database
				</Button>
				{/*<div style={{height: 500, overflow: "auto"}}>*/}
				{/*	<Typography><pre>{JSON.stringify(props.store, null, 2)}</pre></Typography>*/}
				{/*</div>*/}
				<Dialog disableBackdropClick disableEscapeKeyDown open={state.open} onClose={handleClose}>
					<DialogTitle>Select a User</DialogTitle>
					<DialogContent>
						<form className={classes.container}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="user-id">User ID</InputLabel>
								<Select
									native
									value={props.currentUserID}
									onChange={handleChange}
									input={<Input id="user-id"/>}
								>
									{props.userIDs.map(createUserItem)}
								</Select>
							</FormControl>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary" {...injectPropsForSafari4}>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</Paper>
	);
}

const styles = theme => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

const description = "This page can be used to reset the data or changing users, via the two buttons below. This page is strictly created for demo purposes and ease of use and would not be present in commercialized versions of this product. In the case where the site is crashing or you have experienced a bug that is crashing. Try typing the url of this page into the address bar and pressing the Reset Store button. If this doesn't work, you may clear the cache and cookies in your browser.";

// @ts-ignore
export default withStyles(styles)(connect((store: IStore, props: IProps) => {
	return {
		currentUserID: store.tradingStore.currentUser,
		userIDs: Object.keys(store.tradingStore.users),
		store,
		...props
	}
})(AdminPortal));
