import {Grid, Paper, Tooltip, Typography} from "@material-ui/core";
import * as React from "react";

export interface IRealEstateMarketArticleProps {
	imageSource?: string;
	title: string;
	body: string;
	tooltipTitle?: string;
}

const RealEstateMarketArticle: React.FunctionComponent<IRealEstateMarketArticleProps> = (props: IRealEstateMarketArticleProps): JSX.Element => {

	const {imageSource, title, body, tooltipTitle} = props;

	return (
		<Tooltip
			title={tooltipTitle}
			placement="top"
		>
			<Paper>
				<Grid
					container
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={3}
					>
						<div
							style={{
								height: "100%",
								overflow: "hidden"
							}}
						>
							<img
								src={imageSource}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
								}}
							/>
						</div>
					</Grid>


					<Grid
						item
						xs={12}
						sm={12}
						md={9}
						style={{padding: 15, height: 130}}
					>
						<Typography
							variant={"h6"}
						>
							{title}
						</Typography>

						{/*<Typography*/}
							{/*variant={"body1"}*/}
						{/*>*/}
							{/*{body}*/}
						{/*</Typography>*/}
					</Grid>
				</Grid>
			</Paper>
		</Tooltip>
	)
};

RealEstateMarketArticle.defaultProps = {
	imageSource: process.env.PUBLIC_URL + "rem-article-0.jpg",
	title: "Title",
	body: "Body",
	tooltipTitle: "This is a test article. In the real product, these would come from various sources.",
};

export default RealEstateMarketArticle;
