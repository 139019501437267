import * as React from "react";
import {Typography} from "@material-ui/core";
import moment from "moment";
import PayoutSelectorHelper from "./PayoutSelectorHelper";
import {useEffect, useState} from "react";
import {numberWithCommas} from "../services/utils";

export interface IPayoutProjectionDataRowProps {
	name: string;
	expiry: any;
	amountAboveOwned: number;
	amountBelowOwned: number;
	add: any;
	remove: any;
	initialValue: any;
}

const PayoutProjectionDataRow: React.FunctionComponent<IPayoutProjectionDataRowProps> = (props: IPayoutProjectionDataRowProps): JSX.Element => {

	const {name, expiry, amountAboveOwned, amountBelowOwned, add, remove, initialValue} = props;

	const [checked, updateChecked] = useState();

	function handleCheckChanger(side: "LEFT" | "RIGHT"): void {
		if (side === "LEFT" && checked !== "LEFT") {
			if (checked === "RIGHT") {
				add((amountBelowOwned * -1) + amountAboveOwned);
			} else {
				add(amountAboveOwned);
			}
			updateChecked("LEFT");
		} else if (side === "LEFT") {
			updateChecked("");
			remove(amountAboveOwned)
		} else if (side === "RIGHT" && checked !== "RIGHT") {
			if (checked === "LEFT") {
				add((amountAboveOwned * -1) + amountBelowOwned);
			} else {
				add(amountBelowOwned);
			}
			updateChecked("RIGHT");
		} else if (side === "RIGHT") {
			updateChecked("");
			remove(amountBelowOwned);
		}
	}

	return (
		<div style={style.container}>
			<div>
				<div style={style.titleContainer}>
					<Typography variant="subtitle1">
						{name}
					</Typography>
					<Typography variant="subtitle1" style={style.expiryDate}>
						{"Expires " + moment(expiry).format("MMMM Do YYYY")}
					</Typography>

					{(checked === "LEFT" || checked === "RIGHT") && (
						<Typography>
							{"On " + moment(expiry).format("MMMM Do YYYY") + " you will get $" + (
								checked === "LEFT" ? numberWithCommas(amountAboveOwned) :
									checked === "RIGHT" ? numberWithCommas(amountBelowOwned) : ""
							) + ".00 if the market value of '" + name + "' is " + (
								checked === "LEFT" ? "above" :
									checked === "RIGHT" ? "below" : ""
							) + " $" + numberWithCommas(initialValue) + "."}
						</Typography>
					)}
				</div>
			</div>

			<div style={style.toggleSection}>
				<div>
					<PayoutSelectorHelper
						title="Amount owned above"
						amount={amountAboveOwned}
						checked={checked === "LEFT"}
						checkChanger={handleCheckChanger}
						side="LEFT"
					/>
				</div>

				<div style={{marginLeft: 20}}>
					<PayoutSelectorHelper
						title="Amount owned below"
						amount={amountBelowOwned}
						checked={checked === "RIGHT"}
						checkChanger={handleCheckChanger}
						side="RIGHT"
					/>
				</div>
			</div>
		</div>
	);
};

const style: any = {
	container: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: 20,
		paddingBottom: 20,
	},
	leftHalf: {

	},
	titleContainer: {
		maxWidth: 300,
	},
	expiryDate: {
		color: "gray",
		fontSize: 12,
	},
	toggleSection: {
		display: "flex",
		flexDirection: "row",
	},
};

export default PayoutProjectionDataRow;
