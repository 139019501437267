import * as React from "react";
import {Paper, Typography, withStyles} from "@material-ui/core";
import HistoryTable from "../components/TableView/HistoryTable";

export interface IEntireTradingHistoryProps {
	classes?: any
}

export interface IEntireTradingHistoryState {

}

class EntireTradingHistory extends React.Component<IEntireTradingHistoryProps, IEntireTradingHistoryState> {

	public render(): JSX.Element {

		const {classes} = this.props;

		return (
			<Paper className={classes.paper}>
				<div className={classes.contentWrapper}>
					<Typography style={{fontSize: 24, fontWeight: "bold"}}>
						Internal Market History
					</Typography>
					<div>
						<HistoryTable showAll={true} key={Math.random()}/>
					</div>
				</div>
			</Paper>
		);
	}
}

const styles = theme => ({
	paper: {
		maxWidth: 1300,
		margin: 'auto',
		overflow: 'hidden',
	},
	contentWrapper: {
		margin: '40px 16px',
	},
	securitiesSelectorContainer: {
		display: "flex",
		justifyContent: "center",
	},
	timeRangeSelectorContainer: {},
	leaderTitles: {
		color: "gray",
		fontSize: 18,
	},
	leaderInfo: {
		fontSize: 20,
	},
	contentSeparator: {
		marginTop: 10,
	}
});

export default withStyles(styles)(EntireTradingHistory);
